import {defineStore} from 'pinia';


export const useSelectionStore = defineStore('selection',{
    state (){
        return {
            selections: [],
            collectionExpandFunc : null,
            selectionMode: false,
            resultsContainer: null,
        };
    },
    getters:{
        selectionCount () { return this.selections.length; },
        getSelections () { return this.selections; },
        getSelectionMode () { return this.selectionMode; },
        getSelectionCount () { return this.selections.length; }
    },
    actions: {
        setSelection (item) {
            if (this.selections.length === 1 && this.selections[0].id === item.id) {
                this.clearAll();
                this.selections = [];
                return;
            }
          this.clearAll();
          this.selections = [item];
        },
        toggleSelection (item) {
            if (this.selections.find(selection => selection.id === item.id ) === undefined) {
                this.selections.push(item);
            } else {
                this.selections = this.selections.filter(selection => selection.id !== item.id);
            }
        },
        clearAll () {
            this.selections = [];
        },
        setCollectionExpandFunc (func) {
            this.collectionExpandFunc = func;
        },
        toggleSelectionMode () {
            this.selectionMode = !this.selectionMode;
        },
        setSelectionMode (bool) {
            this.selectionMode = bool;
        },
        setResults (div) {
            this.resultsContainer = div;
        },
        selectAll (arr) {
            this.selections = arr;
        }
    }
});