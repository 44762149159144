import axios from 'axios';

const apiClient = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? 'https://reporting.twigwirelesscontrols.com/twigdata/api' : 'https://services.nelsonirrigation.com/twigdata/api',
	withCredentials: false,
});

export default {
	async redeemMC (payload) {
		return await apiClient.post('/TwigEvent/subscribe', payload, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async getPrograms (mcId) {
		return await apiClient.get('/TwigData/' + mcId + '/plans', {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async getMCs () {
		return await apiClient.get('/TwigData/mcs', {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async getValves (mcId) {
		return await apiClient.get('/TwigData/' + mcId + '/valves', {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async getValveEvents (mcId, data) {
		return await apiClient.post('/TwigData/' + mcId + '/valveEvents/', data, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async getValvesDateRange (mcId, data, startDate, endDate) {
		var tweakedEndDate = new Date(endDate);
		tweakedEndDate.setTime(tweakedEndDate.getTime() + (((((23 * 60) + 59) * 60) + 59) * 1000));
		return await apiClient.post('/TwigData/' + mcId + '/valveEvents?startUtc=' + startDate + '&endUtc=' + tweakedEndDate.toISOString(), data, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async saveEmitterData (mcId, data) {
		const dataArray = [];
		dataArray.push(data);
		return await apiClient.post('/TwigData/' + mcId + '/valves/', dataArray, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	async logError (error, info) {
		const logArray = {
			'error': error.stack,
			'info': info
		};
		return await apiClient.post('/log/', JSON.stringify(logArray), {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.token,
				'Content-Type': 'application/json; charset=utf-8'
			}
		});
	},
	data () {
		return {
			token: null
		};
	},
	setAuthHeader (_token) {
		this.token = _token;
	},
};
