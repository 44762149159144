<template>
  <div class="function_bar">
    <div class="segmentOne">
      <DropDown :key="mcStore.MCs"/>
      <div class="row">
        <NUIButton
          :obj="this.reporting"
          :displayType="'icon'"
          :isSelected="appFunction === 'reporting'"
        />
        <NUIButton
          :obj="this.dataLog"
          :displayType="'icon'"
          :isSelected="appFunction === 'data'"
        />
      </div>
    </div>
    <div class="segmentTwo">
      <div ref="functionType" class="function_type" @click="handleLoadingToggle">
        {{ this.functionLabel }}
        <NUILoading/>
      </div>
      <div class="row" @click="handleDateClick">
        <DateDisplay label="FROM" time="start"/>
        <div class="v-spacer"></div>
        <DateDisplay label="TO" time="end"/>
      </div>
    </div>
    <div class="h-divider">
      <div class="h-line"></div>
    </div>
    <div class="segmentThree" v-if="this.showReportOptions()">
      <div class="segment-label">
        <div class="label-text">BY</div>
      </div>
      <NUIArrowButton :obj="this.byPlan"></NUIArrowButton>
      <div class="h-spacer"></div>
      <NUIArrowButton :obj="this.byValve"></NUIArrowButton>
    </div>
    <div class="segmentFour">
      <div class="segment-label segment-options">
        <div class="label-text">{{ this.selectorMode.toUpperCase() }}</div>
        <div class="remove-all" v-if="this.hasSelections()" @click="handleRemoveAll">Remove All</div>
      </div>
      <KeywordSelector ref="keywordSelector" :type="'tags'"></KeywordSelector>
    </div>
    <div class="segmentFive">
      <div class="segment-label view-all-segment">
        <div class="view-all" @click="handleViewAll">View All {{ this.selectType() }}</div>
      </div>
      <div class="end-bracket"></div>
      <div class="generate-btn" v-if="appFunction === 'reporting'" @click="handleGenerate">GENERATE</div>
    </div> 
    <div class="segmentSix">
      <Avatar :size="40" variant="ring" :title="true" :name="'Click for Settings'" :colors="colors"
        @click="this.settingsStore.settings_overlay = !this.settingsStore.settings_overlay"
        style="vertical-align: bottom;cursor:hand;">
      </Avatar>
      <v-label style="" :text="this.user.initials"></v-label>
    </div>
    <SettingsOverlay />
  </div>
</template>

<script>

import DropDown from '@/components/functionBar/DropDown';
import Avatar from 'vue-boring-avatars';
import NUIButton from '@/components/nelsonUI/NUIButton';
import DateDisplay from '@/components/functionBar/date/DateDisplay';
import NUIArrowButton from '@/components/nelsonUI/NUIArrowButton';
import KeywordSelector from '@/components/nelsonUI/KeywordSelector';
import SettingsOverlay from '@/components/SettingsOverlay.vue';
import NUILoading from '@/components/nelsonUI/NUILoading';
import { useModalStore } from '@/stores/ModalStore';
import { storeToRefs } from 'pinia/dist/pinia';
import { useFunctionStore } from '@/stores/FunctionStore';
import { useProgramStore } from '@/stores/ProgramStore';
import { useIdentityStore } from '@/stores/IdentityStore';
import { useRMTStore } from '@/stores/RMTStore';
import { useMCStore } from '@/stores/MCStore';
import { useSettingsStore } from '@/stores/SettingsStore';

export default {
  name: 'FunctionBar',
  components: {
    KeywordSelector,
    DropDown,
    NUIButton,
    DateDisplay,
    NUIArrowButton,
    SettingsOverlay,
    Avatar,
    NUILoading
  },
  setup () {
    const modalStore = useModalStore();
    const functionStore = useFunctionStore();
    const programStore = useProgramStore();
    const mcStore = useMCStore();
    const identityStore = useIdentityStore();
    const RMTStore = useRMTStore();
    const settingsStore = useSettingsStore();
    const { programMode, programType, selectorMode, appFunction } = storeToRefs(functionStore);
    return {
      modalStore, functionStore, programStore, mcStore, identityStore, settingsStore, programMode, programType, selectorMode, appFunction, RMTStore
    };
  },
  data () {
    return {
      // mabe move this into FunctionStore
      functionLabel: 'REPORTING',
      reporting: {
        label: 'Reporting',
        icon: 'reporting',
        value: 'reporting',
        orientation: 'bottom',
        marker: 'var(--nelson-blue)',
        func: this.toggleReportingView
      },
      dataLog: {
        label: 'Data',
        icon: 'data',
        value: 'data',
        orientation: 'bottom',
        marker: 'var(--nelson-green)',
        func: this.toggleDataView
      },
      byPlan: {
        label: 'PROGRAM',
        value: 'program',
        type: 'report_type',
        func: this.selectType
      },
      byValve: {
        label: 'VALVE',
        value: 'valve',
        type: 'report_type',
        func: this.selectType
      },
      colors: ['#5D5AD8', '#A1B5FF', '#5A6154', '#849D7F', '#9FD86B']
    };
  },
  watch: {
    $route (to) {
      this.setFunctionLabel(to);
    }
  },
  mounted () {
    this.setFunctionLabel(this.$route);
  },
  computed:{
    user (){
      return this.identityStore.User;
    }
  },
  methods: {
    promptModal (promptLabel, promptDescription) {
      this.modalStore.setModalObj({
        promptLabel: promptLabel,
        promptDescription: promptDescription,
        buttonALabel: 'Ok',
        buttonAFunc: this.modalStore.hideModal,
        buttonBFunc: null
      });
      this.modalStore.showModal('prompt');
    },
    logout () {
      this.identityStore.signout();
    },
    setFunctionLabel (to) {
      if (to.name !== 'data') {
        this.functionLabel = 'REPORTING';
        this.$refs.functionType.style.backgroundColor = 'var(--nelson-blue)';
      } else {
        this.functionLabel = 'DATA';
        this.$refs.functionType.style.backgroundColor = 'var(--nelson-green)';
      }
    },
    hasSelections () {
      let r = (this.RMTStore.getSelectedProgramType().length > 0);
      return r;
    },
    toggleDataView (val) {
      this.functionStore.setAppFunction(val);
      this.$router.push('/data');
    },
    toggleReportingView (val) {
      this.functionStore.setAppFunction(val);
      this.$router.push('/hybrid');
    },
    // todo: move to ProgramStore
    selectType () {
      return (this.selectorMode === 'program') ? 'Programs' : 'Valves';
    },
    handleDateClick () {
      this.modalStore.toggleModal('calendar');
    },
    showReportOptions () {
      return (this.programMode === 'program');
    },
    handleRemoveAll () {
      this.functionStore.removeAllPrompt();
    },
    handleViewAll () {

      this.modalStore.toggleModal('view all');
    },
    handleGenerate () {
      if (this.programStore.isByProgram) {
        const selectionCount = this.RMTStore.selectedPlans.length > 0 ? true : false;
        if (selectionCount) {
          const programValveCount = [];
          this.RMTStore.selectedPlans.map((element) => {
            programValveCount.push(...element.valves);
          });
          // checks to see if there is any valves in the selected programs. Prompts user if there is none.
          if (programValveCount.length > 0) {
            this.programStore.generateHandler();
            this.$refs.keywordSelector.inputFocused = false;
          } else {
            const promptLabel = 'No Valves in Selected Program(s)';
            const promptDescription = 'Please select a program with valves in it.';
            this.promptModal(promptLabel, promptDescription);
          }
        } else {
          const promptLabel = 'No Selected Program(s)';
          const promptDescription = 'Please select a program.';
          this.promptModal(promptLabel, promptDescription);
        }
      } else {
        const selectionCount = this.RMTStore.selectedValves.length > 0 ? true : false;
        if (selectionCount) {
          this.programStore.generateHandler();
          this.$refs.keywordSelector.inputFocused = false;
        } else {
          const promptLabel = 'No Selected Valve(s)';
          const promptDescription = 'Please select a valve.';
          this.promptModal(promptLabel, promptDescription);
        }
      }
    },
    handleLoadingToggle () {
      this.functionStore.dataLoading = !this.functionStore.dataLoading;
    }
  }
};
</script>

<style scoped>
.function_bar {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-top: 10px;/*
  max-height: 120px;*/
  flex-direction: row;
}
.segmentOne {
  display: flex;
  flex:2;
  flex-direction: column;
  max-width: 250px;
/*  width: 200px;*/
}
.segmentTwo {
/*  height: 100%;
  width: 200px;*/
  min-width: 200px;
  max-width: 250px;
  border-left: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  margin-left: 4px;
  padding: 4px;
  height: 120px;
  flex:2;
}
.segmentThree {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-left: 6px;
  padding-top: 8px;
  padding-bottom: 6px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  height: 120px;
  max-width: 110px;
  flex:1;
}
.segmentFour {
  display: flex;
/*  flex-direction: column;*/
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 6px;
  padding-top: 8px;
  padding-bottom: 6px;
  height: 100%;
  flex: 3;
  max-width: 400px;
}
.segmentFive {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 6px;
  padding-bottom: 6px;
  height: 120px;
  flex:1;
  /*
  max-width: 30px;*/
}
.segmentSix {
  display: flex;
 flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 120px;
  max-width: 200px;
  padding: 5px 5px 5px 5px;
}
.row {
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: space-between;
}
.v-spacer {
  width: 4px;
}
.h-spacer {
  height: 4px;
}
.function_type {
  display: flex;
  justify-content: space-between;
  background-color: #0088ca;
  color: white;
  padding: 10px 0px 10px 8px;
  font-size: .8em;
  font-weight: 900;
  letter-spacing: .06em;
}
.h-divider {
  width: 1px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}
.h-line {
  background-color: #c3c3c3;
  height: 88%;
  width: 1px;
}
.segment-label {
  position: absolute;
  font-size: .7em;
  background-color: white;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0px, -18px);
}
.segment-options {
/*  width: 400px;*/
  align-items: flex-start;
  justify-content: flex-start;
}
.view-all-segment {
  width: 110px;
  transform: translate(-102px, -9px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.view-all {
  text-align: right;
  align-self: flex-end;
  cursor: pointer;
}
.view-all:hover {
  text-decoration: underline;
}
.label-text {
  font-weight: 600;
}
.remove-all {
  cursor: pointer;
  margin-left: 10px;
}
.remove-all:hover {
  text-decoration: underline;
}
.end-bracket {
  width: 10px;
  height: 100%;
  min-width: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px #c3c3c3 solid;
  border-right: 1px #c3c3c3 solid;
  border-bottom: 1px #c3c3c3 solid;
}
.generate-btn {
  aspect-ratio: 1/1;
  height: 100%;
  user-select: none;
  padding: 20px;
  /*set to a global nelson green*/
  background-color: #00bd5e;
  color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .85em;
  font-weight: 600;
  cursor: pointer;
  z-index: 201
}
.generate-btn:hover {
  background-color: #00b254;
}
</style>