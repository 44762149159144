<template>
  <div class="loading-holder">
    <div ref="mask" class="loading-mask">
      <img ref="nl" class="nelson-loading" :src="require('@/assets/svg/SVG/nelson_loading.svg')" />
    </div>
  </div>
</template>

<script>

import { gsap } from 'gsap';
import { useFunctionStore } from '@/stores/FunctionStore';
import { storeToRefs } from 'pinia/dist/pinia';

export default {
  name: 'NUILoading',
  data () {
    return {
      delay: null
    };
  },
  setup () {
    const functionStore = useFunctionStore();
    const { dataLoading } = storeToRefs(functionStore);
    return {
      functionStore, dataLoading
    };
  },
  watch: {
    dataLoading (to) {
      if (to) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    }
  },
  mounted () {
    gsap.set(
      this.$refs.mask, {
        x: 0,
        width: 0
      }
    );
  },
  methods: {
    startAnimation () {
      gsap.killTweensOf(this.$refs.nl);
      if (this.delay) { this.delay.kill(); }
      gsap.set(
        this.$refs.mask, {
          x: 19
        }
      );
      gsap.set(
        this.$refs.nl, {
          x: 0
        }
      );
      gsap.to(
        this.$refs.mask, {
          x: 0,
          width: 19,
          duration: .4,
          ease: 'power2.easeInOut'
        }
      );
      gsap.fromTo(this.$refs.nl, {
        x:0
      },{
        x:-19*2,
        repeat: -1,
        ease: 'none',
        duration: .8,
        repeatDelay: 0
      });
      gsap.fromTo(this.$refs.nl, {
        alpha:.7
      },{
        alpha:1,
        repeat: -1,
        ease: 'none',
        duration: .4,
        yoyo: true
      });
    },
    stopAnimation () {
      gsap.to(this.$refs.mask, {
        width: 0,
        x: 19,
        duration: .2,
        ease: 'power2.easeInOut'
      });
      this.delay = gsap.delayedCall(.3,
        () => {
          gsap.killTweensOf(this.$refs.nl);
        }
      );
    }
  }
};
</script>

<style scoped>
.loading-holder {
  margin-right: 0px; /*10px*/
  width: 19px;
  height: 19px;
}
.loading-mask {
  width: 19px;
  height: 19px;
  overflow: hidden;
}
.nelson-loading {
  height: 19px;
}
</style>