import { defineStore } from 'pinia';
import { useProgramStore } from '@/stores/ProgramStore';
import { useMCStore } from '@/stores/MCStore';

export const useRMTStore = defineStore('RMT', {
  state () {
    return {
      isUploadState: false,
      colors: [
        '#a95e5e',
        '#592121',
        '#e08840',
        '#b6c3dc',
        '#73a43e',
        '#9a4fb0',
        '#e08840'
      ],
      data: [
        { label: 'file name', placeholder: 'name_of_file.jpg', value: 'file' },
        { label: 'title', placeholder: 'Image Title', value: 'title' },
        { label: 'description', placeholder: 'Image Description', value: 'description' },
        { label: 'alt tag', placeholder: 'Alt tag', value: 'altTag' },
      ],
      // tags
      tags: [
        { id: 0, name: 'Soybeans', active: false, type: 'tags', color: 0 },
        { id: 1, name: 'Stonefruit', active: false, type: 'tags', color: 1 },
        { id: 2, name: 'Sugar Beets', active: false, type: 'tags', color: 2 },
        { id: 3, name: 'Sugarcane', active: false, type: 'tags', color: 3 },
        { id: 4, name: 'Vegetables', active: false, type: 'tags', color: 4 },
        { id: 5, name: 'Vineyards', active: false, type: 'tags', color: 0 },
        { id: 6, name: 'WheatCranberries', active: false, type: 'tags', color: 1 },
        { id: 7, name: 'Hay & Forage', active: false, type: 'tags', color: 2 },
        { id: 8, name: 'Help', active: false, type: 'tags', color: 3 },
        { id: 9, name: 'Oil Palm', active: false, type: 'tags', color: 4 },
        { id: 10, name: 'Onions', active: false, type: 'tags', color: 5 },
        { id: 11, name: 'Peanuts', active: false, type: 'tags', color: 0 },
        { id: 12, name: 'Peas', active: false, type: 'tags', color: 1 },
        { id: 13, name: 'Potatoes', active: false, type: 'tags', color: 2 },
        { id: 14, name: 'RiceAlmonds & Tree Nuts', active: false, type: 'tags', color: 3 },
        { id: 15, name: 'Apples & Pears', active: false, type: 'tags', color: 4 },
        { id: 16, name: 'Avocados', active: false, type: 'tags', color: 5 },
        { id: 17, name: 'Bananas', active: false, type: 'tags', color: 0 },
        { id: 18, name: 'Cherries', active: false, type: 'tags', color: 1 },
        { id: 19, name: 'Citrus', active: false, type: 'tags', color: 2 },
        { id: 20, name: 'Corn', active: false, type: 'tags', color: 3 },
        { id: 21, name: 'Cotton', active: false, type: 'tags', color: 4 },
        { id: 22, name: 'Red', active: false, type: 'collections', color: 5 },
        { id: 23, name: 'Green', active: false, type: 'collections', color: 6 },
        { id: 24, name: 'Crops', active: false, type: 'collections', color: 0 },
        { id: 25, name: 'Sprinklers', active: false, type: 'collections', color: 1 },
        { id: 26, name: 'About', active: false, type: 'permissions', color: 2 },
        { id: 27, name: 'Nelson', active: false, type: 'permissions', color: 3 },
        { id: 29, name: 'Permission 1', active: false, type: 'permissions', color: 4 },
        { id: 30, name: 'Permission 2', active: false, type: 'permissions', color: 5 },
        { id: 31, name: 'Permission 3', active: false, type: 'permissions', color: 6 }
      ],
      selectedTags: [],
      strings: [
        { type: 'tags', string: '' }
      ],
      keywordSearchText: '',
      uploadedFiles: [],
      selectedItem: null,
      selectedItemNum: 0,
      defaultDataObject: { file: '', title: '', description: '', altTag: '', date: '', tags: [0, 2, 4, 10, 31, 25] },
      uploadedFileData: [
        // {file:"", title:"", description:"", altTag:"", date:""},
        // {file:"test 1", title:"title 1", description:"hi there", altTag:"alt", date:""},
        // {file:"test 2", title:"title 2", description:"hi there again", altTag:"alt taggage", date:""}
      ],
      selectedPlans: [],
      selectedValves: [],
      fileMode: 'upload',
      currentItemHover: null
    };
  },
  getters: {
    getSelectedItemNum () {
      return this.selectedItemNum;
    },
    getColors () {
      return this.colors;
    }
  },
  actions: {
    removeAllOfType () {
      const programStore = useProgramStore();
      let program = programStore.isByProgram;
      program ? this.selectedPlans = [] : this.selectedValves = [];
    },
    removeAll () {
      this.selectedPlans = [];
      this.selectedValves = [];
    },
    getSelectedProgramType () {
      const programStore = useProgramStore();
      let program = programStore.isByProgram;
      return (program) ? this.selectedPlans : this.selectedValves;
    },
    getAllOfType () {
      const mcStore = useMCStore();
      const programStore = useProgramStore();
      let program = programStore.isByProgram;
      let source = (program) ? mcStore.getCurrentMC.programs : mcStore.getCurrentMC.valves;
      return source;
    },
    getSelected (type) {
      return this.tags.filter(tag => tag.type === type && tag.active).sort((a, b) => a.name.localeCompare(b.name));
    },
    getTagsById (type) {
      let arr = [];
      for (let a = 0; a < this.getSelectedProgramType.length; a++) {
        let id = this.getSelectedProgramType[a];
        this.tags.find(tag => {
          if (tag.id === id && tag.type === type) {
            tag.active = true;
            arr.push(tag);
          }
        });
      }
      return arr.sort((a, b) => a.name.localeCompare(b.name));
    },
    getMatching (type) {
      const programStore = useProgramStore();
      const mcStore = useMCStore();
      let program = programStore.isByProgram;
      // let selected = (program) ? this.selectedPlans : this.selectedValves
      let source = (program) ? mcStore.getCurrentMC.programs : mcStore.getCurrentMC.valves;
      let name = (program) ? 'plan_name' : 'valve_name';
      let id = (program) ? 'plan_id' : 'valve_id';
      if (source === undefined) { return; }
      let f = [];
      if (this.strings[0].string === '' && type !== 'no keyword') { return f; }
      source.filter(s => {
        // if program/valve has no name, give it the name of the id
        if (s[name] === null) {s[name] = s[id].toString();}
        if (type === '') {
          if (s[name].toLowerCase().includes(this.strings[0].string.toLowerCase())) {
            if (this.getSelectedProgramType().some(e => e[id] === s[id]) === false) {
              f.push(s);
            }
          }
        }
        if (type === 'no keyword') {
          if (this.getSelectedProgramType().some(e => e[id] === s[id]) === false) {
            f.push(s);
          }
        }
      });
      return f;
    },
    setTag (type, str) {
      let s = this.strings.find(str => str.type === type);
      s.string = str;
    },
    addKeywordToSelection (selection, option) {
      this.getSelectedProgramType().push(option);
    },
    removeKeywordFromSelection (option) {
      const programStore = useProgramStore();
      let program = programStore.isByProgram;
      if (program) {
        this.selectedPlans = this.getSelectedProgramType().filter(plan => plan !== option);
      } else {
        this.selectedValves = this.getSelectedProgramType().filter(plan => plan !== option);
      }
    },
    getColor (num) {
      return this.colors[num];
    },
    getAllRecent (type) {
      if (this.getMatching() === undefined) {return;}
      return this.getMatching(type).filter(tag => tag.recent);
    },
    setSelectedItemNum (num) {
      this.selectedItemNum = num;
    },
    setSelectedItem (obj) {
      this.selectedItem = obj;
    },
    setSelectedItemColor (color) {
      this.selectedItem.color = color;
    }
  }
});