import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state () {
        return {
            isRedeeming: false,
            isLoading: false,
            isSaving: false,
            isCompleted: false,
            drawGraph: false
        };
    },
});