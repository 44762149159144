import ApiService from '../services/ApiService';
import * as Calculate from '@/library/calculations.js';
import { useMCStore } from '@/stores/MCStore';
import { useAppStore } from './AppStore';
import { defineStore } from 'pinia';

export const useEmitterStore = defineStore('emitter', {
	state () {
		return {
			twigMcEmitterData: [],
			selectedItem: null,
		};
	},
	actions: {
		saveEmitterData (data) {
			const mcStore = useMCStore();
			const appStore = useAppStore();
			ApiService.saveEmitterData(mcStore.getCurrentMC.uuid, data)
				.then((response) => {
					console.log(response.data);
					appStore.isSaving = false;
					this.selectedItem = null;
				})
				.catch((err) => {
					console.log(err);
					appStore.isSaving = false;
					this.selectedItem = null;
				});
		},
		emittersPerSquareMeter (valve) {
			if (valve.emitter_spacing == null || valve.row_spacing == null) { return 0; }
			if (valve.emitter_spacing == 0 || valve.row_spacing == 0) { return 0; }
			return Calculate.emittersPerSquareMeter(valve.emitter_spacing, valve.row_spacing);
		},
		appRateLPMH (valve) {
			if (valve.row_spacing != null && valve.emitter_spacing != null && valve.emitter_flow_lpm != null && valve.emitter_flow_lpm != 0)
				{return Calculate.appRateLPMHfromEmitter(valve.emitter_flow_lpm, valve.emitter_spacing, valve.row_spacing);}
			else if (valve.area_m2 != null && valve.flow_lpm != null)
				{return Calculate.appRateLPMH(valve.area_m2, valve.flow_lpm);}
			else
				{return 0;}
		},
		getValves () {
			const mcStore = useMCStore();
			ApiService.getValves(mcStore.getCurrentMC.uuid)
				.then((response) => {
					this.extractEmitterData(response.data);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		extractEmitterData (valves) {
			this.twigMcEmitterData = [];
			// Fixes issues with results coming back as undefined
			if (valves != undefined) {
				valves.forEach((valve) => {
					this.twigMcEmitterData.push(
						{
							mcName: (valve.valve_name == null) ? valve.valve_id : valve.valve_name,
							valveId: valve.valve_id,
							serial: valve.serial,
							emitterSpacingMeters: valve.emitter_spacing,
							rowSpacingMeters: valve.row_spacing,
							emitterFlowRateLPM: valve.emitter_flow_lpm,
							appRateLPMH: this.appRateLPMH(valve),
							areaM2: valve.area_m2,
							valveFlowLPM: valve.flow_lpm,
							isDirty: valve.isDirty ? valve.isDirty : false,
						}
					);
				});
				return this.twigMcEmitterData;
			} else {
				return [];
			}
		},
	},
});
