<template>
  <div class="modal-bg select-bg" @click.stop="preventDefault">
  <div class="modal-title">SELECT {{ this.getPlaceholderText().toUpperCase() }}</div>
    <div class="split-holder">
      <div class="split">
        <div class="right split-half">
          <div class="title">{{ this.getPlaceholderText().charAt(0).toUpperCase() + this.getPlaceholderText().slice(1) }}</div>
          <div class="highlight"></div>
          <div class="input-holder">
            <input type="text" class="keyword-dropdown-text select-input" :placeholder="'Search ' + this.getPlaceholderText()" v-model="keywordSearchText" @input="handleKeywordSearch">
            <div class="function-text">Select All</div>
          </div>
          <div class="item-holder">
            <KeywordOption v-for="(option, index) in getMatchingOptions" :key="index" :option="option" :removeable="false" @mousedown="addKeywordToSelection(option)"></KeywordOption>
          </div>
        </div>
        <div class="left split-half">
          <div class="title">&nbsp;</div>
          <div class="highlight"></div>
          <div class="left-options-holder">
            <div class="selected-text">Selected {{ this.getPlaceholderText().charAt(0).toUpperCase() + this.getPlaceholderText().slice(1) }}</div>
            <div class="function-text" @click.stop="handleRemoveAll">Remove All</div>
          </div>
          <div class="selected-holder">
            <KeywordOption v-for="(option, index) in getSelectorType" :key="index" :option="option" :index="index" :removeable="true" :func="handleOptionRemove"></KeywordOption>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRMTStore } from '@/stores/RMTStore';
import KeywordOption from '@/components/nelsonUI/KeywordOption';
import { useProgramStore } from '@/stores/ProgramStore';
import { useFunctionStore } from '@/stores/FunctionStore';

export default {
  name: 'SelectViewModal',
  components: {
    KeywordOption
  },
  data () {
    return {
      keywordSearchText: '',
    };
  },
  setup () {
    const RMTStore = useRMTStore();
    const programStore = useProgramStore();
    const functionStore = useFunctionStore();
    return {
      RMTStore, programStore, functionStore
    };
  },
  computed: {
    getMatchingOptions () {
      return this.RMTStore.getMatching('no keyword');
    },
    getSelectorType () {
      return this.RMTStore.getSelectedProgramType();
    },
  },
  methods: {
    preventDefault () {
    },
    getPlaceholderText () {
      return (this.programStore.isByProgram) ? 'programs' : 'valves';
    },
    addKeywordToSelection (option) {
      option.new = true;
      this.RMTStore.addKeywordToSelection(this.getSelectorType, option);
    },
    handleOptionRemove (option) {
      this.RMTStore.removeKeywordFromSelection(option);
    },

    handleRemoveAll () {
      this.functionStore.removeAllPrompt();
    },
    handleKeywordSearch () {

    }
  }
};
</script>

<style scoped>
.keyword-dropdown-text {
  width: 80%;
  margin: 8px;
  min-height: 35px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: .8em;
  border: 1px solid lightgray;
}
.select-bg {
  display: flex;
  flex-direction: column;
}
.split {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.split-half {
  display: flex;
  width: 50%;
  flex-grow: 1;
  flex-direction: column;
}
.right {
  border-right: 1px solid black;
}
.left {
  align-items: flex-start;
}
.title {
  font-weight: 700;
  font-size: .85em;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.highlight {
  height: 4px;
  width: 100%;
  background-color: black;
  margin-bottom: 6px;
}
.item-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
  margin-left: 10px;
  overflow-y: auto;
}
.selected-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 10px;
  overflow-y: auto;
}
.split-holder {
  display: flex;
  flex: 1;
  overflow-y: scroll;
}
.select-input {
  padding-left: 10px;
  padding-right: 10px;
}
.input-holder {
  display: flex;
  align-items: center;
}
.function-text {
  cursor: pointer;
  display: flex;
  font-size: .8em;
}
.left-options-holder {
  display: flex;
  height:50px;
  background-color: white;
  padding-left: 10px;
  align-items: center;
}
.selected-text {
  font-size: .8125em;
  font-weight: 600;
  padding-right: 10px;
}
</style>