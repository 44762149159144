import { createRouter, createWebHashHistory } from 'vue-router';
import { useIdentityStore } from '@/stores/IdentityStore';
import { useModalStore } from '@/stores/ModalStore';
import ApiService from '@/services/ApiService';
import GraphView from '../views/GraphView.vue';
import TableView from '../views/TableView.vue';
import HybridView from '@/views/HybridView.vue';
import BlankView from '@/views/BlankView.vue';
import DataView from '@/views/DataView.vue';

const routes = [
  {
    path: '/',
    redirect: '/hybrid'
  },
  {
    path: '/graph',
    name: 'graph',
    component: GraphView
  },
  {
    path: '/table',
    name: 'table',
    component: TableView
  },
  {
    path: '/data',
    name: 'data',
    component: DataView
  },
  {
    path: '/hybrid',
    name: 'hybrid',
    component: HybridView
  },
  {
    path: '/blank',
    name: 'blank',
    component: BlankView
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to) => {
  const ident = useIdentityStore();
  // dynamic page title
  document.title = to.meta?.title ?? 'Nelson Twig Reporting';

  if (to.path !== '/callback') {
    // check to see if discovery document is stored
    if (!ident.discovery) {
      await ident.verifyDiscoveryDocument();
    }

    var token = ident.access_token();

    if (!token) // no token so we need to authenticate
    { ident.ssoAuthenticate(); }
    else {
      var bTokenActive = await ident.isTokenValid();

      if (!bTokenActive) {
        var refreshToken = ident.refresh_token();
        if (refreshToken) {
          await ident.ssoRefreshToken(refreshToken);
          token = ident.access_token();
        }
      }
      
      if (!ApiService.token) {ApiService.setAuthHeader(ident.access_token()); }

      var user = JSON.parse(localStorage.getItem('user'));
      if (!user) { await ident.getTokenUser(); }
      else {
        ident.User = user;
      }
      // check for TOS
      if (ident.User.properties.twig_reporting_hasAgreed === undefined) {
        const modalStore = useModalStore();
        modalStore.showModal('tos');
      }
     
    }
  }
});
router.onError(err => {
  print('<!-- router error: ' + err.message + ' -->');
});

export default router;
