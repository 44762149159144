<template>
  <v-row no-gutters style="margin-top: 30px; width: 85%">
    <v-col>
      <h3 style="margin-left: 15px;">Change Password</h3>
    </v-col>
  </v-row>
  <v-row no-gutters style="margin-top: 10px; width: 85%">
    <v-col style="margin-left: 15px">
      <div>New Password:</div>
      <input type="password" class="password-input" :value="this.newPassword"
        @blur="this.newPassword = $event.target.value" />
    </v-col>
    <v-col></v-col>
  </v-row>
  <v-row no-gutters style="margin-bottom: 10px; width: 85%">
    <v-col style="margin-left: 15px;">
      <div style="margin-top: 5px;">Confirm Password:</div>
      <input type="password" class="password-input" :value="this.confirmPassword"
        @blur="this.confirmPassword = $event.target.value" />
    </v-col>
    <v-col>
      <v-btn @click="this.changePassword" variant="outlined" style="width: 100px; margin-top: 37px"
        class="settingsBtn">Save</v-btn>
    </v-col>
  </v-row>
  <v-row no-gutters style="margin-bottom: 10px; width: 85%">
    <v-col>
      <h3 style="margin-left: 15px;">Redeem MC Invitation</h3>
    </v-col>
  </v-row>
  <v-row no-gutters style="width: 85%">
    <v-col style="margin-left: 15px">
      <div>Voucher:</div>
      <input type="code" @click="handleInput" class="password-input" :value="this.redeemToken"
        @blur="this.redeemToken = $event.target.value" />
    </v-col>
    <v-col>
      <v-btn @click="this.redeemMC" variant="outlined" style="width: 100px; margin-top: 32px; margin-bottom: 5px;"
        class="settingsBtn"><span>Redeem</span>
        <div class="progress-circle">
          <v-progress-circular v-if="appStore.isRedeeming" :size="15" indeterminate
            color="white"></v-progress-circular><v-icon v-if="appStore.isCompleted" style="color: #ffffff"
            icon="check"></v-icon>
        </div>
      </v-btn>
      <div style="color: red; margin-top: 10px" v-if="mcStore.isInvalidToken">
        <p>Invalid Token! Please type in a valid token.</p>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { useIdentityStore } from '@/stores/IdentityStore.js';
import { useMCStore } from '@/stores/MCStore';
import { useAppStore } from '@/stores/AppStore';
import { mapStores } from 'pinia';
export default {
  data: () => ({
    newPassword: '',
    confirmPassword: '',
    redeemToken: '',
  }),
  computed: {
    ...mapStores(useIdentityStore, useMCStore, useAppStore),
  },
  methods: {
    redeemMC () {
      this.appStore.isRedeeming = true;
      this.mcStore.redeemMC(this.redeemToken);
    },
    handleInput () {
      this.mcStore.isInvalidToken = false;
      this.appStore.isCompleted = false;
    },
    changePassword () {
      if (this.newPassword == '' || this.confirmPassword == '') { return; }
      if (this.newPassword != this.confirmPassword) { return; }
      this.identityStore.setUserPassword(this.newPassword);
    },
  }
};
</script>
<style scoped>
.password-input {
  width: 97%;
  min-height: 35px;
  margin-top: 8px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: .8em;
  border: 1px solid lightgray;
}
.settingsBtn {
  padding: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: var(--nelson-green);
  color: white;
}

</style>