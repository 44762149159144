<template>
  <!-- :style="tableStyle()" -->
    <div class="table-container" >
        <table :if="items" class="table-items">
            <thead class="table-header">
                <tr>
                    <th colspan="8">
                        <hr />
                    </th>
                </tr>
                <tr>
                    <th v-for="(head, index) in headers" :key="index" class="table-header-title">
                        {{ head.title }}
                    </th>
                </tr>
                <tr>
                    <th v-for="(subhead, index) in subheaders" :key="index" class="table-header-subtitle">{{ subhead }}
                    </th>
                </tr>
            </thead>
            <tbody class="table-body-holder">
                <tr v-for="(item, index) in items" :key="index" :id="'row' + this.isSingleValve(item)" class="table-body">
                    <td class="table-data-group">
                        <div class="table-group" :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight-first' : ''">{{ item.group }}</div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''">{{ this.filterArea(item.area) }}</div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''">{{ this.filterFlow(item.flow) }}</div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''"><b>{{ this.filterRate(item.apprate) }}</b></div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''">{{ item.hoursRan.toFixed(3) }}</div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''"><b>{{ this.filterDepth(item.precipitation) }}</b>
                        </div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''">{{ item.verifiedHour.toFixed(3) }}</div>
                    </td>
                    <td>
                        <div :class="tooltip && this.isSingleValveQuery(tooltip) === this.isSingleValveQuery(item) ? 'highlight' : ''"><b>{{ this.filterDepth(item.verifiedPrecipitation) }}</b>
                        </div>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</template>
<script>
import { mapStores } from 'pinia';
import * as UnitConversion from '@/services/UnitConversionService.js';
import { useProgramStore } from '@/stores/ProgramStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import dayjs from 'dayjs';

export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        subheaders: {
            type: Array,
            required: false
        },
        height: {
            type: String,
            required: true
        }

    },
    methods: {
        isSingleValve (item) {
            const isSingleValve = this.programStore.isSingleValve;
            return isSingleValve ? item.index : item.valveId;
        },
        isSingleValveQuery (item) {
            const isSingleValve = this.programStore.isSingleValve;
            return isSingleValve ? dayjs(item.group).format('MMM-D') : item.valveId;
        },
        filterFlow (flow) {
            return (UnitConversion.FilterFlow(flow, this.settingsStore.flowUnit)).toFixed(3);
        },
        filterRate (rate) {
            return (UnitConversion.FilterRate(rate, this.settingsStore.rateUnit)).toFixed(3);
        },
        filterArea (area) {
            return (UnitConversion.FilterArea(area, this.settingsStore.areaUnit)).toFixed(3);
        },
        filterDepth (depth) {
            const lengthUnit = this.settingsStore.lengthUnit;
            const convertedDepth = UnitConversion.FilterLength(depth / 10000 / 1000, this.settingsStore.lengthUnit);
            if (lengthUnit == 'ft') {return (convertedDepth * 12).toFixed(3);}
            if (lengthUnit == 'm') {return (convertedDepth * 1000).toFixed(3);}
            return depth;
        },
        tableStyle () {
            let height = window.innerHeight;
            // let width = window.innerWidth;
            let inHeight = parseFloat(this.height);
            inHeight = inHeight * 0.01;

            return {
                'max-height': (height * inHeight).toString() + 'px',
                'height': (height * inHeight).toString() + 'px',
                'overflow-y': 'scroll'
            };
        },
    },
    computed: {
        ...mapStores(useProgramStore, useSettingsStore),
        tooltip () {
            return this.programStore.tooltip;
        }
    }
};
</script>


<style scoped>

.table-container {
  width: 100%;
}

.table-header {
    top: 0;
    position: sticky;
    background-color: #fafafa;
}

.highlight {
    background-color: rgba(220, 110, 42, 0.25) !important ;
    color: black;
}

.highlight-first {
    background-color: rgba(220, 110, 42, 1) !important;
    color: white;
}

tr td div {
    padding: 0px 0;
}
tr:hover td:first-child div {
    background-color: rgba(220, 110, 42, 1);
    color: white;
}
tr:hover td:not(first-child) div {
    background-color: rgba(220, 110, 42, 0.25);
    color: black;
}
tr td:not(first-child) div {
    background-color: #fafafa;
    height: 30px;
}
tr td:first-child div {
    height: 30px;
    text-align: left;
    border-radius: 15px 0 0 15px;
    padding-left: 15px;
}
tr td:last-child div {
    height: 30px;
    text-align: center;
    border-radius: 0px 15px 15px 0px;
    padding-left: 15px;
}
th:last-child,
td:last-child {
    border-right: 0px solid darkgray;
}

td {
    font-size: small;
    width: 12vw;
    text-align: center;
    border-right: 0px solid darkgray;
}

.table-items {
  width: 100%;
  border-spacing: 0;
}

.table-header-title {

  font-size: .8em;
  line-height: 1.3em;
  border: 0.5px solid lightgray;
  border-bottom: 1px solid gray;
  padding: 10px 3px 10px 3px;

}

.table-header-subtitle {

  border: 0.5px solid lightgray;
  text-align: right;
  padding: 5px 15px 5px 5px;
  font-size: .76em;
}

.table-body td {

  padding-top: 3px;
  padding-bottom: 3px;
  border-right: 0.5px solid lightgray;
}

.table-body div {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: .9em;
  background-color: whitesmoke;

}

.table-group {
  justify-content: flex-start !important;
/*  align-items: flex-start !important;*/
  background-color: yellow;
}

.table-data-group {
  width: 20%;
}

/* .table-body-holder {
  position: relative;
  z-index: -3;
} */

</style>