<template>
	<div class="view-options-container" v-if="currentRouteName !== 'data'">
		<div class="view-options-label-holder">
      <div class="left-options">
        <div class="view-options-label">Views</div>
      </div>
      <div class="flex-spacer"></div>
      <div class="right-options">
        <div class="view-options-label">Runtime</div>
        <div class="view-options-label flex-end">Applied Depth</div>
      </div>
		</div>
    <div class="option-buttons-holder">
			<div class="option-buttons-left">
				<NUIButton
					:obj="this.graph"
					:appendIcon="showGraph ? 'show' : 'hide'"
					:displayType="'icon'"
					:isSelected="showGraph"
					:isDisable="getCurrentEvents()"
				>
				</NUIButton>
				<NUIButton
					:obj="this.table"
					:appendIcon="showTable ? 'show' : 'hide'"
					:displayType="'icon'"
					:isSelected="showTable"
					:isDisable="getCurrentEvents()"
				>
				</NUIButton>
				<NUIButton
					:obj="this.csv"
					:displayType="'icon'"
					:isDisable="getCurrentEvents()"
				></NUIButton>
			</div>
      <div class="option-buttons-flex-spacer"></div>
      <div class="option-buttons-right">
        <div class="nui-button-stack">
          <NUIButton
            data-testid="button-hrsVerified"
            :obj="this.hrsVerified"
            :class="'verified_hours_square'"
            :appendIcon="this.showVerifiedHours ? 'show' : 'hide'"
            :displayType="'no-icon'"
            :isSelected="this.showVerifiedHours || this.showHrsReported"
            :isDisable="getCurrentEvents()"
          >
          </NUIButton>
          <NUIButton
            data-testid="button-hrsReported"
            :obj="this.hrsReported"
            :class="'hours_square'"
            :appendIcon="this.showHrsReported ? 'show' : 'hide'"
            :displayType="'no-icon'"
            :isSelected="this.showHrsReported"
            :isDisable="getCurrentEvents()"
          >
          </NUIButton>
        </div>
        <div class="nui-button-stack">
          <NUIButton
            :obj="this.precipVerified"
            :class="'verified_inches_square'"
            :appendIcon="this.showVerifiedApplied ? 'show' : 'hide'"
            :displayType="'no-icon'"
            :isSelected="this.showVerifiedApplied || this.showAppliedReported"
            :isDisable="getCurrentEvents()"
          >
          </NUIButton>
          <NUIButton
            :obj="this.precip"
            :class="'inches_square'"
            :appendIcon="this.showAppliedReported ? 'show' : 'hide'"
            :displayType="'no-icon'"
            :isSelected="this.showAppliedReported"
            :isDisable="getCurrentEvents()"
          ></NUIButton>
        </div>
      </div>
    </div>
	</div>
</template>
<script>
import { useProgramStore } from '../stores/ProgramStore';
import { mapStores, storeToRefs } from 'pinia';
import NUIButton from '@/components/nelsonUI/NUIButton.vue';

import * as d3 from 'd3';
export default {
	components: {
		NUIButton,
	},
	data () {
		return {
			showVerifiedHours: true,
			showVerifiedApplied: true,
			showHrsReported: true,
			showAppliedReported: true,
			graph: {
				label: 'Chart',
				icon: 'chart',
				value: 'chart',
				orientation: 'top',
				marker: 'var(--nelson-blue)',
				func: this.toggleView,
			},
			table: {
				label: 'Table',
				icon: 'table',
				value: 'table',
				orientation: 'top',
				marker: 'var(--nelson-green)',
				func: this.toggleView,
			},
			csv: {
				label: 'Export CSV',
				icon: 'export_csv',
				value: 'export_csv',
				orientation: 'top',
				marker: '#FFFFFF',
				func: this.saveCSV,
			},
			hrsVerified: {
				label: 'Verified',
				value: 'hrs_verified',
				orientation: 'top',
				marker: 'var(--nelson-blue)',
				func: this.verifiedHoursFilter,
			},
			hrsReported: {
				label: 'Reported',
				value: 'hrs_reported',
				orientation: 'none',
				marker: '#a1b5ff',
				func: this.hoursRanFilter,
			},
			precipVerified: {
				label: 'Verified',
				value: 'precip_verified',
				orientation: 'top',
				marker: 'var(--nelson-green)',
				func: this.verifiedPrecipitationFilter,
			},
			precip: {
				label: 'Reported',
				value: 'precip_reported',
				orientation: 'none',
				marker: '#5F945E',
				func: this.precipitationFilter,
			},
		};
	},
	setup () {
		const programStore = useProgramStore();
		const { currentEventData } = storeToRefs(programStore);
		return {
			currentEventData
		};
	},
	mounted () {
		if (this.programStore) {
			this.toggleView();
		}
	},
	watch: {
		currentEventData () {
			this.showVerifiedHours = true;
			this.showVerifiedApplied = true;
			this.showHrsReported = true;
			this.showAppliedReported = true;
		}
	},
	computed: {
		...mapStores(useProgramStore),
		currentRouteName () {
			return this.$route.name;
		},
		draw () {
			return this.programStore.draw;
		},
		showGraph () {
			return this.programStore.showGraph;
		},
		showTable () {
			return this.programStore.showTable;
		},
	},
	methods: {
		getCurrentEvents () {
			const isDisabled = this.programStore.currentEventData.length > 0 ? false : true;
			return isDisabled;
		},
		saveCSV () {
			this.programStore.downloadCSV();
		},
		toggleDraw () {
			/* 	if (this.programStore)
				this.programStore.draw = false; */
		},
		toggleView (type) {
			this.toggleDraw();
			if (this.programStore) {
				if (type === 'chart')
					{this.programStore.showGraph = !this.programStore.showGraph;}
				else if (type === 'table')
					{this.programStore.showTable = !this.programStore.showTable;}
			}
			var path = '/hybrid'; // default view
			if (!this.showTable || !this.showGraph)
				{path = this.showTable ? '/table' : '/graph';}

			this.$router.push(path);
		},
		verifiedHoursFilter () {
			this.showVerifiedHours = !this.showVerifiedHours;
			let outer = d3.select('#graphSVG');
			outer.selectAll('myLegends');
				let currentOpacity = d3
					.selectAll('.' + 'verifiedHour')
					.attr('opacity');
				d3.selectAll('.' + 'verifiedHour')
					.transition()
					.attr('opacity', currentOpacity == 1 ? 0 : 1);
		},
		hoursRanFilter () {
			this.showHrsReported = !this.showHrsReported;
			let outer = d3.select('#graphSVG');
			outer.selectAll('myLegends');
				let currentOpacity = d3.selectAll('.' + 'hoursRan').attr('opacity');
				d3.selectAll('.' + 'hoursRan')
					.transition()
					.attr('opacity', currentOpacity == 1 ? 0 : 1);

		},
		verifiedPrecipitationFilter () {
			this.showVerifiedApplied = !this.showVerifiedApplied;
			let outer = d3.select('#graphSVG');
			outer.selectAll('myLegends');
				let currentOpacity = d3
					.selectAll('.' + 'verifiedPrecipitation')
					.attr('opacity');
				d3.selectAll('.' + 'verifiedPrecipitation')
					.transition()
					.attr('opacity', currentOpacity == 1 ? 0 : 1);
		},
		precipitationFilter () {
			this.showAppliedReported = !this.showAppliedReported;
			let outer = d3.select('#graphSVG');
			outer.selectAll('myLegends');

				let currentOpacity = d3
					.selectAll('.' + 'precipitation')
					.attr('opacity');
				d3.selectAll('.' + 'precipitation')
					.transition()
					.attr('opacity', currentOpacity == 1 ? 0 : 1);
		},
	},
};
</script>
<style lang="css" scoped>
.icon {
	width: 30px;
	height: auto;
	margin-top: 4px;
}

.button_holder {
	width: 100px;
	height: 70px;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	display: flex;
}

.button_holder:hover {
	background-color: transparent;
}

.graph_filters_box {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	font-size: 12px;
}

.segmentOne {
	display: flex;
	flex-direction: column;
	width: 200px;
}

.all_filters {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.graph_filters {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0rem 1rem;
}

.type-filters {
	padding: 0rem 1rem;
}

.graph_filter_icons {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.view-options-label-holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

}

.view-options-label {
  font-weight:bold;
  font-size: .9em;
  padding-left: 6px;
  flex:1;
  white-space: nowrap;
}

.flex-end {
  align-self: end;
}

.right-options {
  display: flex;
  flex-direction: row;
  max-width: 250px;
  flex: 2;

}

.left-options {
  display: flex;
  flex-direction: row;
  max-width: 250px;
  flex:2;
}

.flex-spacer {
  flex: 6;
}

.option-buttons-holder {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
}

.option-buttons-left {
  max-width: 375px;
  display: flex;
  flex-direction: row;
  flex: 3;
}

.option-buttons-flex-spacer {
  flex: 5;
}

.option-buttons-right {
  max-width: 250px;
  display: flex;
  flex-direction: row;
  flex: 2;
}

.nui-button-stack {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.view-options-container {
  width: 100%;
}
</style>
