<template>
  <div class="prompt">
    <div class="prompt-label">{{ this.modalStore.modalObj.promptLabel }}</div>
      <div class="prompt-description">{{ this.modalStore.modalObj.promptDescription }}</div>
    <div class="button-holder">
      <div class="button" @click.stop="this.modalStore.modalObj.buttonAFunc">{{ this.modalStore.modalObj.buttonALabel }}</div>
      <div class="button" v-if="modalStore.modalObj.buttonBFunc != null" @click.stop="this.modalStore.modalObj.buttonBFunc">{{ this.modalStore.modalObj.buttonBLabel  }}</div>
    </div>
  </div>
</template>

<script>
import { useModalStore } from '@/stores/ModalStore';

export default {
  name: 'ModalPrompt',
  setup () {
    const modalStore = useModalStore();
    return {
      modalStore
    };
  },
  props: [
    'obj'
  ]
};
</script>

<style scoped>
.prompt {
  width: 350px;
  padding: 10px 20px;
  background-color: white;
}
.button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.button {
  margin: 5px;
  padding: 10px 25px;
  color: white;
  font-weight: 700;
  font-size: .9em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--nelson-green);
}
.prompt-label {
  font-weight: bold;
  padding-top:10px;
  padding-bottom:10px;
}
.prompt-description {
  padding-bottom: 10px;
  font-size: .95em;
}
</style>