<template>
  <div class="dateDisplay">
    <div class="dateHeading">
      {{ label }}
    </div>
    <div class="dateValue">
      {{ showAbbrDate(getRange[this.$props.time]) }}
    </div>
    <div class="date">{{ showDate(getRange[this.$props.time]) }}</div>
    <div class="weekday">{{ showWeekday(getRange[this.$props.time]) }}</div>
  </div>
</template>

<script>
import {useFunctionStore} from '@/stores/FunctionStore';
import dayjs from 'dayjs';

export default {
  name: 'DateDisplay',
  props: [
    'label',
    'range',
    'time'
  ],
  setup () {
    const functionStore = useFunctionStore();
    return {
      functionStore
    };
  },
  computed: {
    getRange () {
      return this.functionStore.range;
    }
  },
  methods: {
    showWeekday (d) {
      return dayjs(d).format('dddd');
    },
    showDate (d) {
      return dayjs(d).format('MMM D, YYYY');
    },
    showAbbrDate (d) {
      return dayjs(d).format('M-D');
    }
  },
};
</script>

<style scoped>
.dateDisplay {
  cursor: pointer;
  flex-grow: 1;
}
.dateHeading {
  font-weight: 700;
  font-size: .75em;
}
.dateValue {
  font-weight: 700;
  font-size: 2em;
  background-color: #f2f2f2;
  margin-bottom: 10px;
  letter-spacing: -.03em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dateValue:hover {
  background-color: #e7e7e7;
}
.date {
  font-weight: 700;
  font-size: .85em;
}
.weekday {
  font-size: .85em;
  transform: translateY(-5px);
}
</style>