<template>
	<h4 style="padding-left:15px;">Total Hours and Verified Hours in each Valve</h4>
	<BaseGraph v-if="graphItems !== null" :items="graphItems" :isByProgram="getIsByProgram" :valveCount="graphItems.length" :height="'60'" />
</template>

<script>

import { useProgramStore } from '../stores/ProgramStore';
import { mapStores } from 'pinia';
import BaseGraph from '@/components/BaseGraph.vue';
import { useMCStore } from '@/stores/MCStore';

export default {
	data () {
		return {
			valveName: null,
			verifiedHours: null,
			hoursRan: null,
			precipitation: null,
			verifiedPrecipitation: null,
			programColor: null,
			tooltipItem: null,
		};
	},
	computed: {
		...mapStores(useProgramStore, useMCStore),
		graphItems () {
			return this.programStore.currentEventData;
		},
		getIsByProgram () {
			return this.programStore.isByProgram;
		},
		/* getValveCount() {
			return this.programStore.valveCount
		} */
	},
	setup () {
		
	},
	mounted () {
		// need to call to draw graph here
		this.programStore.draw = true;
	},
	components: {
		BaseGraph
	},
	methods: {
	},
};
</script>

<style scoped>
path {
	fill: none;
	stroke: black;
}

line {
	stroke: black;
}
</style>
