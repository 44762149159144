<template>
  <div class="tos-holder">
    <div class="tos-header">
      TWIG REPORTING APPPLICATION Terms of Service
    </div>
    <div class="tos-subheader">
      BETA VERSION
    </div>
    <div class="tos-subheader">
      EFFECTIVE: June 1, 2023
    </div>
    <div class="tos-scroll" style="white-space: pre-line;">
      {{ tosText() }}
    </div>
    <div class="button-holder">
      <div class="button" @click="accept">Accept</div>
      <div class="button" @click="decline">Decline</div>
    </div>
  </div>
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore';
import { useAppStore } from '@/stores/AppStore';

export default {
  name: 'TOSModal',
  methods: {
    accept () {
      const identityStore = useIdentityStore();
      const appStore = useAppStore();
      appStore.isLoading = true;
      identityStore.isTos = true;
      const date = new Date().toLocaleString();
			identityStore.setUserProperty({ hasAgreed: date });
    },
    decline () {
      const identityStore = useIdentityStore();
      identityStore.signout();
    },
    tosText () {
      return `PLEASE READ THESE TERMS OF SERVICE (“TERMS”) CAREFULLY, INCLUDING THE MANDATORY MEDIATION AND ARBITRATION PROVISION WHICH REQUIRES THAT DISPUTES ARE RESOLVED BY FINAL AND BINDING MEDIATION AND ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS. BY ACCESSING OR USING OUR SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE OUR SERVICE.

        These Terms constitute a legal agreement between Nelson Irrigation Technologies, LLC, a Washington Limited Liability Company. (hereinafter referred to as “NIT,” “We”, “Us” or “Our”) and you (“Subscriber”, “You” or “Your”) and govern Your use of and access to the Service and Site by You and Your Users, whether in connection with a Paid or Free Subscription to the Service or a Free Trial of the Service.
        
        If You are using the Service on behalf of a company, organization or another legal entity (an “Entity”), You represent and warrant that you are authorized to accept these Terms on such Entity's behalf and that such Entity agrees to be responsible to Us if You violate these Terms, in which case the terms “Subscriber,” “You,” “Your” or related capitalized terms herein shall refer to such Entity and its affiliates. If You do not have such authority, or if You do not agree with these Terms, You cannot accept these Terms and may not use the Service.
        
        If you have any question regarding the Service or these Terms, please contact us at info@nelsonirrigationtech.com.


        1. DEFINITIONS

        When used in these Terms with the initial letters capitalized, in addition to terms defined elsewhere in these Terms, the following terms have the following meanings:

        Account: means all accounts or instances created by or on behalf of Subscriber or its Users within the Service to grant individual User access to the Service.  

        Documentation: means any written or electronic documentation, images, video, text or sounds specifying the functionalities of the Service provided or made available by NIT to You or Users through the Site or otherwise.

        Form: means any form generated by or other agreement related to the Service executed or approved by You with respect to Your Subscription to the Service, which form may detail, among other things, the number of Users authorized to use the Service under Your Subscription to the Service and the Service Plan applicable to Your Subscription to the Service.

        Free Trial:  means limited term access rights to the Service provided by NIT for the purposes of evaluating the Service.  

        JAMS: means a private alternative dispute resolution provider with headquarters located in Irvine, California.

        NIT: means Nelson Irrigation Technologies, LLC, a Washington Limited Liability Company located at 848 Airport Road, Walla Walla, WA 99362, United States, or any of its successors or assignees.

        Other Services: means third party products, applications, services, software, products, networks, systems, directories, websites, databases and information to which the Service links, or to which You may connect or enable in conjunction with the Service, including, without limitation, certain Other Services that may be integrated directly into the Service.

        Service: means The TWIG REPORTING App (TWIG REPORTING App) used in conjunction with a TWIG MASTER CONTROLLER (TWIG MC) device, including, individually and collectively, the Software, the API and any Documentation. 

        Service Plan: means the service plan for which You subscribe with respect to each User and in connection with the specific functionality and the Service identified in the service plan currently available or in the future.

        Site: means the use of the internet services through the TWIG REPORTING App or the TWIG MC Application.

        Software: means software provided by NIT (either by download or accessed through the Internet) that allows a User to use any functionality in connection with the Service.

        Subscription:  means Your agreement to make an advance payment in order to receive specific use of the Service for the Subscription Term.

        Subscription Term: means the period during which You have agreed to a Subscription to the Service.

        Update: means any new or modified features added to or augmenting the Service or updates or enhancements to the Service.  

        User or Users: means an individual authorized to use the Service through Your Account as a User as identified through a unique login. 

        
        2. GENERAL CONDITIONS; ACCESS TO AND USE OF THE SERVICE

        2.1 During the Subscription Term and subject to compliance by You and Your Users with these Terms, You have the limited right to access and use the Service consistent with Your Subscription to the Service Plan You select for Your internal business purposes. Without limiting the foregoing, Your right to access and use the Service is also subject to the restrictions and policies implemented by NIT from time to time with respect to the Service as set forth in the Documentation or otherwise communicated to You in writing.  All Updates are subject to these Terms, and We reserve the right to deploy Updates at any time without prior notice to You.

        2.2 A reliable high speed Internet connection is recommended for proper transmission of the Service. You are responsible for procuring and maintaining the network connections that connect Your network to the Service, including, but not limited to, “browser” software that supports protocols that are now or later used by the Service, which may include Secure Socket Layer (SSL) protocol or other protocols accepted by the Service, and to follow procedures for accessing services that support such protocols. We are not responsible for notifying You or Your Users of any Updates, upgrades, fixes or enhancements to any such software or for any compromise of data, transmitted across computer networks or telecommunications facilities (including but not limited to the Internet) which are not owned, operated or controlled by NIT. We assume no responsibility for the reliability or performance of any connections as described in this section or as used by You to access the Service. 

        2.3 You agree not to: (a) copy, translate, modify, or make derivative works of the Service or any part thereof; (b) redistribute, sublicense, rent, publish, sell, assign, lease, market, transfer, or otherwise make the Service available to any third party; (c) reverse engineer, decompile or otherwise attempt to extract the source code of the Service or any part thereof; (d) use the Service in a manner that gives You or any other person or party access to mass downloads or bulk feeds of any content; or (e) delete, obscure, or in any manner alter any warning or link that appears in the Service.

        2.4 You are responsible for compliance with the provisions of these Terms by Your Users and for any and all activities that occur under Your Account. You also maintain all responsibility for determining whether the Service or the information generated thereby is accurate or sufficient for Your purposes. You agree and acknowledge that each User will be identified by a unique username and password (“Login”) and that a User Login may only be used by one (1) individual. You will not share a User Login among multiple individuals. You and Your Users are responsible for maintaining the confidentiality of all Login information for Your Account, and you agree to use commercially reasonable efforts in meeting this obligation. We are not responsible for any damage you may suffer from unauthorized access resulting from Your or Your Users’ failure to safeguard and maintain confidentiality of any Login associated with Your Account.

        2.5 In addition to Our rights as set forth in Section 7.4, NIT reserves the right, in Our sole discretion, to temporarily suspend Your or Your Users’ access to and use of the Service: (a) during planned downtime (“Planned Downtime”) for upgrades and maintenance to the Service (for which NIT will use commercially reasonable efforts to notify You in advance); (b) during any unavailability caused by circumstances beyond Our reasonable control, such as, but not limited to, acts of God, pandemics, acts of government, acts of terror or civil unrest, technical failures beyond Our reasonable control (including, without limitation, inability to access the Internet), or acts undertaken by third parties, including without limitation, distributed denial of service attacks; or (c) if We suspect or detect any viruses, malware, Trojan horses, time bombs, or any other similar harmful software connected to Your Account or use of the Service by You or Your Users. We will use commercially reasonable efforts to schedule Planned Downtime for weekends (Pacific Time Zone) and other off-peak hours.

        2.6 The Service is a tool to aid a trained irrigation specialist in generating reports or information related to the performance of watering programs and valve activity associated with an individual TWIG MC. THIS SERVICE IS A DATA REPORTING TOOL ONLY AND ANY FITNESS FOR USE IN THE FIELD IS THE RESPONSIBILITY OF THE USER AND SUBJECT TO THE DISCLAIMER OF WARRANTIES SET OUT AT SECTION 8.1 HEREIN.

        2.7. Acknowledgment of Beta: User acknowledges and agrees that: (a) the use of the Service as contemplated by User is not a use that is presently being commercially marketed by NIT and may contain errors or other functionality problems; (b) the information or reports generated by the Service may not be accurate and may not accurately correspond to information extracted from any database or other source; (c) NIT has the right unilaterally to abandon or disallow access to the Service at time and without any obligation or liability to User. User acknowledges and agrees that it should not rely on the Service for any reason; (d) Although NIT is allowing User access to the Service free of charge, NIT reserves the right to charge a fee for the use of the Service; (e) User is solely responsible for maintaining and protecting all data and information that is retrieved, extracted, transformed, loaded, stored or otherwise processed as part of User’s use of the Service. User will be responsible for all costs and expenses required to backup and restore any data and information that is lost or corrupted as a result of User’s use of the Service. 

        
        3. YOUR PERSONAL INFORMATION AND YOUR CONTENT

        3.1 We use, disclose, and protect Your Personal Information and Your Content as described in Our Privacy Policy, which is incorporated by reference into the Terms.

        3.2 NIT will maintain commercially reasonable administrative, physical and technical safeguards to protect the security, confidentiality and integrity of Your Personal Information. These safeguards may include encryption of Personal Information in transmission (using SSL or similar technologies), except for certain Other Services that do not support encryption, which You may link to through the Service at Your election.

        3.3 You agree that NIT and the service providers We use to assist in providing the Service to You shall have the right to access Your Account and to use, modify, reproduce, distribute, display and disclose Your Personal Information and Your Content that Your Users store in the Service to the extent necessary to provide the Service. Any service providers We utilize will only be given access to Your Account, Your Personal Information and Your Content as is reasonably necessary to provide the Service.

        3.4 NIT may also access or disclose information about You, Your Account, and Your Users, including Your Personal Information or Content that You or Your Users store within the Service in order to (a) comply with the law or respond to lawful requests or legal process; (b) protect NIT or its customers’ or partners’ rights or property, including enforcement of these Terms or other policies associated with the Service; (c) act on a good faith belief that such disclosure is necessary to protect personal safety or avoid violation of applicable law or regulation.

        
        4. INTELLECTUAL PROPERTY RIGHTS

        Unless otherwise indicated by us, the Service and all Documentation, Forms, content, intellectual property, proprietary rights and/or other materials therein, including without limitation all Our respective patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how, designs, text, graphics, pictures, information, data, software, sound files, other files, the selection and arrangement thereof (collectively, “Intellectual Property Rights”) are the proprietary property of NIT or our licensors or suppliers and may be protected by U.S. and international patent, trademark and copyright laws.

        The TWIG trademark and NIT’s other product and service names and logos used or displayed on or in connection with the Service are registered or unregistered trademarks of NIT (collectively, “Marks”).  You may only use such Marks to identify You as a Subscriber; provided You do not attempt, now or in the future, to claim any rights in the Marks, degrade the distinctiveness of the Marks, or use the Marks to disparage or misrepresent the Service, NIT, its services or products.  All goodwill arising out of any use of the Marks by You will inure solely for the benefit of NIT.

        We hereby grant You and Your Users a limited, nonexclusive and non-sublicensable license to access and use the Service for Your and Your Users’ internal business use. Such license is subject to these Terms and does not convey any additional rights in the Service, or in any Intellectual Property Rights associated therewith. Subject only to limited rights to access and use the Service as expressly herein, all rights, title and interest in and to the Service and all hardware, software and other components of or used to provide the Service, including all related Intellectual Property Rights, will remain with and be owned exclusively by NIT. Any use of the Service other than as specifically authorized herein, without Our prior written permission, is strictly prohibited and will terminate the license granted herein at the discretion of NIT. Such unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws and applicable communications regulations and statutes.

        You hereby assign and agree to assign to NIT all rights in and to any suggestions, enhancement requests, recommendations or other feedback (collectively, “Feedback”) We receive from You or Your Users, including but not limited to the right to incorporate any Feedback into the Service. You understand that any such Feedback you submit is non-confidential.

        
        5. THIRD PARTY SERVICES

        If You decide to enable, access or use Other Services, You acknowledge and agree that Your access and use of such Other Services is governed solely by the terms and conditions of such Other Services, and We do not endorse, are not responsible or liable for, and make no representations as to any aspect of such Other Services, including, without limitation, their content or the manner in which they handle data or any interaction between You and the provider of such Other Services. You irrevocably waive any claim against NIT with respect to or based upon the use of such Other Services. NIT is not liable for any damage or loss caused or alleged to be caused by or in connection with Your enablement, access or use of any such Other Services, or Your reliance on the privacy practices, data security processes or other policies of such Other Services. You may be required to register for or log into such Other Services on their respective websites. By enabling any Other Services, You are expressly permitting NIT to disclose Your Login as well as Your Data as necessary to facilitate the use or enablement of such Other Service.

        
        6. BILLING, PLAN MODIFICATIONS AND PAYMENTS

        6.1 Service Plan Subscriptions to the Service are provided at no charge to our clients at this time.  However, we may change the pricing policy for subsequent versions of the Service, specific features of the Service or for relationships other than our direct customers.   In the event that additional Service Plans are offered for a fee, the following apply:

        6.1.1 Unless otherwise indicated on a Form referencing these Terms and subject to Section 6.1.2, all charges associated with Your access to and use of the Service (“Subscription Charges”) are due in full upon commencement of Your Subscription Term. If You fail to pay Your Subscription Charges or charges for other services indicated on any Form referencing these Terms within fifteen (15) business days of Our notice to You that payment is due or delinquent unless otherwise agreed to by Us, or if You do not take steps to bring Your Account status to current upon Our request, in addition to Our other remedies, We may suspend or terminate access to and use of the Service by You and Your Users.

        6.1.2 Upon the purchase of Your Subscription, you agree to an automatic, reoccurring payment.  

        6.1.3 No refunds or credits for Subscription Charges or other fees or payments will be provided to You if You elect to downgrade Your Service Plan. Downgrading Your Service Plan may cause loss of content, features, or capacity of the Service as available to You under Your Account, and NIT accepts no liability for such loss. NIT reserves the right to contact You about special pricing if You maintain an exceptionally high number of Users or other excessive stress on the Service.

        6.1.4 Unless otherwise stated, Our charges do not include any taxes, levies, duties or similar governmental assessments, including value-added, sales, use or withholding taxes assessable by any local, state, provincial or foreign jurisdiction (collectively “Taxes”). You are responsible for paying Taxes except those assessable against NIT based on its income. We will invoice You for such Taxes if We believe We have a legal obligation to do so and You agree to pay such Taxes if so invoiced.

        
        7. CANCELLATION AND TERMINATION

        7.1 Either You or NIT may elect to terminate Your Account and Subscription to the Service as of the end of your then current Subscription Term by providing notice, in accordance with these Terms, on or prior to the date thirty (30) days preceding the end of such Subscription Term. Unless Your Account and Subscription to the Service is so terminated, Your Subscription to the Service will renew for a Subscription Term equivalent in length to the then expiring Subscription Term. Unless otherwise provided for in any Form, the Subscription Charges applicable to Your Subscription to the Service for any such subsequent Subscription Term shall be Our standard Subscription Charges for the Service Plan to which You have subscribed as of the time such subsequent Subscription Term commences.

        7.2 No refunds or credits for Subscription Charges or other fees or payments will be provided to You if You elect to terminate Your Subscription to the Service or cancel Your Account prior to the end of Your then effective Subscription Term. Following the termination or cancellation of Your Subscription to the Service and/or Account, We reserve the right to delete all Your Content or other information in the normal course of operation. Upon termination of Your Subscription to the Service or cancelation of Your Account, We have no obligation to return Your Content or other information. Your Content or other information may not be recoverable once Your Account is cancelled.

        7.3 If You terminate Your Subscription to the Service or cancel Your Account prior to the end of Your then effective Subscription Term or We effect such termination or cancellation pursuant to 7.4, in addition to other amounts You may owe NIT, You must immediately pay any then unpaid Subscription Charges associated with the remainder of such Subscription Term. This amount will not be payable by You in the event You terminate Your Subscription to the Service or cancel Your Account as a result of a material breach of these Terms by NIT, provided that You provide prompt written notice of such breach to NIT and afford NIT not less than thirty (30) days to reasonably cure such breach.

        7.4 NIT reserves the right to modify, suspend or terminate the Service (or any part thereof), Your Account or Your and/or Users’ rights to access and use the Service, and remove, disable and discard any of Your information if We believe that You or Your Users have violated these Terms. Any suspected fraudulent, abusive, or illegal activity by You, Your Users or End Users may be referred to law enforcement authorities at Our sole discretion.

        
        8. DISCLAIMER OF WARRANTIES

        8.1 EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY US, THE SITE AND THE SERVICE, INCLUDING ALL DOCUMENTATION, CONTENT, FORMS, SERVER AND NETWORK COMPONENTS, ARE PROVIDED, TO THE FULLEST EXTENT PERMITTED BY LAW, ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. NIT EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.

        WHILE NIT ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE SAFE, YOU ACKNOWLEDGE THAT NIT DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUSCODE. THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO PROTECT AGAINT MALICIOUS CODE AND DETECT AND DISINFECT VIRUSES. NO INFORMATION OR ADVICE OBTAINED BY YOU FROM NIT OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.

        YOU ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSMIT THROUGH THE SERVICE MAY BECOME IRRETRIEVABLY LOST, CORRUPTED, OR TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES, INCLUDING BUT NOT LIMITED TO PHYSICAL DAMAGE TO THE TWIG MC, POWER FAILURE, SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY PROVIDERS, LOSS OF CONNECTION, INTERNET OUTAGES, ACTS OF GOD OR OTHER DISASTERS, SCHEDULED OR UNSCHEDULED MAINTENANCE, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR CONTROL.

        Some jurisdictions do not allow the disclaimer of implied terms in contracts with consumers, so some or all of the disclaimers in this section may not apply to you.

        8.2 Software and Firmware: Unless otherwise provided by in an NIT or third party license, NIT warrants that standard software or firmware Services furnished hereunder, when used with NIT specified hardware will perform in accordance with published specifications prepared, approved, and issued by NIT for a period of one (1) year from the date of invoice from NIT or its appointed distributor, as the case may be.  NIT makes no representation or warranty, express or implied, that the operation of the software or firmware Products Services will be uninterrupted or error free, or that the functions contained therein will meet or satisfy User’s intended user or requirements.

        
        9. LIMITATION OF LIABILITY

        9.1 IN NO EVENT, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL NIT, OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS, BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, BUSINESS INTERRUPTION OR ANY OTHER LOSS INCURRED BY YOU OR A THIRD PARTY IN CONNECTION WITH THESE TERMS OR THE SERVICE, REGARDLESS OF WHETHER WE HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN SUCH DAMAGES.

        9.2 NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, NIT’S TOTAL AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT OF THESE TERMS OR OTHERWISE IN CONNECTION WITH ANY SUBSCRIPTION TO, OR USE OR EMPLOYMENT OF THE SERVICE, SHALL IN NO EVENT EXCEED THE SUBSCRIPTION CHARGES IF ANY PAID BY YOU DURING THE TWELVE (12) MONTHS PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH LIABILITY. YOU ACKNOWLEDGE AND AGREE THAT THE ESSENTIAL PURPOSE OF THIS SECTION 9.2 IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES AND LIMIT POTENTIAL LIABILITY GIVEN THE SUBSCRIPTION CHARGES, WHICH WOULD HAVE BEEN SUBSTANTIALLY HIGHER IF NIT WERE TO ASSUME ANY FURTHER LIABILITY OTHER THAN AS SET FORTH HEREIN. NIT HAS RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO PROVIDE YOU THE RIGHTS TO ACCESS AND USE THE SERVICE PROVIDED FOR IN THESE TERMS.

        9.3 Some jurisdictions do not allow the limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to You. IN THESE JURISDICTIONS, NIT’S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.  

        
        10. INDEMNIFICATION

        You will indemnify and hold NIT harmless against any claim brought by a third party against NIT arising from or related to use of the Service by You or Your Users in breach of these Terms or matters which You have expressly agreed to be responsible pursuant to these Terms. You will cooperate as fully as reasonably required in the defense of any claim. NIT reserves the right to assume exclusive defense and control of any matter subject to indemnification by You.

        
        11. ASSIGNMENT; ENTIRE AGREEMENT; REVISIONS

        11.1 You may not, directly or indirectly, by operation of law or otherwise, assign all or any part of these Terms or any Form, or Your rights under these Terms or any Form or delegate performance of Your duties under these Terms or any Form, without NIT’s prior written consent. We may, without Your consent, assign Our agreement with You to any affiliate or in connection with any merger or change of control of NIT or the sale of all or substantially all of Our assets. Subject to the foregoing restrictions, these Terms will be fully binding upon, inure to the benefit of and be enforceable by the parties and their respective successors and assigns.

        11.2 These Terms, together with any Form(s), constitute the entire agreement, and supersede any and all prior agreements between You and NIT with regard to the subject matter hereof. We may amend or revise these Terms from time to time, in which case the new Terms will supersede prior versions. If we make any material changes to the Terms, we will notify you by email or by posting a prominent notice on the Site prior to the change becoming effective. We encourage you to periodically review the Terms to take notice of any changes, as revised versions will be binding on You. Your continued use of the Service following the effective date of any such revision or amendment may be relied upon by NIT as Your consent to any such revision or amendment.

        11.3 NIT’s failure to enforce at any time any provision of these Terms does not constitute a waiver of that provision or of any other provision of the Terms.

        11.4 If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.

        11.5	Other NIT Agreements Applicable. Except as expressly modified herein You agree that any other NIT Agreements, including but not limited to the TWIG Master Controller Terms of Service dated May 11, 2020, are in full force and effect and You agree to be bound by such other Agreements of NIT. 

        
        12. MEDIATION AND ARBITRATION

        PLEASE READ THE FOLLOWING PARAGRAPH CAREFULLY BECAUSE IT REQUIRES YOU TO MEDIATE AND ARBITRATE DISPUTES WITH NIT AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.

        You and NIT agree to mediate and arbitrate any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of these Terms or your use of the Services, except that you and NIT are NOT required to mediate and arbitrate any dispute in which either party seeks equitable and other relief for the alleged unlawful use of the Intellectual Property Rights of either party. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL.

        You and NIT agree: (a) to attempt informal resolution for a period of not less than fourteen (14) days prior to any demand for mediation and arbitration; (b) if unsuccessful, that any mediation will occur in Walla Walla County, Washington; (c) that any arbitration will occur in Walla Walla County, Washington; (d) that mediation and arbitration will be conducted confidentially by a single mediator and if necessary a single, separate arbitrator in accordance with the rules of JAMS and as set forth herein; and (e) that the arbitration shall be binding with no right of appeal. The arbitration shall be commenced by filing a demand for arbitration with the administrator of JAMS and serving the demand on the opposing party. The responding party may file a response and/or a counter-claim within fifteen (15) calendar days. If no response is filed, all the allegations of the demand shall be deemed denied. The parties shall select an arbitrator by mutual agreement through JAMS within thirty (30) calendar days of the date the demand for arbitration is filed. If the parties are unable to agree on the selection of an arbitrator within such time, the administrator of JAMS shall select an independent arbitrator. The costs of the arbitration, including the arbitrator’s fees, shall be borne equally by the parties to the arbitration, unless the arbitrator orders otherwise. The prevailing party shall be entitled to recover its attorneys’ fees and costs, and the arbitrator shall have the authority to make an award of attorneys’ fees to the prevailing party as required or permitted by applicable law. Other than class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available in court. The arbitration award may be enforced by a competent court in any country or jurisdiction.

        WHETHER THE DISPUTE IS HEARD IN MEDIATION, ARBITRATION OR IN COURT, YOU AND NIT AGREE THAT YOU WILL NOT COMMENCE AGAINST THE OTHER A CLASS ACTION, CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION OR PROCEEDING.

        
        13. GOVERNING LAW; VENUE

        These Terms are governed by and construed in accordance with the laws of the State of Washington and applicable United States law, without giving effect to any conflict of laws principles. You agree that any action at law or in equity arising out of or relating the alleged unlawful use of the Intellectual Property Rights of either party will be filed only in the federal courts located in the Eastern District of Washington. If either party brings an action in court pursuant to these Terms, the prevailing party shall be entitled to recover its attorneys’ fees and costs as required or permitted by applicable law, whether before or at trial, and upon any appeals.`;
    }
  }
};
</script>

<style scoped>
.tos-holder {
  width: 90%;
  background-color: #ffffff;
}
.tos-header {
  background-color: white;
  text-align: center;
  font-size: 2em;
  font-weight: 700;
}
.tos-subheader {
  background-color: white;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
}
.tos-scroll {
  overflow-y: scroll;
  height: 70vh;
  padding: 10px;
}
.button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.button {
  margin: 5px;
  padding: 10px 25px;
  color: white;
  font-weight: 700;
  font-size: .9em;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--nelson-green);
}
</style>