<template>
  <div class="modal-bg">
    <div class="modal-title">MODAL CALENDAR</div>
    <DatePicker v-model="range" :columns="2" @click.stop="" is-range color="gray" :attributes="attrs" class="date-picker"/>
    <div class="date-holder">
      <div>
        <div class="label">FROM</div>
        <div class="date-bg">
          <div class="date">{{ showDate(range.start) }}</div>
          <div class="weekday">{{ showWeekday(range.start) }}</div>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <div class="label">TO</div>
        <div class="date-bg">
          <div class="date">{{ showDate(range.end) }}</div>
          <div class="weekday">{{ showWeekday(range.end) }}</div>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="save">SAVE</div>
    </div>
  </div>
</template>

<script>
import { useFunctionStore } from '@/stores/FunctionStore';
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
dayjs.locale('en');
import weekday from 'dayjs/plugin/weekday';
import { storeToRefs } from 'pinia/dist/pinia';
dayjs.extend(weekday);

export default {
  name: 'ModalCalendar',
  components: {
    DatePicker
  },
  data () {
    return {
      date: new Date(),
      attrs: [
        {
          highlight: {
            start: { fillMode: 'outline' },
            base: { fillMode: 'light' },
            end: { fillMode: 'outline' },
          },
          dates: { start: new Date(2019, 0, 14), end: new Date(2019, 0, 18) },
        },
      ],
    };
  },
  setup () {
    const functionStore = useFunctionStore();
    const { range } = storeToRefs(functionStore);
    return {
      functionStore, range
    };
  },
  methods: {
    showWeekday (d) {
      return dayjs(d).format('dddd');
    },
    showDate (d) {
      return dayjs(d).format('MMM D, YYYY');
    }
  }
};
</script>

<style scoped>
.date-picker  {
  width: 100%;
  padding: 50px;
  font-family: "Heebo", sans-serif;
}
.label {
  font-size: .75em;
  font-weight: 700;
}
.date-bg {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: lightgrey;
}
.date {
  font-weight: 700;
  font-size: .85em;
}
.weekday {
  font-size: .85em;
}
.date-holder {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 50px;
}
.save {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 700;
  cursor: pointer;
}
.spacer {
  width: 6px;
}
</style>

<style>
.vc-container {
  border: none;
}
.vc-day {
  min-height: 45px;
}
.vc-highlight {
  min-height: 45px;
  width: 45px;
}
span.vc-day-content.vc-focusable {
  background-color: transparent;
}
</style>