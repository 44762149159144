<template>
    <h4 style="padding-left:15px;">Total Hours and Verified Hours in each Valve</h4>
    <BaseTable :headers="headers" :items="tableItems" :subheaders="subHeaders" :height="'60'" class="table-view-basetable"/>
</template>
<script>
import { useProgramStore } from '../stores/ProgramStore';
import { useMCStore } from '@/stores/MCStore';
import { useSettingsStore } from '../stores/SettingsStore';
import BaseTable from '@/components/BaseTable.vue';
import { mapStores } from 'pinia';
export default {
    name: 'TableView',
    components: {
        BaseTable
    },
    computed: {
        ...mapStores(useMCStore, useProgramStore, useSettingsStore),
        graphItems () {
            return this.programStore.formatDataForGraph();
        },
        tableItems () {
            const key = 'valveId';
            let filterDuplicates;
            if (this.programStore.isSingleValve === false) {
                filterDuplicates = [...new Map(this.programStore.currentEventData.map(item => [item[key], item])).values()];
                filterDuplicates[0].program != null ? filterDuplicates.sort((a, b) => a.program.localeCompare(b.program)) : filterDuplicates;
            } else {
                filterDuplicates = this.programStore.currentEventData;
            }
            return filterDuplicates;
        },
        subHeaders () {
            return this.programStore.tableSubHeaders();
        },
        headers () {
            return this.programStore.tableHeaders();
        }
    },
    methods: {
        rowClick (value) {
            this.highlightClickedRow(value);
        },
        highlightClickedRow (value) {
            const tr = value.target.parentNode;
            tr.classList.add('highlight');
        },
    }
};
</script>
<style scoped>
.highlight:hover {
    background-color: #ec9e4696;
}

.table-view-basetable {

  overflow-y: scroll;

}

</style>