<template>
  <div ref="hybridView" class="hybrid-view">
    <h4 ref="chartTitle" class="chart-title">Total Hours and Verified Hours in each Valve</h4>
    <div ref="chartView" class="chart-view">
      <BaseGraph :isByProgram="getIsByProgram" :items="graphItems" :height="'45'" />
    </div>
    <div ref="dragSizer" class="drag-sizer" @touchstart="handleStart" @touchmove="handleDrag" @touchend="handleTouchEnd" @mousedown="handleMouseStart" @mouseup="handleMouseUp">
      <div class="drag-line"></div>
    </div>
    <div ref="tableView" class="table-view">
      <BaseTable v-if="graphItems.length > 0" :headers="headers" :items="tableItems" :height="'20'" :subheaders="subHeaders" />
    </div>
  </div>
<!--   <h4 style="padding-left:15px;padding-top:0;margin:0;">Total Hours and Verified Hours in each Valve</h4>
   <BaseGraph :isByProgram="getIsByProgram" :items="graphItems" :height="'45'" />
   <v-divider v-if="graphItems.length > 0" :thickness="3"></v-divider>
   <BaseTable v-if="graphItems.length > 0" :headers="headers" :items="tableItems" :height="'20'" :subheaders="subHeaders" />-->
</template>

<script>
import { useProgramStore } from '../stores/ProgramStore';
import { useMCStore } from '@/stores/MCStore';
import { mapStores } from 'pinia';
import BaseGraph from '@/components/BaseGraph.vue';
import BaseTable from '@/components/BaseTable.vue';
import * as d3 from 'd3';

export default {
   data () {
      return {
         valveName: null,
         verifiedHours: null,
         hoursRan: null,
         precipitation: null,
         verifiedPrecipitation: null,
         programColor: null,
         startY: null,

      };
   },
   components: {
      BaseGraph,
      BaseTable
   },
   computed: {
      ...mapStores(useProgramStore, useMCStore),
      graphItems () {
         return this.programStore.currentEventData;
      },
      tableItems () {
         const key = 'valveId';
         let filterDuplicates;
         if (this.programStore.isSingleValve === false) {
            filterDuplicates = [...new Map(this.programStore.currentEventData.map(item => [item[key], item])).values()];
            filterDuplicates[0].program != null ? filterDuplicates.sort((a, b) => a.program.localeCompare(b.program)) : filterDuplicates;
         } else {
            filterDuplicates = this.programStore.currentEventData;
         }
         return filterDuplicates;
      },
      getIsByProgram () {
         return this.programStore.isByProgram;
      },
      currentRouteName () {
         return this.$route.name;
      },
      headers () {
         return this.programStore.tableHeaders();
      },
      subHeaders () {
         return this.programStore.tableSubHeaders();
      },
   },
  
  methods: {
    handleClick (e) {

      this.startY = e.touches[0].pageY;

    },
    
    handleResize () {

      this.$refs.tableView.style.maxHeight = this.$refs.hybridView.clientHeight - this.$refs.chartTitle.clientHeight - this.$refs.chartView.clientHeight - this.$refs.dragSizer.clientHeight + 'px';

    },
    
    handleDrag (e) {


      let py = (e.touches) ? e.touches[0].pageY :  e.pageY;
      let d = py - this.$refs.hybridView.offsetTop - this.$refs.chartTitle.clientHeight;

      // upper limit
      if(d <= 0) {
        this.$refs.chartView.style.height = '0';
        return;
      }

      // percentages of chart and table view 58 42
      // lower limit
      if(d > (this.$refs.hybridView.clientHeight - this.$refs.chartTitle.clientHeight) * .58) {
        this.$refs.chartView.style.height = ((this.$refs.hybridView.clientHeight - this.$refs.chartTitle.clientHeight) * .58) + 'px';
        return;
      }

      this.$refs.chartView.style.height = d + 'px';
      this.$refs.tableView.style.maxHeight = this.$refs.hybridView.clientHeight - this.$refs.chartTitle.clientHeight - d - this.$refs.dragSizer.clientHeight + 'px';

    },
    handleStart () {

      this.startY = this.$refs.dragSizer.offsetTop - this.$refs.hybridView.offsetTop;
      this.$refs.dragSizer.style.backgroundColor = 'gray';

    },

    handleTouchEnd () {
      this.$refs.dragSizer.style.backgroundColor = 'whitesmoke';
    },

    handleMouseStart (e) {

      e.preventDefault();

      this.handleStart();
      window.addEventListener('mousemove', this.handleDrag);
      window.addEventListener('mouseup', this.handleMouseUp);
      
    },

    handleMouseUp () {

      this.handleTouchEnd();
      window.removeEventListener('mousemove', this.handleDrag);
      window.removeEventListener('mouseup', this.handleMouseUp);

    }
  },
   setup () {
      d3.select('.graphSVG').selectAll('svg > *').remove();
   },
   mounted () {
      d3.select('.graphSVG').selectAll('svg > *').remove();
      this.programStore.draw = false;
      if (this.mcStore.AllValveEventData != null && this.mcStore.AllValveEventData.length > 0) {
         this.programStore.draw = true;
         // // Set draw blank graph to false because it is not needed any more.
         // this.programStore.drawBlank = false
      }
      // // Creates a blank graph at home screen
      // this.programStore.draw = true
     
     window.addEventListener('resize', this.handleResize);
     
   },
  unmounted () {

    window.removeEventListener('resize', this.handleResize);

  }
};
</script>

<style>

/* ===== Scrollbar CSS ===== */

/* note: table-view is inside of TableView.vue */

.input-table-holder::-webkit-scrollbar,
.table-view-basetable::-webkit-scrollbar,
.chart-view::-webkit-scrollbar,
.table-view::-webkit-scrollbar {
  width: 8px;

}

.input-table-holder::-webkit-scrollbar-track,
.table-view-basetable::-webkit-scrollbar-track,
.chart-view::-webkit-scrollbar-track,
.table-view::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.0);
}

.input-table-holder::-webkit-scrollbar-thumb,
.table-view-basetable::-webkit-scrollbar-thumb,
.chart-view::-webkit-scrollbar-thumb,
.table-view::-webkit-scrollbar-thumb {
  margin: 2px;
/*  background-color: #ffffff;*/
    background-color: #d0d0d0;
  border-radius: 10px;
/*  box-shadow: inset 0 0 10px 10px #d0d0d0;*/
  border: solid 3px transparent;
}


</style>


<style scoped>

.chart-view {

  width: 100%;
  height: 58%;
  overflow-y: scroll;
  flex: auto;

}

.chart-title {
  padding-left:15px;
  padding-top:0;
  margin:0;
  height: 25px;

}

.table-view {

  width: 100%;
  /* 45 is from the height of the chart title and the height of the drag sizer */
  max-height: calc(42% - 45px);
  overflow-y: scroll;

}

.drag-sizer {
  cursor: row-resize;
  width: 100%;
  height: 20px;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .1s background-color;
}

.drag-sizer:hover {
  background-color: #e7e7e7;
}

.drag-line {
  height: 5px;
  width: 100px;
  background-color: lightgray;
  border-radius: 3px;
}

.hybrid-view {
  height: 100%;
}

</style>