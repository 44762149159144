<template>
  <div class="search-container">
  <div ref="selected" v-if="getSelectorType.length > 0" :class="getKeywordClass" @click.stop="showAllItems">
    <KeywordOption v-for="(option, index) in getSelectorType" :key="index" :option="option" :index="index" :removeable="true" :func="handleOptionRemove"></KeywordOption>
  </div>
  <div class="search-bar" >
    <input type="text" class="keyword-dropdown-text" :placeholder="this.getPlaceholderText()" v-model="RMTStore.keywordSearchText" @input="handleKeywordSearch" @focus="handleFocus" >
    <div class="icons">
      <div class="clear-search">
        <v-icon
          v-show="RMTStore.keywordSearchText"
          title="Clear Search"
          icon="close"
          @click="clearSearch"
        >
          clear
        </v-icon>
      </div>
      <div class="search-icon">
        <v-icon icon="search"></v-icon>
      </div>
    </div>
    <div class="overflow-text" ref="overflowText"  v-show="this.overflow" @click="showAllItems">+ {{ this.remainingItems }} more items</div>
  </div>
  <div class="keyword-autocomplete-holder" v-if="inputFocused">
    <div ref="keywordAutocomplete" class="keyword-autocomplete">
      <div class="recent" v-show="getMatchingOptions.length === 0" >No matches found. <span class="view-all" @click="handleViewAll">View All {{ this.selectType() }}</span></div>
      <KeywordOption v-for="(option, index) in getMatchingOptions" :key="index" :option="option" :removeable="false" @click.stop="addKeywordToSelection(option)"></KeywordOption>
    </div>
  </div>
</div>
  <div v-if="inputFocused" class="input-bg" @click="handleBlur"></div>
</template>

<script>
import { useRMTStore } from '@/stores/RMTStore';
import KeywordOption from '@/components/nelsonUI/KeywordOption';
import { useSelectionStore } from '@/stores/SelectionStore';
import { useModalStore } from '@/stores/ModalStore';
import { useFunctionStore } from '@/stores/FunctionStore';
import { storeToRefs } from 'pinia/dist/pinia';
import { useProgramStore } from '@/stores/ProgramStore';

export default {
  name: 'KeywordSelector',
  components: {
    KeywordOption
  },
  props: [
    'type'
  ],
  updated () {
    if (this.$refs.selected === undefined || this.$refs.selected === null) {
      this.overflow = false;
      return;
    }
    let children_count = 0;
    for (let a = 0 ; a < this.$refs.selected.children.length ; a++) {
      let child = this.$refs.selected.children[a];
      let rect = child.getBoundingClientRect();
      if (rect.top > this.$refs.selected.clientHeight) {
        children_count++;
      }
    }
    this.children_count = children_count;
    this.overflow = this.$refs.selected.scrollHeight > this.$refs.selected.clientHeight;
  },
  data () {
    return {
      overflow: false,
      children_count: 0,
      inputFocused: false
    };
  },
  setup () {
    const RMTStore = useRMTStore();
    const selectionStore = useSelectionStore();
    const modalStore = useModalStore();
    const functionStore = useFunctionStore();
    const programStore = useProgramStore();
    const { selectorMode } = storeToRefs( functionStore );
    const { twigMcPlans } = storeToRefs( programStore );
    return {
      RMTStore, selectionStore, modalStore, selectorMode, twigMcPlans, programStore
    };
  },
  computed: {
    getSelectorType () {
      return this.RMTStore.getSelectedProgramType();
    },
    getMatchingOptions () {
      return this.RMTStore.getMatching('');
    },
    getRecent () {
      return this.RMTStore.getAllRecent(this.$props.type).length > 0;
    },
    getKeywordClass () {
      return (this.inputFocused) ? 'keywords-holder' : 'keywords-holder holder-focused';
    },
    remainingItems () {
      return this.children_count;
    }
  },
  methods: {
    handleTextInput () {
      this.RMTStore.keywordSearchText = '';
    },
    clearSearch () {
      // clears search when clicked
      this.RMTStore.keywordSearchText = '';
      this.RMTStore.setTag('tags', this.RMTStore.keywordSearchText);
    },
    resetSelector () {
      this.children_count = 0;
      this.overflow = false;
    },
    getPlaceholderText () {
      return (this.selectorMode === 'program') ? 'Search programs' : 'Search valves';
    },
    selectType () {
      return (this.selectorMode === 'program') ? 'Programs' : 'Valves';
    },
    handleKeywordSearch () {
      // todo: for some reason, not about to do this with a string, but can do with an array
      // switching to sending over type and doing switch in the store
      // would prefer to keep it consistent if possible
      this.RMTStore.setTag(this.$props.type, this.RMTStore.keywordSearchText);
    },
    handleOptionRemove ( option) {
      this.RMTStore.removeKeywordFromSelection(option);
    },
    addKeywordToSelection (option) {
      option.new = true;
      this.RMTStore.addKeywordToSelection(this.getSelectorType, option);
    },
    showAllItems () {
      if (!this.overflow) {return;}
      this.modalStore.toggleModal('select');
    },
    handleFocus () {
      this.inputFocused = true;
    },
    handleBlur (event) {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.inputFocused = false;
      }
    },
    handleViewAll (e) {
      this.handleBlur(e);
      this.modalStore.setModalBackground(false);
      this.modalStore.toggleModal('view all');
    }
  }
};
</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  /* min-width: 400px; */
  justify-content: space-between;
  min-width: 200px;
  width: 100%;
  flex:1;
}
.icons {
  position: relative;
  z-index: 99999;
  right: 55px;
  top: 3px;
  display: grid;
  grid-template-columns: 23px 23px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
}
.clear-search {
  cursor: pointer;
  grid-column: 1/2;
  grid-row: 1/2;
}
.search-icon {
  cursor: pointer;
  grid-column: 2/3;
  grid-row: 1/2;
} 
.overflow-text {
  font-size: .75em;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.keyword-dropdown-text {
  width: 100%;
  min-width: 60%;
  min-height: 35px;
  margin-top: 8px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: .8em;
  border: 1px solid lightgray;
  position: relative;
  z-index: 99999 !important;
}
input[type="text"], textarea {
  background-color: white;
}
.keyword-autocomplete-holder {
  max-width:550px;
  margin: 6px;
  background-color: white;
  border: 1px solid #a8a8a8;
  display: flex;
  flex-wrap: wrap;
  z-index: 999 !important;
  position: relative;
  backface-visibility: hidden;
  -webkit-box-shadow: 6px 6px 17px -2px rgba(0,0,0,0.18);
  -moz-box-shadow: 6px 6px 17px -2px rgba(0,0,0,0.18);
  box-shadow: 6px 6px 17px -2px rgba(0,0,0,0.18);
}
.keywords-holder {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
/*  margin-bottom: 10px;*/
  background-color: white;
/*  min-width: 400px;*/
  min-height: 75px;
  max-height: 104px;
  overflow-y: hidden;
  z-index: 500;
}
.holder-focused {
  background-color: #f2f2f2;
}
.recent {
  color: #939393;
  font-size: .75em;
  padding-left: 10px;
  padding-right: 10px;
}
.keyword-autocomplete {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start
}
.keywords-label {
  text-transform: uppercase;
  font-size: .75em;
  font-weight: 900;
  letter-spacing: .05em;
  padding-top: 10px;
  padding-bottom: 10px;
}
.input-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 299;
}
.view-all {
  cursor: pointer;
  color: #626262;
}
.view-all:hover {
  text-decoration: underline;
}

.search-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

</style>