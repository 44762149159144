<template>
  <v-overlay :scroll-strategy="'reposition'" v-model="this.settingsStore.settings_overlay" name="settings_overlay"
    no-gutters style="margin-top:15px;">
    <v-card title="SETTINGS" :subtitle="user.name"
      style="background-color:white;height:auto;width:33vw;margin-top:5vh;margin-left:60vw;margin-right:75px">
      <div class="avatar" @click="handleClose">
        <Avatar :size="40" variant="ring" :title="true" :name="'Click for Settings'" :colors="colors"
          style="vertical-align: bottom;cursor:hand;">
        </Avatar>
        <v-label style="cursor: pointer;" text="close"></v-label>
      </div>
      <v-row style="display: flex; flex-direction: row; max-height: 75px">
        <NUIButton
					:obj="this.graph"
					:displayType="'icon'"
          :isSelected="appFunction === 'units'"
				>
				</NUIButton>
        <NUIButton
					:obj="this.table"
					:displayType="'icon'"
          :isSelected="appFunction === 'password'"
				>
        </NUIButton>
      </v-row>
      <v-row style="height: 2px; background-color: lightgrey;"></v-row>
      <Units v-if="isUnits"/>
      <PassRedeem v-if="isPassRedeem" />
      <v-row>
        <v-col>
          <v-btn @click="this.logout" variant="outlined" class="settingsBtn" :style="this.handleLogoutStyle()">
            Logout
            <v-icon style="margin-left: 15px">logout</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore.js';
import Avatar from 'vue-boring-avatars';
import NUIButton from './nelsonUI/NUIButton.vue';
import Units from './settings/Units.vue';
import PassRedeem from './settings/PassRedeem.vue';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useProgramStore } from '@/stores/ProgramStore';
import { mapStores } from 'pinia';
import { useMCStore } from '@/stores/MCStore';
import { useAppStore } from '@/stores/AppStore';

export default {
  data () {
    return {
      colors: ['#5D5AD8', '#A1B5FF', '#5A6154', '#849D7F', '#9FD86B'],
      isUnits: true,
      isPassRedeem: false,
      appFunction: 'units',
      graph: {
        label: 'Units',
        icon: 'tune',
        value: 'units',
        orientation: 'top',
        marker: 'var(--nelson-blue)',
        func: this.toggleUnitsView,
      },
      table: {
        label: 'Password',
        icon: 'lock',
        value: 'password',
        orientation: 'top',
        marker: 'var(--nelson-green)',
        func: this.togglePassView,
      },
    };
  },
  setup () {
    const identityStore = useIdentityStore();
    identityStore.getUserProperties();
  },
  components: {
    NUIButton,
    Units,
    PassRedeem,
    Avatar
  },
  computed: {
    ...mapStores(useIdentityStore, useProgramStore, useSettingsStore, useMCStore, useAppStore),
    user () {
      return this.identityStore.User;
    },
  },
  methods: {
    handleLogoutStyle () {
      // const color = this.appFunction === 'units' ? 'var(--nelson-blue)' : 'var(--nelson-green)';
      return {
        'background-color': 'var(--nelson-blue)',
        'width':'120px',
        'float': 'right',
      };
    },
    handleClose () {
      this.settingsStore.settings_overlay = false;
      this.appStore.isCompleted = false;
      if(!this.isUnits) {
        this.appFunction = 'units';
        this.isUnits = this.isUnits ? false : true;
        this.isPassRedeem = this.isPassRedeem ? false : true;
      }
    },
    togglePassView () {
      if(!this.isPassRedeem) {
        this.appFunction = 'password';
        this.isUnits = this.isUnits ? false : true;
        this.isPassRedeem = this.isPassRedeem ? false : true;
      }
    },
    toggleUnitsView () {
      if(!this.isUnits) {
        this.appFunction = 'units';
        this.isUnits = this.isUnits ? false : true;
        this.isPassRedeem = this.isPassRedeem ? false : true;
      }
    },
    logout () {
      this.identityStore.signout();
    },
  }
};
</script>

<style scoped>
.settingsBtn {
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: var(--nelson-blue);
  color: white;
}

.closeIcon {
  margin-right: 10px;
  margin-top: -65px;
  float: right;
  background-color: rgb(185, 4, 4);
  border-radius: 20px;
}

.closeIcon:hover {
  box-shadow: 2px 2px 4px #444343;
}
.avatar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 6px;
  padding-bottom: 6px;
  height: 100%; 
  margin-top: -70px;
  position: relative;
  max-width: 200px;
  float: right;
  padding-right: 8px;
}

.progress-circle {
  padding-left: 5px;
}</style>
