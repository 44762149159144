import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import * as d3 from 'd3';
import { formatPrograms, formatValves, saveDataAsCSV } from '../library/functions';
import dayjs from 'dayjs';
import { useMCStore } from '@/stores/MCStore';
import { useModalStore } from '@/stores/ModalStore';
import { useFunctionStore } from '@/stores/FunctionStore';
import { useRMTStore } from '@/stores/RMTStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import * as Calculate from '@/library/calculations.js';
import { useAppStore } from './AppStore';
import * as UnitConversion from '@/services/UnitConversionService.js';

export const useProgramStore = defineStore('program', {
	state () {
		return {
			currentEventData: [],
			isSingleValve: false,
			multiSelectValue: null,
			dateRange: null,
			startDate: null,
			endDate: null,
			data: [],
			isByProgram: true,
			valveCount: null,
			isTypeProgram: true,
			drawBlank: true,
			isHomeGraph: true,
			draw: false,
			showGraph: true,
			showTable: true,
			tooltip: null,
			overlay: false,
		};
	},
	getters: {
		getTooltip () {
			return this.tooptip ? this.tooltip : null;
		},
	},
	actions: {
		setTooltip (tip) {
			this.tooltip = tip;
		},
		appRateLPMH (valve) {
			if (valve.row_spacing != null
				&& valve.emitter_spacing != null
				&& valve.emitter_flow_lpm != null
				&& valve.emitter_flow_lpm != 0) {
				return Calculate.appRateLPMHfromEmitter(valve.emitter_flow_lpm, valve.emitter_spacing, valve.row_spacing);
			} else if (valve.area_m2 != null && valve.flow_lpm != null) {
				return Calculate.appRateLPMH(valve.area_m2, valve.flow_lpm);
			} else {
				return 0;
			}
		},
		groupByArray (xs, key) {
			return xs.reduce(function (rv, x) {
				let v = key instanceof Function ? key(x) : x[key];
				let el = rv.find((r) => r && r.key === v);
				if (el) {
					el.values.push(x);
				}
				else {
					rv.push({ key: v, values: [x] });
				}
				return rv;
			}, []);
		},
		formatDataForGraph () {
			const mcStore = useMCStore();
			if (!mcStore.AllValveEventData) { return []; }
			this.isSingleValve = mcStore.AllValveEventData.length === 1 ? true : false;
			this.valveCount = this.isSingleValve ? 1 : mcStore.AllValveEventData.length;
			let graphItems = mcStore.AllValveEventData.map((item) => {
				if (this.isSingleValve) {
					// we need to pull the time stamp off the dates so we can sort by the day
					var eventList = item.open_events.map(function (oe) {
						oe.date = dayjs(new Date(oe.date + '.000Z')).format('MMM D YYYY');
						return oe;
					});
					// groupByArray takes in an array and a property to group by. 
					// It returns an array of objects which have a values array.
					var uniqueDates = this.groupByArray(eventList, 'date');
					let lastDate = dayjs(this.startDate);

					uniqueDates.forEach((date, index) => {
						const valveValues = date.values.map((element) => {
							let dateData;
							if (element.isVerified === true) {
								dateData = {verifiedHour: element.seconds, hoursRan: 0};
							} else {
								dateData = {verifiedHour: 0, hoursRan: element.seconds};
							}
							return dateData;
						});
						const initialValue = 0;
						const sumHoursRan = valveValues.reduce((accumulator, currentValue) => accumulator + currentValue.hoursRan,
							initialValue
						);
						const sumVerifiedHour = valveValues.reduce((accumulator, currentValue) => accumulator + currentValue.verifiedHour,
							initialValue
						);
						date.sortKey = dayjs(date.key).format('YYYY-MM-DD');
						date.values = {
							programColor:'#808080', 
							group: date.key, 
							area: item.area_m2 ? item.area_m2 : 0,
							flow: item.flow_lpm ? item.flow_lpm : 0,
							apprate: this.appRateLPMH(item),
							hoursRan: sumHoursRan / 60 / 60,
							verifiedHour: sumVerifiedHour / 60 /60,
							precipitation: 0,// calculate precipitation
							verifiedPrecipitation: 0// calculate precipitation
						};

						let currDate = dayjs(date.key);
						var daysToInsert = currDate.diff(lastDate, 'hours') / 24;
						for (let i = 0; i < daysToInsert; i++) {
							const formatDate = lastDate.format('MMM D YYYY');
							const previousIndex = index === 0 ? index : index - 1;
							if (uniqueDates[previousIndex].key != formatDate) {
								uniqueDates.push({
									key: lastDate.format('MMM D YYYY'),
									sortKey: lastDate.format('YYYY-MM-DD'),
									values: {
										programColor: '#808080',
										group: lastDate.format('MMM D YYYY'),
										area: item.area_m2 ? item.area_m2 : 0,
										flow: item.flow_lpm ? item.flow_lpm : 0,
										apprate: this.appRateLPMH(item),
										hoursRan: 0,
										verifiedHour: 0,
										precipitation: 0,// calculate precipitation
										verifiedPrecipitation: 0// calculate precipitation
									}
								});
							}
							lastDate = lastDate.add(1, 'day');
						}
					});

					var daysLeftToEnd = dayjs(this.endDate).diff(lastDate, 'hours') / 24;
					if (daysLeftToEnd > 0) {
						for (var i = 0; i < daysLeftToEnd; i++) {
							uniqueDates.push({
								key: lastDate.add(1, 'day').format('MMM D YYYY'), 
								sortKey: lastDate.format('YYYY-MM-DD'),
								values: {
									programColor: '#808080',
									group: lastDate.add(1, 'day').format('MMM D YYYY'),
									area: item.area_m2 ? item.area_m2 : 0,
									flow: item.flow_lpm ? item.flow_lpm : 0,
									apprate: this.appRateLPMH(item),
									hoursRan: 0,
									verifiedHour: 0,
									precipitation: 0,// calculate precipitation
									verifiedPrecipitation: 0// calculate precipitation
								}
							});
							lastDate = lastDate.add(1, 'day');
						}
					}
					uniqueDates.sort(function (a, b) {
						return a.sortKey.localeCompare(b.sortKey);
					});
					const events = uniqueDates.map((element) => {
						element.values.precipitation = element.values.apprate * element.values.hoursRan * 60; // Liters per hectare
						element.values.verifiedPrecipitation = element.values.apprate * element.values.verifiedHour * 60; // Liters per hectare
						return element.values;
					});
					events.forEach((element, index) => {
						element.index = index;
					});
					return events;
				}
				else {
					let graphItem = {
						index: item.index,
						program: item.program ? item.program.plan_name : null,
						programId: item.program ? item.program.plan_id : '',
						programColor: item.program ? item.program.color : '#808080',
						group: item.valve_name ? item.valve_name : JSON.stringify(item.valve_id),
						valveId: item.valve_id,
						area: item.area_m2 ? item.area_m2 : 0,
						flow: item.flow_lpm ? item.flow_lpm : 0,
						apprate: this.appRateLPMH(item),
						hoursRan: (item.open_events.reduce(function (acc, oe) {
							if (!oe.isVerified) {
								return acc + oe.seconds;
							} else {
								return acc;
							}
						}, 0)) / 60 / 60,
						verifiedHour: (item.open_events.reduce(function (acc, oe) {
							if (oe.isVerified) {
								return acc + oe.seconds;
							} else {
								return acc;
							}
						}, 0)) / 60 / 60,
						precipitation: 0,// calculate precipitation
						verifiedPrecipitation: 0// calculate precipitation
					};
					graphItem.precipitation = graphItem.apprate * graphItem.hoursRan * 60; // Liters per hectare
					graphItem.verifiedPrecipitation = graphItem.apprate * graphItem.verifiedHour * 60; // Liters per hectare
					return graphItem;
				}
			});
			if (this.isByProgram && graphItems[0].program != null) {
				return graphItems.sort((a, b) => a.program.localeCompare(b.program));
			} else {
				const items = this.isSingleValve ? graphItems[0] : graphItems;
				return items;
			}
		},
		formatVal () {
			return formatValves(this.twigMcValveEvents.valves);
		},
		formatProgram () {
			return formatPrograms(this.twigMcPlans.plans);
		},
		groupBy (xs, key) {// array, sortby
			return xs.reduce(function (rv, x) {
				(rv[x[key]] = rv[x[key]] || []).push(x);
				return rv;
			}, {});
		},
		toggleByProgram () {
			// event.preventDefault();
			this.isByProgram = !this.isByProgram;
		},
		toggleTypeProgram () {
			// event.preventDefault();
			this.isTypeProgram = !this.isTypeProgram;
		},
		programValves (event) {
			event.preventDefault();
			this.isTypeProgram = true;
		},
		singleValve (event) {
			event.preventDefault();
			this.isTypeProgram = false;
			this.isSingleValve = true;
		},
		queryValves (startDate, endDate, data) {
			const mcStore = useMCStore();
			const appStore = useAppStore();
			const modalStore = useModalStore();
			this.draw = false;
			// Checking what data gets sent in
			ApiService.getValvesDateRange(
				mcStore.getCurrentMC.uuid,
				data,
				startDate,
				endDate
			)
				.then((response) => {
					let events;
					response.data.valves.forEach((item) => {
						if (item.open_events.length > 0) {
							events = true;
						}
					});
					if (events) {
						let formattedData;
						if (!this.isByProgram) {
							response.data.valves.forEach((item, index) => {
								item.index = index;
							});
							formattedData = response.data.valves;
						} else {
							const revisedData = [];
							const revisedPrograms = [];
							response.data.valves.forEach((el, index) => {
								const programs = mcStore.getProgramFromValveID(el.valve_id);
								if (programs.length === 1) {
									revisedData.push(response.data.valves[index]);	
									response.data.valves[index].program = programs[0];
									revisedPrograms.push({program: programs[0]});								
								} else {
									for (let i = 0; i < programs.length; i++) {
										let multiProg = response.data.valves[index];
										multiProg.index = i;
										revisedData.push(multiProg);
										revisedPrograms.push({program: programs[i]});
									}
								}
							});
							// deals with the possiblity of multiple programs having the same valve in it.
							for (let i = 0; i < revisedData.length; i++) {
								revisedPrograms[i].index = i;
								revisedPrograms[i].apprate = revisedData[i].apprate;
								revisedPrograms[i].row_spacing = revisedData[i].row_spacing;
								revisedPrograms[i].emitter_spacing = revisedData[i].emitter_spacing;
								revisedPrograms[i].area_m2 = revisedData[i].area_m2;
								revisedPrograms[i].emitter_flow_lpm = revisedData[i].emitter_flow_lpm;
								revisedPrograms[i].flow_lpm = revisedData[i].flow_lpm;
								revisedPrograms[i].open_events = revisedData[i].open_events;
								revisedPrograms[i].serial = revisedData[i].serial;
								revisedPrograms[i].valve_id = revisedData[i].valve_id;
								revisedPrograms[i].valve_name = revisedData[i].valve_name;
								revisedPrograms[i].valve_pk = revisedData[i].valve_pk;
							}
							formattedData = revisedPrograms;
						}
						// save event data for use by all components
						mcStore.setEventData(formattedData);
						this.currentEventData = this.formatDataForGraph();
						d3.select('.graphSVG').selectAll('svg > *').remove();
						appStore.isLoading = false;
					} else {
						appStore.isLoading = false;
						modalStore.setModalObj({
							promptLabel: 'Data',
							promptDescription: 'No data within specified date range.',
							buttonALabel: 'Ok',
							buttonAFunc: modalStore.hideModal,
							buttonBFunc: null
						});
						modalStore.showModal('prompt');
					}
					//		this.draw = true; //this triggers the graph draw.
				})
				.catch((err) => {
					console.log(err);
				});
		},
		generateHandler () {
			var self = this;
			const functionStore = useFunctionStore();
			const appStore = useAppStore();
			appStore.isLoading = true;
			this.drawBlank = false;
			// // To push them out of data view if there going to generate in data view
			// if (functionStore.appFunction === 'data') {
			// 	functionStore.setAppFunction('reporting');
			// 	this.$router.push('/hybrid');
			// }
			// clears the graph before if there was data in it.
			// !! NEED TO BE SURE WE ARE ONLY SELECTING THE GRAPH'S SVGs
			d3.select('.graphSVG').selectAll('svg > *').remove();
			let p = new Promise(function (success) {
				var startDate = functionStore.range.start.toJSON();
				var endDate = functionStore.range.end.toJSON();
				self.startDate = startDate;
				self.endDate = endDate;
				const RMTStore = useRMTStore();
				let selectValues;
				if (self.isByProgram === false) {
					let singleValves = [];
					RMTStore.selectedValves.forEach((valve) => {
						singleValves.push(valve.valve_id);
					});
					selectValues = singleValves;
				} else {
					let valves = [];
					RMTStore.selectedPlans.forEach((planItems) => {
						let planValves = planItems.valves.map(function (plan) {
							return plan.valve_id;
						});
						planValves.sort((a, b) => (a.programName > b.programName) ? 1 : -1);
						valves.push(...planValves);
					});
					selectValues = valves;
				}
				self.queryValves(startDate, endDate, selectValues);
				success;
			});
			return p;
		},
		tableSubHeaders () {
			const settingsStore = useSettingsStore();
			return [
				'',
				settingsStore.areaUnit,
				settingsStore.flowUnit,
				settingsStore.rateUnit,
				'hours',
				(settingsStore.lengthUnit == 'ft') ? 'in' : 'mm',
				'hours',
				(settingsStore.lengthUnit == 'ft') ? 'in' : 'mm'
			];
		},
		tableHeaders () {
			const mcStore = useMCStore();
			return [
				{
					title: mcStore.currentEventData.length === 1 ? 'Date' : 'Valve Name',
					align: 'start',
					sortable: true,
					key: 'valve_name',
				},
				{ title: 'Irrigated Area', align: 'end', key: 'area_m2' },
				{ title: 'Flow Rate', align: 'end', key: 'flow_lpm' },
				{ title: 'Application', align: 'end', key: 'apprate' },
				{ title: 'Runtime', align: 'end', key: 'runtime' },
				{ title: 'Applied Depth', align: 'end', key: 'applied' },
				{ title: 'Verified Runtime', align: 'end', key: 'verified_hours' },
				{ title: 'Verified Applied Depth', align: 'end', key: 'verified_application' },
			];
		},
		filterDepth (depth) {
			const settingsStore = useSettingsStore();
			const lengthUnit = settingsStore.lengthUnit;
			const convertedDepth = UnitConversion.FilterLength(depth / 10000 / 1000, settingsStore.lengthUnit);
			if (lengthUnit == 'ft') { return (convertedDepth * 12).toFixed(2); }
			if (lengthUnit == 'm') { return (convertedDepth * 1000).toFixed(2); }
			return depth;
    },
		downloadCSV () {
			const settingsStore = useSettingsStore();
			let typeHeader = this.isSingleValve === true ? 'Date' : 'Valve Name';
			var headers = [typeHeader, 'Irrigated Area', 'Flow Rate', 'Application', 'Runtime', 'Applied Depth', 'Verified Runtime', 'Verified Applied Depth'];
			var subheaders = this.tableSubHeaders();
			const csvData = [
				headers,
				subheaders,
				...this.currentEventData.map(item => [
					item.group,
					(UnitConversion.FilterArea(item.area, settingsStore.areaUnit)).toFixed(2),
					(UnitConversion.FilterFlow(item.flow, settingsStore.flowUnit)).toFixed(2),
					(UnitConversion.FilterRate(item.apprate, settingsStore.rateUnit)).toFixed(2),
					item.hoursRan.toFixed(2),
					this.filterDepth(item.precipitation),
					item.verifiedHour.toFixed(2),
					this.filterDepth(item.verifiedPrecipitation)])
			];
			saveDataAsCSV(csvData);
		}
	},
});