<template>
  <div ref="keywordOption" class="keyword-option-holder" @mouseleave="hideColorOverlay">
    <div class="color-marker" :style="getColor">
      <ColorSelector v-if="showColor"></ColorSelector>
    </div>
    <div ref="option" class="keyword-option" >{{getName}}</div>
    <div class="remove-btn" v-show="removeable" @click.stop=removeItem>
      <div class="circle-x">
        <img :src="require('@/assets/svg/SVG/x.svg')" alt=Bookmark/>
      </div>
    </div>
  </div>
</template>

<script>
import { useRMTStore } from '@/stores/RMTStore';
import { gsap } from 'gsap';
import ColorSelector from '@/components/nelsonUI/ColorSelector';
import { useProgramStore } from '@/stores/ProgramStore';

export default {
  name: 'KeywordOption',
  components: { ColorSelector },
  data () {
    return {
      showColor: false
    };
  },
  setup () {
    const RMTStore = useRMTStore();
    const programStore = useProgramStore();
    return {
      RMTStore, programStore
    };
  },
  props: [
    'option',
    'index',
    'removeable',
    'func'
  ],
  mounted () {
    if (!this.$props.removeable) { return; }
    gsap.killTweensOf(this.$refs.keywordOption);
    if (this.$props.option.new) {
      gsap.from(this.$refs.keywordOption, {
        width: 0,
        duration: 0,// .6,
        ease: 'power3.out'
      });
      this.$props.option.new = false;
    }
  },
  updated () {
    if (this.$props.option.new) {
      gsap.fromTo(this.$refs.keywordOption, {
        width: 0,
        ease: 'power3.out'
      },{
        width: 'auto',
        duration: 0,// .6,
      });
      this.$props.option.new = false;
    } else {
      gsap.set(this.$refs.keywordOption, {
        width: 'auto'
      });
    }
  },
  computed: {
    getColor () {
      // return this.option.colors[this.$props.index]
      return 'background-color:' + this.$props.option.color;
    },
    getName () {
      const programName = this.$props.option.plan_name;
      const valveName = this.$props.option.valve_name;
      let n = '';
      n = (this.programStore.isByProgram) ? programName : valveName;
      return n;
    }
  },
  methods: {
    textColor: function (color) {
      if (color === undefined) { return; }
      // Get background color hex value. '#' is optional.
      let hex = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
      // Convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      // By this point, it should be 6 characters
      if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
      }
      let r = parseInt(hex.slice(0, 2), 16),
          g = parseInt(hex.slice(2, 4), 16),
          b = parseInt(hex.slice(4, 6), 16);
      // Return light or dark class based on contrast calculation
      return ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ? '#000000' : '#ffffff';
    },
    hideColorOverlay () {
      this.showColor = false;
    },
    removeItem () {
      this.$props.func(this.$props.option);
    },
  }
};
</script>

<style scoped>
.keyword-option-holder {
  white-space: nowrap;
  cursor:pointer;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  margin: 2px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  user-select: none;
  /*align-self: flex-start;*/
  justify-content: center;
  background-color: #dddddd;
}
.keyword-option-holder:hover {
  background-color: #d2d2d2;
}
.keyword-option {
  color: black;
  padding-right: 5px;
  font-size: .78em;
}
.remove-btn {
  padding-left: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-x {
  cursor: pointer;
  font-size: .8em;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-right: 0px;
}
.color-marker {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right:8px;
  cursor: pointer;
}
</style>