<template>
<!--  <v-container fluid id="graphContainer" @generate-graph="this.drawGraph" style="padding:0">-->
	<div id="graphContainer" @generate-graph="this.drawGraph" >
		<GraphTooltip :item="tooltip" v-if="tooltip != null" />
		<div id="container" class="svg-container">
			<svg class="graphSVG">
				<rect></rect>
			</svg>
		</div>
	</div>
</template>

<script>
import {
	drawXAxis,
	drawLeftAxis,
	drawRightAxis,
	drawGrid,
	drawBottom,
	drawBars,
} from '@/library/drawing';
import GraphTooltip from './GraphTooltip.vue';
import * as UnitConversion from '@/services/UnitConversionService.js';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useProgramStore } from '@/stores/ProgramStore';
import { useMCStore } from '@/stores/MCStore';
import { useAppStore } from '@/stores/AppStore';
import { mapStores } from 'pinia';
import dayjs from 'dayjs';
import * as d3 from 'd3';

export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
		height: {
			type: String,
			required: true,
		},
		isByProgram: {
			type: Boolean,
			required: true,
		},
		drawBlank: {
			type: Boolean,
		},
	},
	data () {
		// tooltip to be displayed
		return {
			valveCount: 0,
		};
	},
	watch: {
		items: {
			deep: true,
			handler: function (newVal) {
				if (newVal.length > 0) {
					this.programStore.draw = true;
				}
			},
		},
		draw: {
			deep: true,
			handler: function (newVal) {
				if (newVal === true) {
					this.drawGraph();
				}
			},
		},
	},
	components: {
		GraphTooltip,
	},
	computed: {
		...mapStores(useMCStore, useAppStore, useProgramStore, useSettingsStore),
		tooltip	() {
			if (this.programStore.tooltip) {
				return this.programStore.tooltip;
			} else {
				return null;
			}
		},
		draw	() {
			return this.programStore.draw;
		},
	},
	methods: {
		isSingleValveQuery (item) {
			return this.programStore.isSingleValve ? item.index : item.valveId;
    },
		scrollToElement	(item) {
			const element = document.getElementById('row' + this.isSingleValveQuery(item));
			if (element) {
				element.scrollIntoView({behavior: 'auto', block: 'center', inline: 'nearest'});
			}
		},
		// mouseover, mousemove, and mouseleave deal with tool tip of the graph.
		mouseover	(select, values) {
			this.scrollToElement(values);
			// "#5D5AD8", "#A1B5FF", "#5A6154", "#849D7F"
			this.programStore.setTooltip(values);
			let Tooltip = d3.select('#graphTip');
			Tooltip.style('width', '150px')
				.style('height', '230px')
				.style('left', select.x + 20 + 'px')
				.style('top', select.y - 300 + 'px');
			Tooltip.style('opacity', 1);
			const hoursRanBlur = d3.selectAll('.hoursRan').attr('opacity');
			const verifiedHourBlur = d3.selectAll('.verifiedHour').attr('opacity');
			const precipitationBlur = d3.selectAll('.precipitation').attr('opacity');
			const verifiedPrecipitationBlur = d3.selectAll('.verifiedPrecipitation').attr('opacity');
			let isHoursRan = hoursRanBlur == 0 ? true : false;
			let isVerifiedHour = verifiedHourBlur == 0 ? true : false;
			let isPrecipitaion = precipitationBlur == 0 ? true : false;
			let isVerifiedPrecipitation = verifiedPrecipitationBlur == 0 ? true : false;
			if (isHoursRan === false) {
				d3.selectAll('.hoursRan').transition().duration(500).attr('opacity', 0.4);
			}
			if (isVerifiedHour === false) {
				d3.selectAll('.verifiedHour').transition().duration(500).attr('opacity', 0.4);
			}
			if (isPrecipitaion === false) {
				d3.selectAll('.precipitation').transition().duration(500).attr('opacity', 0.4);
			}
			if (isVerifiedPrecipitation === false) {
				d3.selectAll('.verifiedPrecipitation').transition().duration(500).attr('opacity', 0.4);
			}
			// current selection bars
			var self = select.currentTarget;
			self = d3.select(select.currentTarget);
			if (isHoursRan === false) {
				self.selectAll('.hoursRan').transition().duration(500).attr('opacity', 1);
			}
			if (isVerifiedHour === false) {
				self.selectAll('.verifiedHour').transition().duration(500).attr('opacity', 1);
			}
			if (isPrecipitaion === false) {
				self.selectAll('.precipitation').transition().duration(500).attr('opacity', 1);
			}
			if (isVerifiedPrecipitation === false) {
				self.selectAll('.verifiedPrecipitation').transition().duration(500).attr('opacity', 1);
			}
		},
		mousemove	(d) {
			let Tooltip = d3.select('#graphTip');
			Tooltip.style('width', '150px')
				.style('height', '230px')
				.style('left', d.x + 20 + 'px')
				.style('top', d.y - 300 + 'px');
		},
		mouseleave () {
			let Tooltip = d3.select('#graphTip');
			Tooltip.style('height', '0px');
			this.programStore.setTooltip(null);
			const hoursRanBlur = d3.selectAll('.hoursRan').attr('opacity');
			const verifiedHourBlur = d3.selectAll('.verifiedHour').attr('opacity');
			const precipitationBlur = d3.selectAll('.precipitation').attr('opacity');
			const verifiedPrecipitationBlur = d3.selectAll('.verifiedPrecipitation').attr('opacity');
			let isHoursRan = hoursRanBlur == 0 ? true : false;
			let isVerifiedHour = verifiedHourBlur == 0 ? true : false;
			let isPrecipitaion = precipitationBlur == 0 ? true : false;
			let isVerifiedPrecipitation = verifiedPrecipitationBlur == 0 ? true : false;
			if (isHoursRan === false) {
				d3.selectAll('.hoursRan').transition().duration(250).attr('opacity', 1);
			}
			if (isVerifiedHour === false) {
				d3.selectAll('.verifiedHour').transition().duration(250).attr('opacity', 1);
			}
			if (isPrecipitaion === false) {
				d3.selectAll('.precipitation').transition().duration(250).attr('opacity', 1);
			}
			if (isVerifiedPrecipitation === false) {
				d3.selectAll('.verifiedPrecipitation').transition().duration(250).attr('opacity', 1);
			}
		},
		// Finds the maximums of the data to deteremine y and x axis
		getMaximums	(valveInfo) {
			let maxNumRan = [];
			let maxNumVerified = [];
			let maxNumInches = [];
			let maxNumVerifiedPrecipitation = [];
			valveInfo.forEach((element) => {
				// console.log(element.group + ':' + ' ' + element.verifiedHour);
					maxNumRan.push(JSON.parse(element.hoursRan));
					maxNumVerified.push(JSON.parse(element.verifiedHour));
					maxNumInches.push(JSON.parse(element.precipitation));
					maxNumVerifiedPrecipitation.push(
						JSON.parse(element.verifiedPrecipitation)
					);
			});
			const maxRan = Math.max(...maxNumRan);
			const maxVRan = Math.max(...maxNumVerified);
			const maxPrecip = Math.max(...maxNumInches);
			const maxVPrecip = Math.max(...maxNumVerifiedPrecipitation);

			let maxTime = null;
			let maxApplied = null;

			maxTime = maxRan > maxVRan ? maxRan + 1 : maxVRan + 1;
			maxApplied = maxPrecip > maxVPrecip ? maxPrecip + 1 : maxVPrecip + 1;

			// Makes equal amount of ticks possible of the both y axis. To get the grid to line up.
			maxApplied = Math.floor(maxApplied);
			maxTime = Math.floor(maxTime);

			maxApplied = maxApplied / 9;
			maxTime = maxTime / 9;

			let maxAppliedValues = [];
			let maxTimeValues = [];
			for (let i = 0; i < 10; i++) {
				const timePoint = maxTime * i;
				const appliedPoint = maxApplied * i;
				maxTimeValues.push(timePoint.toFixed(2));
				maxAppliedValues.push(appliedPoint.toFixed(2));
			}
			return [maxTimeValues, maxAppliedValues];
		},
		getCounts	(valveInfo) {
			// sort the valve data based on what they have selected.
			const valveData = valveInfo;
			// total count of valves in the query
			let dataCount = valveData.length;
			// This finds the count of valves in each program
			let counts = valveData.reduce((p, c) => {
				let name = c.program;
				var hasProp = Object.prototype.hasOwnProperty.call(p, name);
				if (!hasProp) {
					p[name] = 0;
				}
				p[name]++;
				return p;
			}, {});
			// this creates an array of having the total count in each program to be halfed
			let countsExtended = Object.keys(counts).map((k) => {
				return { name: k, count: Math.round(counts[k] / 2) };
			});
			// this is the array with total count of valves for each program queried
			let totalExtended = Object.keys(counts).map((k) => {
				return { name: k, count: counts[k] };
			});
			// don't do this if it is a single valve
			if (!this.programStore.isSingleValve) {
				totalExtended.sort(function (a, b) {
					return a.name.localeCompare(b.name);
				});
			}
			return [dataCount, countsExtended, totalExtended];
		},
		filterFlow (flow) {
			if (flow) {
				return UnitConversion.FilterFlow(
					flow,
					this.settingsStore.flowUnit
				).toFixed(2);
			}
			else { return 0.0; }
		},
		filterRate	(rate) {
			return UnitConversion.FilterRate(
				rate,
				this.settingsStore.rateUnit
			).toFixed(2);
		},
		filterArea	(area) {
			return UnitConversion.FilterArea(
				area,
				this.settingsStore.areaUnit
			).toFixed(2);
		},
		filterDepth	(depth) {
			const lengthUnit = this.settingsStore.lengthUnit;
			const convertedDepth = UnitConversion.FilterLength(
				depth / 10000 / 1000,
				this.settingsStore.lengthUnit
			);
			if (lengthUnit == 'ft') { return (convertedDepth * 12).toFixed(2); }
			if (lengthUnit == 'm') { return (convertedDepth * 1000).toFixed(2); }
			return depth;
		},
		// drawGraph function does the drawing of the graph
		drawGraph	() {
			// Gets the date querid and creates an array to put them in.
			let valveInfo;
			let subgroups;
			let num;
			if (
				this.items === null ||
				this.items === undefined ||
				this.items.length === 0 ||
				this.drawBlank == true
			) { return; }
			if (this.isByProgram === false) {
				valveInfo = this.items;
			} else {
				valveInfo = this.items;
			}
			num = valveInfo[0];
			// change this to (6, 10) if live data else dummyData (1)
			subgroups = Object.keys(num).slice(6, 10);
			this.valveCount = this.programStore.isSingleValve ? 1 : this.items.length;
			// subgroups = Object.keys(num).slice(1);
			subgroups = [
				'verifiedHour',
				'hoursRan',
				'verifiedPrecipitation',
				'precipitation',
			];
			let groupIndex = 1;
			const valveConversions = valveInfo.map((item) => {
				const area = this.filterArea(item.area);
				const flow = this.filterFlow(item.flow);
				const apprate = this.filterArea(item.area);
				const precipitation = this.filterDepth(item.precipitation);
				const verifiedPrecipitation = this.filterDepth(
					item.verifiedPrecipitation
				);
				groupIndex + 1;
				return {
					index: item.index,
					program: item.program,
					programColor: item.programColor,
					group: item.group,
					valveId: item.valveId,
					area: area,
					flow: flow,
					apprate: apprate,
					hoursRan: item.hoursRan,
					verifiedHour: item.verifiedHour,
					precipitation: precipitation,
					verifiedPrecipitation: verifiedPrecipitation,
				};
			});
			valveInfo = valveConversions;
			this.valveCount = this.programStore.isSingleValve ? 1 : valveInfo.length;
			if (this.valveCount === 1) {
				valveInfo.forEach((element) => {
					let date = dayjs(element.group);
					let dateLabel = dayjs(date).format('MMMM-YYYY');
					let groupLabel = dayjs(date).format('MMM-D');
					element.program = dateLabel;
					element.group = groupLabel;
				});
			}
			var maximums = [];
			// gets maxmiums of the two main data parts
			maximums = this.getMaximums(valveInfo);
			const maxTimeValues = maximums[0];
			const maxAppliedValues = maximums[1];
			console.log(maxTimeValues);
			console.log(maxAppliedValues);
			// gets certain counts of the data
			const count = this.getCounts(valveInfo);
			const dataCount = valveInfo.length;
			const countsExtended = count[1];
			const totalExtended = count[2];
			const allGroup = valveInfo.map((d) => d.index);
			// sets the width of the graph based on a certain amount of valves created.
			let widthValue;
			if (dataCount <= 35) {
				widthValue = document.body.clientWidth - 20; // have to include new 2.5% margins
				d3.select('#container').style('overflow-x', 'hidden');
				d3.select('.graphSVG').style('width', '100%');
				d3.select('.graphSVG').style('height', 'auto');
			} else {
				widthValue = (dataCount / 12) * 800 + document.body.clientWidth;
				d3.select('.graphSVG').style('width', 'auto');
				d3.select('.graphSVG').style('height', '100%');
				d3.select('#container').style('overflow-x', 'scroll');
			}
			// d3.select("#container").style("border", "1px solid cyan");
			// set the dimensions and margins of the graph
			// let margin = { top: 30, right: 30, bottom: 30, left: 30 },
			// width = widthValue - margin.left - margin.right,
			let height = ((parseInt(this.$props.height) * 0.01) * document.body.clientHeight) - 32;
			// creates the svg in graphSVG Id
			let outer = d3
				.select('.graphSVG')
				.attr('preserveAspectRatio', 'xMinYMin meet')
				.attr('width', widthValue)
				.attr('height', height)
				.attr('viewBox', '0 0 ' + (widthValue + 50).toString() + ' ' + height)
				.classed('svg-content', true);
			// Appends the
			let svg = outer
				.append('g')
				// .classed("g", true)
				.attr('transform', 'translate(53, 25)');
			// height = 100;
			if (
				this.items === null ||
				this.items.length === 0 ||
				this.drawBlank == true
			) {
				// Draws x axis
				let x = drawXAxis(svg, widthValue, height, allGroup);
				// Draws the left axis
				drawLeftAxis(svg, height, maxTimeValues);
				// Draws the right axis
				drawRightAxis(svg, height, widthValue, maxAppliedValues);
				// let y1 = drawRightAxis(svg, height, width, maxAppliedValues);
				// Draws the grid of the graph
				drawGrid(svg, x, height, widthValue, valveInfo);
			} else {
				// Draws x axis
				let x = drawXAxis(svg, widthValue - 60, height - 150, allGroup);
				// Draws bottom of graph
				drawBottom(
					svg,
					dataCount,
					valveInfo,
					countsExtended,
					totalExtended,
					this.isByProgram,
					this.valveCount,
					x
					// this.generateSingleValve
				);
				// Draws the left axis
				drawLeftAxis(svg, height - 150, maxTimeValues);
				// Draws the right axis
				drawRightAxis(svg, height - 150, widthValue - 60, maxAppliedValues);
				// Draws the grid of the graph
				drawGrid(svg, x, height - 150, widthValue - 60, valveInfo);
				// Draw Bars function
				drawBars(
					svg,
					valveInfo,
					subgroups,
					height - 150,
					this.mouseover,
					this.mousemove,
					this.mouseleave,
					x,
					// y0,
					// y1,
					maxTimeValues,
					maxAppliedValues
				);
			}
			this.appStore.isLoading = false;
			this.programStore.draw = false;
		},
	},
};
</script>

<style scoped>
.svg-content {
	overflow-x: scroll;
	vertical-align: middle;
}
::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-thumb {
	width: 8px;
	margin: 2px;
  background-color: #d0d0d0;
  border-radius: 10px;
  /* box-shadow: inset 0 0 10px 10px #d0d0d0; */
  border: solid 3px transparent;
}
.graphSVG {
	padding: 0 10px;
}

/* .svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
} */

</style>
