import * as UnitConversion from '@/services/UnitConversionService.js';

export const switchName = (item_name, item_id) => {
  if (item_name == null) {
    return item_id;
  } else {
    return item_name;
  }
};

export const appRateLPMH = function (area, flow) {
  if (area == 0) { return 0; }
  return flow / UnitConversion.ConvertM2ToHectare(area);
};

export const appRateLPMHfromEmitter = function (emitterFlow, emitterSpacing, rowSpacing) {
  if (emitterSpacing == 0 || rowSpacing == 0) { return 0; }
  return emitterFlow / UnitConversion.ConvertM2ToHectare(1 / emittersPerSquareMeter(emitterSpacing, rowSpacing));
};

export const emittersPerSquareMeter = function (emitterSpacing, rowSpacing) {
  if (emitterSpacing == 0 || rowSpacing == 0) { return 0; }
  return 1 / (emitterSpacing * rowSpacing);
};