<template>
  <div class="view-all-holder">
    <div class="view-all-header">
      {{ this.functionStore.selectType().toUpperCase() }}
    </div>
    <div ref="keywordScroll" class="keyword-scroll">
      <div class="keyword-holder">
        <KeywordOption v-for="(option, index) in getMatchingOptions" :key="index" :option="option" :removeable="false" @click.stop="addKeywordToSelection(option)"></KeywordOption>
      </div>
    </div>
  </div>
</template>

<script>
import { useRMTStore } from '@/stores/RMTStore';
import KeywordOption from '@/components/nelsonUI/KeywordOption';
import { useFunctionStore } from '@/stores/FunctionStore';
import { storeToRefs } from 'pinia/dist/pinia';
import { useModalStore } from '@/stores/ModalStore';
import { nextTick } from 'vue';

export default {
  name: 'ViewAll',
  components: {
    KeywordOption
  },
  setup () {
    const RMTStore = useRMTStore();
    const functionStore = useFunctionStore();
    const modalStore = useModalStore();
    const { isShowing } = storeToRefs(modalStore);

    return {
      RMTStore, functionStore, modalStore, isShowing
    };
  },
  watch: {
    isShowing (to) {
      if (to) {
        nextTick(() => {
          this.$refs.keywordScroll.scrollTo(0,0);
        });
      }
    }
  },
  computed: {
    getMatchingOptions () {
      return this.RMTStore.getMatching('no keyword');
      // this returns all regardless of selection status
      // return this.RMTStore.getAllOfType()
    },
  },
  methods: {
    addKeywordToSelection (option) {
      this.RMTStore.addKeywordToSelection(this.getSelectorType, option);
    },
  }
};
</script>

<style scoped>
.view-all-holder {
  display: flex;
  flex-direction: column;
  top:0;
  left:800px;
  position: absolute;
  width: 300px;
  background-color: #ffffff;
}
.keyword-holder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.view-all-header {
  height: 50px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: .80em;
  font-weight: 700;
}
.keyword-scroll {
  overflow-y: scroll;
  height: 70vh;
}
</style>