<template>
   <h4 style="padding-left:15px;padding-top:0;margin:0;">Total Hours and Verified Hours in each Valve</h4>
   <BaseGraph v-if="graphItems != null" :isByProgram="getIsByProgram" :valveCount="getValveCount" :drawBlank="getDrawBlank"
      :items="graphItems" :height="'45'" />
   <v-divider v-if="graphItems != null" :thickness="3"></v-divider>
</template>
 
<script>
import { useProgramStore } from '../stores/ProgramStore';
import { mapStores } from 'pinia';
import BaseGraph from '@/components/BaseGraph.vue';

export default {
   components: {
      BaseGraph,
      // LineFilter
   },
   computed: {
      ...mapStores(useProgramStore),
      graphItems () {
         if (this.programStore.currentEventData !== null) {
            return this.programStore.currentEventData;
         } else {
            return [];
         }
      },
      getIsByProgram () {
         if (this.programStore.isByProgram) {
            return this.programStore.isByProgram;
         } else {
            return true;
         }
      },
      getValveCount () {
         if (this.programStore.valveCount) {
            return this.programStore.valveCount;
         } else {
            return 0;
         }
      },
      getDrawBlank () {
         if (this.programStore.drawBlank) {
            return this.programStore.drawBlank;
         } else {
            return false;
         }
      },
   },
   setup () {
      const programStore = useProgramStore();
      return {
         programStore
      };
   },
   mounted () {
      // need to call to draw graph here
      this.programStore.draw = true;
      //    if (this.programStore.currentEventData != null) {
      //       this.programStore.draw = true;
      //    }
   },
};

</script>
 
<style></style>