import { defineStore } from 'pinia';
import { useProgramStore } from '@/stores/ProgramStore';
import { useModalStore } from '@/stores/ModalStore';
import { useRMTStore } from '@/stores/RMTStore';

export const useFunctionStore = defineStore('function', {
    state () {
        return {
            range: {
                start: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() - 14),// 14 days prior
                end: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate())// todays date
            },
            // "reporting" "data"
            appFunction: 'reporting',
            // "program" "single"
            programMode: 'program',
            programType: 'program',
            selectorMode: 'program',
            // colors
            nelsonBlue: '#0088ca',
            nelsonGreen: '#00bd5e',
            // NUILoading
            dataLoading: false
        };
    },
    getters: {
        getProgramMode () {
            return this.programMode;
        },
        getProgramType () {
            return this.programType;
        }
    },
    actions: {
        getDateInPast (previousDays) {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth(), now.getDate() - previousDays);
        },
        getDate () {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth(), now.getDate());
        },
        setAppFunction (appF) {
            this.appFunction = appF;
        },
        setProgramMode (mode) {
            this.programMode = mode;
            if (mode === 'program') {
                this.selectorMode = 'plans';
            }
            if (mode === 'single') {
                this.selectorMode = 'valves';
            }
        },
        setProgramType (type) {
            this.programType = type;
            // todo: adding in the ProgramStore isByProgram toggle -- ultimately
            // todo: we should only have one variable to hold this state
            // todo: are there other variables being set?
            const programStore = useProgramStore();
            if (type === 'program') {
                this.selectorMode = 'program';
                programStore.isByProgram = true;
            }
            if (type === 'valve') {
                this.selectorMode = 'valves';
                programStore.isByProgram = false;
            }
        },
        selectType () {
            return (this.selectorMode === 'program') ? 'Programs' : 'Valves';
        },
        removeAllPrompt () {
            const modalStore = useModalStore();
            modalStore.setModalObj(
                {
                    promptLabel: 'Remove All',
                    promptDescription: 'Are you sure you want to remove all items from your selection?',
                    buttonALabel: 'CONFIRM',
                    buttonAFunc: this.removeAll,
                    buttonBLabel: 'Cancel',
                    buttonBFunc: this.cancelPrompt
                });
            modalStore.showModal('prompt');
            // modalStore.toggleModal("prompt")
        },
        removeAll () {
            const RMTStore = useRMTStore();
            const modalStore = useModalStore();
            RMTStore.removeAllOfType();
            // this.$refs.keywordSelector.resetSelector()
            modalStore.hideModal();
        },
        cancelPrompt () {
            const modalStore = useModalStore();
            modalStore.hideModal();
        },
    }
});