<template>
  <div class="color-holder">
    <ColorOption
      v-for="(color, index) in this.RMTStore.colors"
      :key="index"
      :index="index"
      :color="color"
    />
    <div class="color-picker-box" @click="handleColorPicker">
      <div>+</div>
    </div>
  </div>
  <div @click="handleSelectedColor">
    <v-color-picker
      mode="hex"
      canvas-height="65"
      width="260"
      v-model="this.selectedColor"
      class="color-picker-holder"
      v-if="isColorPicker"
    />
  </div>
</template>

<script>
import { useRMTStore } from '@/stores/RMTStore';
import ColorOption from '@/components/nelsonUI/ColorOption';

export default {
  name: 'ColorSelector',
  components: {
    ColorOption
  },
  data () {
    return {
      isColorPicker: false,
      selectedColor: null,
    };
  },
  setup () {
    const RMTStore = useRMTStore();
    return {
      RMTStore
    };
  },
  computed: {
    getColors () {
      // todo: not sure why this isn't working
      return this.RMTStore.getColors();
    }
  },
  methods: {
    handleColorPicker () {
      this.isColorPicker = this.isColorPicker ? false : true;
    },
    handleSelectedColor () {
      if (this.selectedColor != '#FFFFFF') {
        this.RMTStore.colors.splice(0, 1);
        this.RMTStore.colors.push(this.selectedColor);
        const colorCount = this.RMTStore.colors.length - 1;
        this.RMTStore.setSelectedItemColor(colorCount);
      }
    }
  }
};
</script>

<style scoped>
.color-holder {
  position: absolute;
  background-color: lavenderblush;
  transform-origin: top left;
  transform: translate(5px,-25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
}
.color-picker-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: lightgray;
  border-radius: 50%;
}
.color-picker-holder {
  position: absolute;
  z-index: 10;
}
</style>