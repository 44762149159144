import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/AppStore';
import { colorFromHue } from '@/library/functions';
import { useRMTStore } from './RMTStore';
import { useModalStore } from './ModalStore';
import { useIdentityStore } from './IdentityStore';

export const useMCStore = defineStore('mc', {
  state () {
    return {
      current_mc: [],
      twig_mcs: [],
      currentEventData: null,
      isInvalidToken: false,
    };
  },
  getters: {
    MCs () {
      return this.twig_mcs;
    },
    getCurrentMC () {
      return this.current_mc;
    },
    MCdata () {
      return this.fetchMCdata();
    },
    AllValveEventData () {
      return this.currentEventData;
    },
    SingleValveEventData (valveId) {
      return this.currentEventData.find(({ valve_id }) => valve_id === valveId);
    },
  },
  actions: {
    refreshMCs () {
      const appStore = useAppStore();
      ApiService.getMCs()
      .then((mcData) => {
        this.twig_mcs = mcData.data;
        this.current_mc = this.twig_mcs[0];
        // for when new mc is redeemed;
        // const matchNewMC = this.twig_mcs.map((element) => {
        //   if (newMC === element.uuid) {
        //     return element;
        //   }
        // });
        // this.current_mc = matchNewMC;
        appStore.isRedeeming = false;
        appStore.isCompleted = true;

      }).catch((err) => {
        console.log('Error fetching mcs: ' + err);
      });
    },
    redeemMC (token) {
      const modalStore = useModalStore();
      const appStore = useAppStore();
      const payload = {
        twigNetwork: 'Production',
        voucher: token,
        clientName: null
      };
      ApiService.redeemMC(payload)
      .then((response) => {
        console.log(response);
        // const newMC = response.data.hostKey;
        this.refreshMCs();
        modalStore.hideModal();
        
      })
      .catch((err) => {
        appStore.isRedeeming = false;
        this.isInvalidToken = true;
        console.log('Error in redeeming new MC ' + err);
      });
    },
    fetchMCs () {
      const identityStore = useIdentityStore();
      const modalStore = useModalStore();
      const appStore = useAppStore();
      appStore.isLoading = true;
      ApiService.getMCs()
        .then((mcData) => {
          if (mcData.data === undefined || mcData.data.length === 0) {
            this.current_mc = [];
            this.twig_mcs = [];
          } else {
            this.twig_mcs = mcData.data;
            this.current_mc = this.twig_mcs[0];
          }
          appStore.isLoading = false;
          if (identityStore.User.properties.twig_reporting_hasAgreed != undefined) {
            if (this.twig_mcs.length === 0) {
              modalStore.setModalObj({
                redeemHeader: 'Redeem MC Invitation',
                redeemLabel: 'Voucher:',
              });
              modalStore.showModal('redeem');
            }
          }
        }).catch((err) => {
          appStore.isLoading = false;
          console.log('Error fetching mcs: ' + err);
        });
    },
    getProgramFromValveID (id) {
      const RMTStore = useRMTStore();
      // need to find the valves program by the selected programs
      // or you will get programs showing up that were not selected.
      const program = [];
      RMTStore.selectedPlans.forEach((prog) => {
        let valve = prog.valves.find(({ valve_id }) => valve_id === id);
        if (valve) {return program.push(prog);}
      });
      if (program) { return program; }
    },
    setCurrentMC (mc_uuid) {
      const newMC = this.twig_mcs.find((mc) => mc.uuid == mc_uuid);
      if (newMC === undefined) { return; }
      this.current_mc = newMC;
      this.fetchMCdata();
    },
    fetchValveData () {
      ApiService.getValves(this.current_mc.uuid)
      .then((valveData) => {
        const filterDuplicates = valveData.data.filter((value, index, self) => index === self.findIndex((t) => t.valve_id === value.valve_id));
        this.current_mc.valves = filterDuplicates;
        this.current_mc.valves.forEach((item) => {
          item.color = '#808080';
        });
      })
      .catch((err) => {
        console.log('Error fetching programs: ' + err);
      });
    },
    fetchMCdata () {
      const appStore = useAppStore();
      appStore.isLoading = true;
      if (this.current_mc === undefined) { return; }
      ApiService.getPrograms(this.current_mc.uuid)
        .then((programsData) => {
          this.current_mc.programs = programsData.data.plans;
          this.current_mc.programs.forEach((item) => {
            item.hue === null ? item.color = '#19ABBE' : item.color = colorFromHue(item.hue);
          });
          ApiService.getValves(this.current_mc.uuid)
            .then((valveData) => {
              const filterDuplicates = valveData.data.filter((value, index, self) => index === self.findIndex((t) => t.valve_id === value.valve_id));
              this.current_mc.valves = filterDuplicates;
              this.current_mc.valves.forEach((item) => {
                item.color = '#808080';
              });
              appStore.isLoading = false;
            })
            .catch((err) => {
              console.log('Error fetching programs: ' + err);
            });
        })
        .catch((err) => {
          console.log('Error fetching valves: ' + err);
        });
    },
    setEventData (eventData) {
      this.currentEventData = eventData;
    },
  }
});