<template>
  <div class="arrow_button_holder" @click="handleClick" @mouseover="handleOver" @mouseout="handleOut">
    <div ref="arrowButton" :class="getSelectedClass">
      {{ this.$props.obj.label.toUpperCase() }}
    </div>
    <div class="arrow arrow-selected" v-if="this.isSelected()">
      <div class="arrow_point">
        <div ref="arrowPoint" class="arrow_point_rot selected" :class="isHovered"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { useFunctionStore } from '@/stores/FunctionStore';
import { storeToRefs } from 'pinia/dist/pinia';
import { useProgramStore } from '@/stores/ProgramStore';

export default {
  name: 'NUIArrowButton',
  props: [
    'obj'
  ],
  data () {
    return {
      hovered : false
    };
  },
  setup () {
    const functionStore = useFunctionStore();
    const programStore = useProgramStore();
    const { programMode, programType } = storeToRefs( functionStore );
    const { isByProgram } = storeToRefs( programStore );
    return {
      functionStore, programStore, programMode, programType, isByProgram
    };
  },
  watch: {
    $route () {
      this.setBorderColor();
    }
  },
  mounted () {
    this.setBorderColor();
  },
  computed: {
    getSelectedClass () {
      let c = '';
      if (this.obj.type === 'report_mode') {
        c = (this.obj.value === this.functionStore.getProgramMode) ? 'arrow_button selected' : 'arrow_button not-selected';
      }
      if (this.obj.type === 'report_type') {
        c = (this.obj.value === this.functionStore.getProgramType) ? 'arrow_button selected' : 'arrow_button not-selected';
      }
      return c;
    },
    isHovered () {
      return (this.hovered) ?  'arrow-gray' : '';
    }
  },
  methods: {
    setBorderColor () {
      document.documentElement.style.setProperty('--nelson-color',
        (this.$route.name === 'data' ) ? 'var(--nelson-green)' : 'var(--nelson-blue)'
      );
    },
    handleOver () {
      this.hovered = true;
    },
    handleOut () {
      this.hovered = false;
    },
    handleClick () {
      if (this.obj.type === 'report_type') {
        this.programStore.isByProgram = (this.obj.value === 'program');
        this.functionStore.setProgramType(this.obj.value);
      }
    },
    isSelected () {
      if (this.obj.type === 'report_type') {
        return (this.$props.obj.value === this.programType);
      }
    },
  }
};
</script>

<style scoped>
.arrow_button_holder {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.arrow_button {
  box-shadow:inset 0 0 0 1px var(--nelson-color);
  background-color: white;
  padding: 15px 10px 14px;
  font-size: .80em;
  font-weight: 700;
  word-wrap: break-word;
  min-width: 90px;
  max-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1em;
  max-height: 50px;
  min-height: 50px;
  user-select: none;
}
.arrow_button:hover {
  background-color: #e8e8e8;
}
.arrow-gray {
  background-color: #e8e8e8 !important;
}
.selected {
  box-shadow:inset 0px 0px 0px 3px var(--nelson-color);
}
.not-selected {
  background-color: #f2f2f2;
  border-color: #738f6d;
}
.arrow {
  width: 10px;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  transform: translate(0px, 0px);
}
.arrow-selected {
  transform: translate(-3.3px, 0px);
}
.arrow_point{
  width: 16px;
  height: 16px;
  transform-origin: center center;
  transform: rotate(0deg) translate(-9px, -.5px);
}
.arrow_point_rot {
  width: 16px;
  height: 16px;
  background-color: white;
  box-shadow:inset 0px 0px 0px 3px var(--nelson-color);
  transform: rotate(45deg) translate(0px, 0px);
}
</style>