<template>
  <v-app :theme="theme">
    <v-layout v-if="user" class="app-layout">
      <ModalView></ModalView>
      <v-overlay v-model="this.isLoading">
        <div class="text-center" style="align-items: center; width: 100vw; height: 100vh">
          <v-progress-circular :size="99" :width="8" indeterminate color="var(--nelson-blue)" style="margin-top:25%">
            <span style="font-size:smaller; word-wrap: normal;">Loading<br />Data...</span>
          </v-progress-circular>
        </div>
      </v-overlay>
      <div v-if="this.user" class="function-bar-holder">
        <div class="function-container">
          <div class="function-row">
            <FunctionBar></FunctionBar>
          </div>
        </div>
      </div>
      <ViewOptions />
      <div class="router-container">
        <RouterView />
      </div>
    </v-layout>
  </v-app>
</template>
<script>

import { useAppStore } from './stores/AppStore';
import { useIdentityStore } from '@/stores/IdentityStore';
import { useMCStore } from '@/stores/MCStore';
import { useProgramStore } from '@/stores/ProgramStore';
import ViewOptions from './components/ViewOptions.vue';
import ModalView from './components/modal/ModalView.vue';
import FunctionBar from '@/components/functionBar/FunctionBar.vue';
import { ref } from 'vue';
import { mapStores } from 'pinia';

export default {
  setup () {
    const theme = ref('light');
    return {
      theme
    };
  },
  components: {
    ViewOptions,
    ModalView,
    FunctionBar,
  },
  data () {
    return {
      colors: ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'],
      size: 40,
    };
  },
  created () {
    this.identityStore.init();
  },
  computed: {
    // note we are not passing an array, just one store after the other
    // each store will be accessible as its id + 'Store'
    ...mapStores(useAppStore, useMCStore, useIdentityStore, useProgramStore),
    access_token (){
      return this.identityStore.access_token();
    },
    isLoading () {
      if (this.appStore.isLoading) {
        return this.appStore.isLoading;
      } else {
        return false;
      }
    },
    user () {
      return this.identityStore.User;
    },
    isByProgram () {
      if (this.programStore.isByProgram) {
        return this.programStore.isByProgram;
      } else {
        return true;
      }
    },
    twigMCs () {
      return this.mcStore.MCs;
    }
  },
  methods: {
    getDrawBlank () {
      if (this.programStore.drawBlank) {
        return this.programStore.drawBlank;
      } else {
        return false;
      }
    },
    changeTheme () {
      this.theme = this.theme === 'light' ? 'dark' : 'light';
    },
    toggleByProgram () {
      this.programStore.toggleByProgram();
    },
    generate () {
      this.programStore.generateHandler();
    },
    logout () {
      this.identityStore.signout();
    },
  },
  watch: {
		user: {
			deep: true,
			handler: function (newUserVal) {
        if (newUserVal.properties.twig_reporting_MC === this.mcStore.current_mc) {
          return this.mcStore.fetchMCs();
        } else if (this.mcStore.current_mc.length === 0) {
          return this.mcStore.fetchMCs();
        }
			},
		},
	},
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.v-col {
  border: 0px solid rgb(63, 18, 212);
}
.function-bar-holder {
  width: 100%;
  z-index: 0 !important;
  overflow: visible;
  height: 160px;

}

.function-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main {
  margin-top: 40px;
/*  margin-top: 190px;
  height: calc(100vh - 190px);*/
  width: 100%;
  padding: 0;
}

.function-row {
  width: 100%;
  margin: 0 !important;
  display: flex;
}

.view-options-column {
  padding: 0;
}

.app-layout {
  flex-direction: column;
}

.router-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
/*  background-color: green;*/
}

</style>