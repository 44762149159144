<template>
  <div class="dropdown" v-if="twigMCs.length > 0">
    <v-select density="comfortable" bg-color="lightgray" v-model="this.selectedTwigMC" return-object :items="twigMCs"
      :menu-props="{ closeOnClick: true, closeOnContentClick: true }">
      <template v-slot:selection="{ item }">
        <div class="twig-text">
          {{ item.value.mc }}
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div @click="this.setMC(item.value)" class="dropdown-option">
          {{ item.value.mc }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { useMCStore } from '@/stores/MCStore';
import { useIdentityStore } from '@/stores/IdentityStore';
import { useProgramStore } from '@/stores/ProgramStore';
import { useRMTStore } from '@/stores/RMTStore';
import { mapStores } from 'pinia';

export default {
  name: 'DropDown',
  data () {
    return {
      selectedTwigMC: null,
    };
  },
  mounted () {
    let getSavedMc = this.identityStore.User.properties.twig_reporting_MC;
    if (getSavedMc === null || getSavedMc === undefined) {
      this.mcStore.setCurrentMC(this.mcStore.current_mc.uuid);
    } else {
      this.mcStore.setCurrentMC(getSavedMc);
    }
    this.selectedTwigMC = this.mcStore.current_mc;
  },
  computed: {
    ...mapStores(useMCStore, useProgramStore, useIdentityStore, useRMTStore),
    twigMCs () {
      return this.mcStore.MCs;
    }
  },
  methods: {
    setMC (item) {
      // clears search on change of mc
      this.RMTStore.keywordSearchText = '';
      this.RMTStore.setTag('tags', this.RMTStore.keywordSearchText);
      this.RMTStore.removeAll();
      this.restoreMC(item);
      this.selectedTwigMC = item;
      this.mcStore.setCurrentMC(item.uuid);
    },
    restoreMC (item) {
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ MC: item.uuid });
		},
  }
};
</script>

<style scoped>
.dropdown {
  height: 50px;
  border: 0.25px solid lightgray;
}
.twig-text {
  white-space: nowrap;
  overflow: hidden;
  margin-left: 15px;
}

.dropdown-option {
  padding: 7px;
  transition: .1s background-color;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: whitesmoke;
}

</style>

<style>
 .v-input__control {
    height: 50px;
  }
 div .v-field__input {
   min-height: 50px;
   height: 50px !important;
   padding: 0 !important;
   display: flex;
   align-items: center;
   justify-content: flex-start;
 }
 .v-field__append-inner {
   padding: 0 !important;
   align-items: center !important;
 }

</style>