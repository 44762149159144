export const ConvertFtToMeters = (a) => {
    return parseFloat(a * .3048);
};
export const ConvertHaToAcre = (a) => {
    return parseFloat(a * 2.47);
};
export const ConvertM2ToAcre = (a) => {
    return parseFloat(a * 0.000247105);
};
export const ConvertAcreToM2 = (a) => {
    return parseFloat(a / 0.000247105);
};
export const ConvertM2ToHectare = (a) => {
    return parseFloat(a * 0.0001);
};
export const ConvertHectareToM2 = (a) => {
    return parseFloat(a / 0.0001);
};
export const ConvertAcreToHa = (a) => {
    return parseFloat(a / 2.47);
};
export const ConvertMetersToFt = (a) => {
    return parseFloat(a / .3048);
};
export const ConvertMetersToMM = (a) => {
    return parseFloat(a * 1000);
};
export const ConvertMetersToCM = (a) => {
    return parseFloat(a * 100);
};
export const ConvertMMToMeters = (a) => {
    return parseFloat(a / 1000);
};
export const ConvertMetersToIn = (a) => {
    return parseFloat(a / 0.0254);
};
export const ConvertInToMeters = (a) => {
    return parseFloat(a * 0.0254);
};
export const ConvertPAStoPSI = (a) => {
    return parseFloat(a * 0.000145038);
};
export const ConvertPSItoPAS = (a) => {
    return parseFloat(a / 0.000145038);
};
export const ConvertPAStoBAR = (a) => {
    return parseFloat(a * 0.00001);
};
export const ConvertBARtoPAS = (a) => {
    return parseFloat(a / 0.00001);
};
// 1 lpm per hectare = 0.106906637 (US gallons per minute) per acre
export const ConvertLPMHtoGPMA = (a) => {
    return parseFloat(a * 0.106906637);
};
export const ConvertGPMAtoLPMH = (a) => {
    return parseFloat(a / 0.106906637);
};
export const ConvertLPMHtoMMPD = (a) => {     // mm/day
    return parseFloat(a * 0.144);
};
export const ConvertLPMHtoINPD = (a) => {     // in/day
    return parseFloat(ConvertLPMHtoGPMA(a) * 0.053030);
};
export const ConvertMMPDtoLPMH = (a) => {     // mm/day
    return parseFloat(a / 0.144);
};
export const ConvertINPDtoGPMA = (a) => {     // in/day
    return parseFloat(a / 0.053030);
};
export const ConvertLPMtoGPM = (a) => {
    return parseFloat(a * 0.26417287472922);
};
export const ConvertGPMtoLPM = (a) => {
    return parseFloat(a / 0.26417287472922);
};
export const ConvertLPMtoLPS = (a) => {
    return parseFloat(a) / 60;
};
export const ConvertLPStoLPM = (a) => {
    return parseFloat(a) * 60;
};
export const ConvertLPMHtoLPSH = (a) => {
    return parseFloat(a) / 60;
};
export const ConvertLPSHtoLPMH = (a) => {
    return parseFloat(a) * 60;
};
export const ConvertLPMtoM3H = (a) => {
    return parseFloat(a) * 0.06;
};
export const ConvertM3HtoLPM = (a) => {
    return parseFloat(a) / 0.06;
};
export const ConvertINtoMM = (a) => {
    return parseFloat(a) * 25.4;
};
export const FilterLength = (length, unit) => {
    if (length == null) {return 0;}
    if (unit == 'ft') {return ConvertMetersToFt(length);}
    return length;
};
export const FilterFlow = (flow, unit) => {
    if (flow == null) {return 0;}
    if (unit == 'gal/min') {return ConvertLPMtoGPM(flow);}
    if (unit == 'liters/sec') {return (flow / 60);}
    if (unit == 'm3/h') {return ConvertLPMtoM3H(flow);}
    return flow;
};
export const FilterRate = (rate, unit) => {
    if (rate == null) {return 0;}
    if (unit == 'gal/min/acre') {return ConvertLPMHtoGPMA(rate);}
    if (unit == 'liters/sec/ha') {return ConvertLPMHtoLPSH(rate);}
    if (unit == 'mm/hr') {return ConvertLPMHtoMMPD(rate) / 24;}
    if (unit == 'in/hr') {return ConvertLPMHtoINPD(rate) / 24;}
    return rate;
};
export const FilterArea = (area, unit) => {
    if (area == null) {return 0;}
    if (unit == 'acres') {return ConvertM2ToAcre(area);}
    if (unit == 'hectares') {return ConvertM2ToHectare(area);}
    return area;
};
export const FilterPerArea = (perArea, unit) => {
    if (perArea == null) {return 0;}
    if (unit == 'acres') {return (1 / ConvertM2ToAcre(1 / perArea));}
    if (unit == 'hectares') {return (1 / ConvertM2ToHectare(1 / perArea));}
    return perArea;
};
export const UnfilterLength = (length, unit) => {
    if (length == null) {return 0;}
    if (unit == 'ft') {return ConvertFtToMeters(length);}
    return length;
};
export const UnfilterFlow = (flow, unit) => {
    if (flow == null) {return 0;}
    if (unit == 'gal/min') {return ConvertGPMtoLPM(flow);}
    if (unit == 'liters/sec') {return (flow * 60);}
    if (unit == 'm3/h') {return ConvertM3HtoLPM(flow);}
    return flow;
};
export const UnfilterArea = (area, unit) => {
    if (area == null) {return 0;}
    if (unit == 'acres') {return ConvertAcreToM2(area);}
    if (unit == 'hectares') {return ConvertHectareToM2(area);}
    return area;
};
// Flow Conversions
export const FlowFromRate = (rate, length, arc, runLength) => {

    if (runLength == 0 && length == 0)
        {return 0;}
    // rate = export const ConvertLPMtoGPM(rate);
    if (runLength == 0 || runLength == null)
        {return rate * Math.PI * (Math.pow(length, 2) / 10000) * (arc / 360);}
    else
        {return rate * length * (runLength / 10000);}
};
export const FlowFromRadius = (L, r, Qtot, AppRate) => {// Machine Length, EG Radius, TotalFlow, AppRate
    if (AppRate == null)// Caclulate from Qtot
    {
        AppRate = (Qtot * 10000) / (Math.pow((L + r), 2) * Math.PI); // lpm/ha
    }

    var Qgun = (AppRate * Math.PI * (Math.pow((L + r), 2) - Math.pow(L, 2))) / 10000;// lpm

    return Qgun;
};
export const RadiusFromFlow = (L, Qgun, Qtot, AppRate) => {// Machine Length, EG Flow, TotalFlow, AppRate
    if (AppRate == null)// Calculate from flow
    {
        AppRate = ((Qtot - Qgun) * 10000) / (Math.PI * Math.pow(L, 2)); // lpm/ha
    }
    else if (Qtot == null)// Calculate from Rate
    {
        Qtot = ((AppRate * Math.PI * Math.pow(L, 2)) / 10000) + Qgun;
    }

    var radiusAdjusted = Math.sqrt((Qtot * 10000) / (AppRate * Math.PI)) - L;

    return radiusAdjusted;
};
export const translateImportLength = (len, units) => {
    switch (units) {
        case 'ft':
            return ConvertFtToMeters(len);
        case 'm':
            return parseFloat(len);
    }
};
export const translateImportPressure = (press, units) => {
    switch (units) {
        case 'psi':
            return ConvertPSItoPAS(press);
        case 'bar':
            return ConvertBARtoPAS(press);
    }
};
export const translateImportFlow = (flow, units) => {
    switch (units) {
        case 'gpm':
            return ConvertGPMtoLPM(flow);
        case 'lpm':
            return parseFloat(flow);
        case 'm�/hr':
            return ConvertM3HtoLPM(flow);
        case 'm3/hr':
            return ConvertM3HtoLPM(flow);
    }
};
export const translateImportDia = (dia, units) => {
    switch (units) {
        case 'in':
            return ConvertInToMeters(dia);
        case 'cm':
            return parseFloat(dia) * 0.01;// cm to m
        case 'mm':
            return ConvertMMToMeters(dia);
    }
};

// export const translateLength = function(len) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertMetersToFt(len);
//         case '0':
//             return parseFloat(len);
//     }
// }
// export const translateArea = function(len) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertM2ToAcre(len);
//         case '0':
//             return export const ConvertM2ToHectare(len);
//     }
// }
// export const translateInputLength = function(len) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertFtToMeters(len);
//         case '0':
//             return parseFloat(len);
//     }
// }
// export const translateDia = function(dia) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertMetersToIn(dia);
//         case '0':
//             return export const ConvertMetersToMM(dia);
//     }
// }
// export const translateDropLength = function(len) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertMetersToIn(len);
//         case '0':
//             return export const ConvertMetersToCM(len);
//     }
// };
// export const translateInputDia = function(dia) => {
//     switch (res[0]) => {
//         case '1':
//             return export const ConvertInToMeters(dia);
//         case '0':
//             return export const ConvertMMToMeters(dia);
//     }
// }
// export const translatePressure = function(pressure) => {
//     switch (res[1]) => {
//         case '2':
//             return export const ConvertPAStoBAR(pressure);
//         case '1':
//             return export const ConvertPAStoPSI(pressure);
//         case '0':
//             return parseFloat(pressure) * 0.001;
//     }
// }
// export const translateInputPressure = function(pressure) => {
//     switch (res[1]) => {
//         case '2':
//             return export const ConvertBARtoPAS(pressure);
//         case '1':
//             return export const ConvertPSItoPAS(pressure);
//         case '0':
//             return parseFloat(pressure) * 1000;
//     }
// }
// export const translateFlow = function(flow) => {
//     switch (res[2]) => {
//         case '3':
//             return export const ConvertLPMtoM3H(flow);
//         case '2':
//             return export const ConvertLPMtoLPS(flow);
//         case '1':
//             return export const ConvertLPMtoGPM(flow);
//         case '0':
//             return parseFloat(flow);
//     }
// }
// export const translateInputFlow = function(flow) => {
//     switch (res[2]) => {
//         case '3':
//             return export const ConvertM3HtoLPM(flow);
//         case '2':
//             return export const ConvertLPStoLPM(flow);
//         case '1':
//             return export const ConvertGPMtoLPM(flow);
//         case '0':
//             return parseFloat(flow);
//     }
// }
// export const translateRate = function(rate) => {
//     switch (res[3]) => {
//         case '4':
//             return export const ConvertGPMAtoINPD(rate);
//         case '3':
//             return export const ConvertLPMHtoMMPD(rate);
//         case '2':
//             return export const ConvertLPMHtoLPSH(rate);
//         case '1':
//             return export const ConvertLPMHtoGPMA(rate);
//         case '0':
//             return parseFloat(rate);
//     }
// }
// export const translateInputRate = function(rate) => {
//     switch (res[3]) => {
//         case '4':
//             return export const ConvertINPDtoGPMA(rate);
//         case '3':
//             return export const ConvertMMPDtoLPMH(rate);
//         case '2':
//             return export const ConvertLPSHtoLPMH(rate);
//         case '1':
//             return export const ConvertGPMAtoLPMH(rate);
//         case '0':
//             return parseFloat(rate);
//     }
// }