<template>
	<v-card id="graphTip" class="tooltip">
		<div class="tooltip_content">
			<div class="toolTip_header">
				<div class="toolTip_cirlce" :style="toolTipStyles"></div>
				<h4 class="tooltip_valve_name">{{ group }}</h4>
			</div>
			<hr class="tooltip_hr2" />
			<h5>Runtime</h5>
			<section>
				<div class="graph_tooltip_values">
					<div class="graph_tooltip_titles">
						<div class="verified_hours_square"></div>
						<p>Verified</p>
					</div>
					<p data-testid="tooltip-verifiedHours">{{ verifiedHours }}</p>
				</div>
				<div class="graph_tooltip_values">
					<div class="graph_tooltip_titles">
						<div class="hours_square"></div>
						<p>Reported</p>
					</div>
					<p>{{ hoursRan }}</p>
				</div>
			</section>
			<hr class="tooltip_hr3" />
			<h5>Applied Depth {{ this.depthUnit }}</h5>
			<section>
				<div class="graph_tooltip_values">
					<div class="graph_tooltip_titles">
						<div class="verified_inches_square"></div>
						<p>Verified</p>
					</div>
					<p>{{ verifiedPrecipitation }}</p>
				</div>
				<div class="graph_tooltip_values">
					<div class="graph_tooltip_titles">
						<div class="inches_square"></div>
						<p>Reported</p>
					</div>
					<p>{{ precipitation }}</p>
				</div>
			</section>
		</div>
	</v-card>
</template>
<script>
import { hoursConverter } from '../library/functions';
import * as UnitService from '@/services/UnitConversionService';
import { useSettingsStore } from '@/stores/SettingsStore';
import { mapStores } from 'pinia';

export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapStores(useSettingsStore),
		depthUnit () {
			return this.settingsStore.lengthUnit === 'ft' ? '(in)' : '(mm)';
		},
		group () {
			return this.item != null ? this.item.group : '';
		},
		toolTipStyles () {
			return this.item != null ? {'background-color': this.item.programColor} : {'background-color': '#808080'};
		},
		verifiedHours () {
			if (this.item != null) {
				const verifyRuntime = this.item.verifiedHour;
				const convertVRuntime = verifyRuntime * 3600;
				return verifyRuntime === undefined ? '00:00:00' : hoursConverter(convertVRuntime);
			} else {return '';}
		},
		verifiedPrecipitation () {
			if (this.item != null) {
				const verifyPrecip = this.item.verifiedPrecipitation;
				return verifyPrecip === undefined ? '0.00' : verifyPrecip;
			} else {return '';}
		},
		hoursRan () {
			if (this.item != null) {
				const runtime = this.item.hoursRan;
				const convertRuntime =  runtime * 3600;
				return runtime === undefined ? '00:00:00' : hoursConverter(convertRuntime);
			} else {return '';}
		},
		precipitation () {
			if (this.item != null) {
				const precip = this.item.precipitation;
				return precip === undefined ? '0.00' : precip; 
			} else {return '';}
		},
	},
	methods: {
		filterDepth (depth) {
			const lengthUnit = this.settingsStore.lengthUnit;
			const convertedDepth = UnitService.FilterLength(
				depth / 10000 / 1000,
				this.settingsStore.lengthUnit
			);
			if (lengthUnit == 'ft') {return (convertedDepth * 12).toFixed(2);}
			if (lengthUnit == 'm') {return (convertedDepth * 1000).toFixed(2);}
			return depth;
		},
	},
};
</script>
<style scoped>
.tooltip {
	width: 0px;
	height: 0px;
	position: absolute;
	background-color: lightgray;
	color: black;
	visibility: visible;
	border: "2px solid black";
	border-radius: "10px";
	padding: "10px";
	z-index: 999;
}
.graph_tooltip_values {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
}
.graph_tooltip_titles {
	display: flex;
	flex-direction: row;
}
.tooltip_content {
	padding: 10px;
}
.tooltip_hr1,
.tooltip_hr2,
.tooltip_hr3 {
	margin: 5px 0;
}
.tooltip_hr2 {
	font-weight: 700;
}
.tooltip_valve_name {
	font-size: 15px;
	padding-left: 8px;
}
.toolTip_cirlce {
	height: 15px;
	width: 15px;
	padding-right: 15px;
	border-radius: 50%;
	display: inline-block;
}
.toolTip_header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
</style>