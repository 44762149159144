<template>
  <div class="input-table-holder">
    <table v-show="items !== null">
      <thead class="table-header">
        <tr>
          <th v-for="(head, index) in headers" :key="index" class="table-header-title">
            {{ head }}
          </th>
        </tr>
        <tr>
          <th v-for="(subhead, index) in subheaders" :key="index" class="table-header-subtitle">
            {{ subhead }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" class="item-row">
          <td>
            <div>{{ this.switchValveName(item.mcName, item.valveId) }}</div>
          </td>
          <td>
            <div>
              <input
                type="number"
                @keypress="handleNaN"
                @focus="$event.target.select()"
                :value="this.filterLength(item.emitterSpacingMeters, 'emitter', index)"
                @blur="item.emitterSpacingMeters = this.storeableLength($event.target.value);"
              />
            </div>
          </td>
          <td>
            <div>
              <input
                type="number"
                @keypress="handleNaN"
                @focus="$event.target.select()"
                :value="this.filterLength(item.rowSpacingMeters, 'row', index)"
                @blur="item.rowSpacingMeters = this.storeableLength($event.target.value);"
              />
            </div>
          </td>
          <!-- <td>
            <div>{{ this.filterPerArea(item.emittersPerSquareMeter()) }}</div>
          </td> -->
          <td>
            <div>
              <input
                type="number"
                @keypress="handleNaN"
                @focus="$event.target.select()"
                :value="this.filterFlow(item.emitterFlowRateLPM, 'emitterFlow', index)"
                @blur="item.emitterFlowRateLPM = this.storeableFlow($event.target.value);"
              />
            </div>
          </td>
          <td>
            <div>{{ this.filterRate(item.appRateLPMH) }}</div>
          </td>
          <td>
            <div>
              <input
                type="number"
                @keypress="handleNaN"
                @focus="$event.target.select()"
                :value="this.filterArea(item.areaM2, index)"
                @blur="item.areaM2 = this.storeableArea($event.target.value);"
              />
            </div>
          </td>
          <td>
            <div>
              <input
                type="number"
                @keypress="handleNaN"
                @focus="$event.target.select()"
                :value="this.filterFlow(item.valveFlowLPM, 'valveFlow', index)"
                @blur="item.valveFlowLPM = this.storeableFlow($event.target.value);"
              />
            </div>
          </td>
          <td>
            <div>
              <div class="save-btn" @click="this.saveEmitterData(item, index);" :class="item.isDirty ? 'save-enabled' : 'save-disabled'" >
<!--              <v-btn @click="this.saveEmitterData(item, index);" :class="item.isDirty ? 'save-enabled' : 'save-disabled'" v-model="isSaving" :disabled="item.isDirty == false">-->
                <span v-show="emitterStore.selectedItem !== index">SAVE</span>
                <v-progress-circular :size="15" :width="4" indeterminate color="#FFFFFF" style="background-color:#F7DAC8;" v-if="this.appStore.isSaving && emitterStore.selectedItem === index">
                </v-progress-circular>
                </div>
<!--              </v-btn>-->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useMCStore } from '@/stores/MCStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useEmitterStore } from '@/stores/EmitterStore';
import { useAppStore } from '@/stores/AppStore';
import * as UnitConversion from '@/services/UnitConversionService.js';
import { mapStores } from 'pinia';

export default {
  data () {
    return {
      decimalPlaces: 3,
    };
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    subheaders: {
      type: Array,
      required: false
    },
    height: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useSettingsStore, useAppStore, useEmitterStore, useMCStore),
    isSaving () {
      return this.appStore.isSaving ? this.appStore.isSaving : false;
    },
  },
  methods: {
    handleNaN (event) {
      ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
    },
    switchValveName (valveName, valveId) {
      if (valveName === null || valveName === undefined) {
        // return 'No Name' + '(' + valveId + ')';
        return valveId;
      } else {
        return valveName;
      }
    },
    filterLength (length, type, index) {
      let filterLength = UnitConversion.FilterLength(length, this.settingsStore.lengthUnit).toFixed(this.decimalPlaces);
      if (type === 'emitter') {
        if (length === this.mcStore.current_mc.valves[index].emitter_spacing) { return filterLength; }
        this.mcStore.current_mc.valves[index].emitter_spacing = this.$props.items[index].emitterSpacingMeters;
      } else {
        if (length === this.mcStore.current_mc.valves[index].row_spacing) { return filterLength; }
        this.mcStore.current_mc.valves[index].row_spacing = this.$props.items[index].rowSpacingMeters;
      }
      this.mcStore.current_mc.valves[index].isDirty = true;
      return filterLength;
    },
    filterFlow (flow, type, index) {
      let filterFlow = UnitConversion.FilterFlow(flow, this.settingsStore.flowUnit).toFixed(this.decimalPlaces);
      if (type === 'emitterFlow') {
        if (flow == this.mcStore.current_mc.valves[index].emitter_flow_lpm) { return filterFlow; }
        this.mcStore.current_mc.valves[index].emitter_flow_lpm = this.$props.items[index].emitterFlowRateLPM;
      } else {
        if (flow == this.mcStore.current_mc.valves[index].flow_lpm) { return filterFlow; }
        this.mcStore.current_mc.valves[index].flow_lpm = this.$props.items[index].valveFlowLPM;
      }
      this.mcStore.current_mc.valves[index].isDirty = true;
      return filterFlow;
    },
    filterRate (rate) {
      return UnitConversion.FilterRate(rate, this.settingsStore.rateUnit).toFixed(this.decimalPlaces);
    },
    filterArea (area, index) {
      const filterArea = UnitConversion.FilterArea(area, this.settingsStore.areaUnit).toFixed(this.decimalPlaces);
      if (area == this.mcStore.current_mc.valves[index].area_m2) { return filterArea; }
      this.mcStore.current_mc.valves[index].area_m2 = area;
      this.mcStore.current_mc.valves[index].isDirty = true;
      return filterArea;
    },
    filterPerArea (perArea) {
      return UnitConversion.FilterPerArea(perArea, this.settingsStore.areaUnit).toFixed(this.decimalPlaces);
    },
    storeableLength (length) {
      if (length == '') { return 0; }
      return UnitConversion.UnfilterLength(parseFloat(length), this.settingsStore.lengthUnit);
    },
    storeableFlow (flow) {
      if (flow == '') { return 0; }
      return UnitConversion.UnfilterFlow(parseFloat(flow), this.settingsStore.flowUnit);
    },
    storeableArea (area) {
      if (area == '') { return 0; }
      return UnitConversion.UnfilterArea(parseFloat(area), this.settingsStore.areaUnit);
    },
    saveEmitterData (item, index) {
      this.emitterStore.selectedItem = index;
      this.$emit('save', item);
      this.appStore.isSaving = true;
      this.mcStore.current_mc.valves[index].isDirty = false;
    },
    /* tableStyle () {
      let height = window.innerHeight;
      // let width = window.innerWidth;
      let inHeight = parseFloat(this.height);
      inHeight = inHeight * 0.01;
      return {
        'max-height': (height * inHeight).toString() + 'px',
        'height': (height * inHeight).toString() + 'px',
        'overflow-y': 'scroll'
      };
    }*/
  }
};
</script>

<style scoped>

table {
  width: 100%;
  height: 100%;
  border-spacing: 0;
}


tbody tr td {
  padding-bottom: 10px;

}

.table-header {
  top: 0;
  position: sticky;
  background-color: #fafafa;
}

.highlight {
  background-color: rgba(220, 110, 42, 0.25) !important ;
  color: black;
}

.highlight-first {
  background-color: rgba(220, 110, 42, 1) !important;
  color: black;
}

tr td div {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
/*  border-right: 1px solid lightgray;*/
}

tr td {
  border-right: 0.5px solid lightgray;
}

.item-row td:first-child div {
  justify-content: flex-start;

}

.item-row td:first-child {
  width: 15%;
}

tbody input {
/*  background-color: lightblue;*/
  width: 100%;
  height: 100%;
  text-align: center;
}

tr:hover td:first-child div {
  background-color: rgba(220, 110, 42, 1);
  color: white;
}
tr:hover td:not(first-child) div {
  background-color: rgba(220, 110, 42, 0.25);
  color: black;
}


tr td:not(first-child) div {
  background-color: #fafafa;
  height: 30px;
}
tr td:first-child div {
  height: 30px;
  text-align: left;
  border-radius: 15px 0 0 15px;
  padding-left: 15px;
}
tr td:last-child div {
  height: 30px;
  text-align: center;
  border-radius: 0px 15px 15px 0px;
 /* padding-left: 15px;*/
}
th:last-child,
td:last-child {
  border-right: 0px solid darkgray;
}

td {
  font-size: small;
  width: 12vw;
  text-align: center;
  border-right: 0px solid darkgray;
}

.table-items {
  width: 100%;
  border-spacing: 0;
}

.table-header-title {

  font-size: .8em;
  line-height: 1.3em;
  border: 0.5px solid lightgray;
  border-bottom: 1px solid gray;
  padding: 10px 3px 10px 3px;

}

.table-header-subtitle {

  border: 0.5px solid lightgray;
  text-align: right;
  padding: 5px 15px 5px 5px;
  font-size: .76em;
}

.table-body td {

  padding-top: 3px;
  padding-bottom: 3px;
  border-right: 0.5px solid lightgray;
}

.table-body div {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: .9em;

}

.table-group {
  justify-content: flex-start !important;
  /*  align-items: flex-start !important;*/
  background-color: yellow;
}

.table-data-group {
  width: 20%;
}

.input-table-holder {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-top: 10px;
}

.save-btn {
  cursor: pointer;
  width: 50%;
  background-color: lightgrey;
  border-radius: 20px !important;
}

.save-enabled {
  background-color: #00bd5e;
  color: white;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

</style>
