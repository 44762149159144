export const switchName = (item_name, item_id) => {
	if (item_name == null) {
		return item_id;
	} else {
		return item_name;
	}
};

export const switchValveName = (itemName, serial) => {
	if(itemName === null) {
		return serial;
	} else {
		return itemName;
	}
};

export const switchProgramName = (itemName) => {
	if(itemName === null) {
		return 'No Name';
	} else {
		return itemName;
	}
};

// It is a reusable function that sums up the valves given to it. Using reduce function.
export const sum = (element) => {
	const initialValue = 0;
	const total = element.reduce((prev, curr) => prev + curr, initialValue);
	return total;
};

// Converts millimeters to inches
export const millimeterToInches = (millimeters) => {
	const inches = millimeters / 25.4;
	return inches;
};

// converts inches to millimeters
export const inchesToMillimeters = (inches) => {
	const millimeters = inches * 25.4;
	return millimeters;
};

// converts meters to acres
export const metersSquaredToAcres = (metersSquared) => {
	const acres = metersSquared * 0.0002471054;
	return acres;
};

// converts acres to meters squared
export const acresToMetersSquared = (acres) => {
	const metersSquared = acres * 4046.856422;
	return metersSquared;
};

// converts lpm to gom
export const lpmToGpm = (lpm) => {
	const gpm = lpm * 0.26417287472922;
	return gpm;
};

// converts gpm to lpm
export const gpmToLpm = (gpm) => {
	const lpm = gpm * 3.785411784;
	return lpm;
};

// calculates application rate
export const appRate = (application) => {
	const applicationRate = application / 25.4;
	return applicationRate;
};

export const labels = (data) => {
	let labels = [];
	data.forEach((element) => {
		labels.push(switchName(element.valve_name, element.serial));
	});
	return labels;
};

export const dateLabels = (data) => {
	let labels = [];
	data.forEach((element) => {
		labels.push(element.date);
	});
	return labels;
};

export const hours = (data) => {
	let hours = [];
	data.forEach((element) => {
		hours.push(element.total_seconds / 3600);
	});
	let hoursDataSet = {
		label: 'Hours of Runtime',
		backgroundColor: '#0089D0',
		data: hours,
	};
	return hoursDataSet;
};

export const verifiedHours = (data) => {
	let verifiedHours = [];
	data.forEach((element) => {
		verifiedHours.push(element.verified_hours / 3600);
	});
	let verifiedHoursDataSet = {
		label: 'Verified Hours',
		backgroundColor: '#1F1F1F',
		data: verifiedHours,
	};
	return verifiedHoursDataSet;
};

export const singleVerifiedHours = (data) => {
	let verifiedHours = [];
	data.forEach((element) => {
		verifiedHours.push(element.verified_seconds / 3600);
	});
	let verifiedHoursDataSet = {
		label: 'Verified Hours',
		backgroundColor: '#1F1F1F',
		data: verifiedHours,
	};
	return verifiedHoursDataSet;
};

export const inches = (data) => {
	let inches = [];
	data.forEach((element) => {
		inches.push(element.valve_inches);
	});
	let inchesDataSet = {
		label: 'Inches Applied',
		backgroundColor: '#0FAF5F',
		data: inches,
	};
	return inchesDataSet;
};

export const verifiedPrecipitation = (data) => {
	let verifiedPrecipitation = [];
	data.forEach((element) => {
		verifiedPrecipitation.push(element.verified_inches);
	});
	let verifiedPrecipitationDataSet = {
		label: 'Verified Inches',
		backgroundColor: '#737373',
		data: verifiedPrecipitation,
	};
	return verifiedPrecipitationDataSet;
};

export const nullToNotName = (planName) => {
	if (planName === null) {
		return 'zz-Not Named';
	} else {
		return planName;
	}
};

// formats data for select valves in select menu
export const formatValves = (dataValues) => {
	const valveData = [];
	if (dataValues != null) {
		dataValues.forEach((element) => {
			valveData.push({
				label: switchName(element.valve_name, element.serial),
				value: element.valve_id,
			});
		});
	}
	return valveData;
};

// formats data for programs in select menu
export const formatPrograms = (dataValues) => {
	const programData = [];
	if (dataValues != null)
		{dataValues.forEach((element) => {
			let valveId = [];
			let valveName = [];
			element.valves.forEach((element) => {
				valveId.push(element.valve_id);
				valveName.push(element.valve_name);
			});
			programData.push({
				label: nullToNotName(element.plan_name),
				value: valveId,
			});
		});}
	return programData;
};

export const hoursConverter = (value) => {
	const sec = parseInt(value, 10);
	let hours = Math.floor(sec / 3600);
	let minutes = Math.floor((sec - hours * 3600) / 60);
	let seconds = sec - hours * 3600 - minutes * 60;
	if (hours < 10) {
		hours = '0' + hours;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (seconds < 10) {
		seconds = '0' + seconds;
	}
	const formatHours = hours + ':' + minutes + ':' + seconds;
	return formatHours;
};

export const csv = (arr) => {
	var ret = [];
	ret.push('"' + Object.keys(arr[0]).join('","') + '"');
	for (var i = 0, len = arr.length; i < len; i++) {
		var line = [];
		for (var key in arr[i]) {
			if (!Object.getOwnPropertyDescriptor(arr[i], key)) {
				line.push('"' + arr[i][key] + '"');
			}
		}
		ret.push(line.join(','));
	}
	return ret.join('\n');
};

export const saveDataAsCSV = (data) => {
	var csvdata = data.map(e => e.join(',')).join('\n');
	var type = 'text/csv;charset=utf-8;';
	var blob = new Blob([csvdata], { type: type });
	var filename = 'program_report' + Date.now() + '.csv';
	if (typeof window.navigator.msSaveBlob === 'undefined') { // feature detection
		var URL = window.URL || window.webkitURL;
		var downloadUrl = URL.createObjectURL(blob);
		var saving = document.createElement('a');
		saving.href = downloadUrl;
		saving.download = filename;
		document.body.appendChild(saving);
		saving.click();
		document.body.removeChild(saving);
	}
};

export const hslToHex = (h, s, l) => {
	l /= 100;
	const a = (s * Math.min(l, 1 - l)) / 100;
	const f = (n) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, '0'); // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
};

export const colorFromHue = (hue) => {
	const scaledHue = hue * 360;// hue 0 to 360, around the color wheel
	const oneThird = 1.0 / 3.0;
	const arc = (scaledHue % oneThird) / oneThird;
	const fractionalAdjust = Math.cos(arc * Math.PI * 2.0) * -0.5 + 0.5;
	const saturation = 100 * (0.9 + 0.1 * fractionalAdjust);
	return hslToHex(scaledHue, saturation, 40);// brightness hardcoded to 50, 100 is washed out white.
};