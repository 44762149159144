<template>
    <InputTable :headers="this.headers()" :items="this.inputData" :subheaders="this.subHeaders()" :height="'74'"
        @save="this.saveEmitterData($event)" />
</template>
<script>
import InputTable from '@/components/InputTable.vue';
import { useEmitterStore } from '../stores/EmitterStore';
import { useSettingsStore } from '../stores/SettingsStore';
import { useMCStore } from '@/stores/MCStore';
import { mapStores } from 'pinia';
export default {
    name: 'DataView',
    components: {
        InputTable
    },
    data: () => ({

    }),
    computed: {
        ...mapStores(useEmitterStore, useSettingsStore, useMCStore),
        inputData () {
            return this.emitterStore.extractEmitterData(this.mcStore.current_mc.valves);
        },
    },
    methods: {
        headers () {
            return ['', 'Space Between Emitters', 'Space Between Rows', 'Emitter Flow', 'App Rate', 'Area', 'Valve Flow', ''];
            // return ["", "Space Between Emitters", "Space Between Rows", "Emitters / Unit Area", "Emitter Flow", "App Rate", "Area", "Valve Flow", ""];
        },
        subHeaders () {
            return [
                '',
                this.settingsStore.lengthUnit,
                this.settingsStore.lengthUnit,
                this.settingsStore.flowUnit,
                this.settingsStore.rateUnit,
                this.settingsStore.areaUnit,
                this.settingsStore.flowUnit,
                ''
            ];
        },
        saveEmitterData (item) {
            const data = {
                area_m2: item.areaM2,
                emitter_spacing: item.emitterSpacingMeters,
                emitter_flow_lpm: item.emitterFlowRateLPM,
                flow_lpm: item.valveFlowLPM,
                row_spacing: item.rowSpacingMeters,
                valve_id: item.valveId,
            };
            this.emitterStore.saveEmitterData(data);
        },
    }
};
</script>
