<template>
  <v-row style="margin: 20px 0px 50px 0px">
    <v-col class="unit-column">
      <div class="unit-title">LENGTHS</div>
      <v-radio-group v-model="this.lengthUnit" class="unit-option">
        <v-radio v-for="n in this.lengthOptions()" :key="n" :label="n" :value="n" @click="redrawGraph"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col class="unit-column">
      <div class="unit-title">PRESSURES</div>
      <v-radio-group v-model="this.pressureUnit" class="unit-option">
        <v-radio v-for="n in this.pressureOptions()" :key="n" :label="n" :value="n"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col class="unit-column">
      <div class="unit-title">FLOWS</div>
      <v-radio-group v-model="this.flowUnit" class="unit-option">
        <v-radio v-for="n in this.flowOptions()" :key="n" :label="n" :value="n"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col class="unit-column">
      <div class="unit-title">RATES</div>
      <v-radio-group v-model="this.rateUnit" class="unit-option">
        <v-radio v-for="n in this.rateOptions()" :key="n" :label="n" :value="n"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col class="unit-column">
      <div class="unit-title">AREAS</div>
      <v-radio-group v-model="this.areaUnit" class="unit-option">
        <v-radio v-for="n in this.areaOptions()" :key="n" :label="n" :value="n"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
import { useFunctionStore } from '@/stores/FunctionStore';
import { useProgramStore } from '@/stores/ProgramStore';
import { useRMTStore } from '@/stores/RMTStore';
import { useSettingsStore } from '@/stores/SettingsStore.js';
import { mapStores } from 'pinia';
export default {
  computed: {
    ...mapStores(useSettingsStore, useFunctionStore, useProgramStore),
    lengthUnit: {
      get () {
        return this.settingsStore.lengthUnit;
      },
      set (value) {
        this.settingsStore.setLengthUnit(value);
      },
    },
    pressureUnit: {
      get () {
        return this.settingsStore.pressureUnit;
      },
      set (value) {
        this.settingsStore.setPressureUnit(value);
      },
    },
    flowUnit: {
      get () {
        return this.settingsStore.flowUnit;
      },
      set (value) {
        this.settingsStore.setFlowUnit(value);
      },
    },
    rateUnit: {
      get () {
        return this.settingsStore.rateUnit;
      },
      set (value) {
        this.settingsStore.setRateUnit(value);
      },
    },
    areaUnit: {
      get () {
        return this.settingsStore.areaUnit;
      },
      set (value) {
        this.settingsStore.setAreaUnit(value);
      },
    }
  },
  methods: {
    redrawGraph () {
      const RMTStore = useRMTStore();
      // fix issues with bug but could do something else.
      if (this.programStore.isByProgram) {
        if (RMTStore.selectedPlans.length === 0) {return;}
      } else {
        if (RMTStore.selectedValves.length === 0) {return;}
      }
      const currentData = this.programStore.currentEventData;
      if (currentData.length > 0) {
        if (currentData[0].program != 'valves' && currentData[0].program != undefined && currentData[0].program != null) {
          this.programStore.isByProgram = true;
          this.functionStore.setProgramType('program');
          this.programStore.generateHandler();
        } else {
          this.programStore.isByProgram = false;
          this.functionStore.setProgramType('valve');
          this.programStore.generateHandler();
        }
      }
    },
    lengthOptions () {
      return this.settingsStore.lengthOptions;
    },
    pressureOptions () {
      return this.settingsStore.pressureOptions;
    },
    flowOptions () {
      return this.settingsStore.flowOptions;
    },
    rateOptions () {
      return this.settingsStore.rateOptions;
    },
    areaOptions () {
      return this.settingsStore.areaOptions;
    },
  }
};
</script>
<style scoped>
.unit-column {
  width: 30px;
}

.unit-title {
  height: 40px;
  color: var(--nelson-blue);
  /* padding: 0px 10px; */
  text-align: center;
  border-bottom: 4px lightgray solid;
}

.unit-option {
  color: #444444
}
</style>
