<template>
  <div class="modal-view-bg" :class="showBg" v-show="getIsShowing" @click="handleBackgroundClick" >
    {{ this.$props.obj }}
    <ModalPrompt v-show="getModalType === 'prompt'"></ModalPrompt>
    <SelectViewModal v-show="getModalType === 'select'" ></SelectViewModal>
    <ModalCalendar v-show="getModalType === 'calendar'" ></ModalCalendar>
    <ViewAll v-show="getModalType === 'view all'"></ViewAll>
    <TOSModal v-show="getModalType === 'tos'"></TOSModal>
    <ModalRedeem v-show="getModalType === 'redeem'"></ModalRedeem>
  </div>
</template>
 
<script>
import { useModalStore } from '@/stores/ModalStore';
import { storeToRefs } from 'pinia/dist/pinia';
import ModalRedeem from './ModalRedeem.vue';
import SelectViewModal from '@/components/modal/SelectViewModal';
import ModalCalendar from '@/components/modal/ModalCalendar';
import ModalPrompt from '@/components/modal/ModalPrompt';
import ViewAll from '@/components/functionBar/ViewAll';
import TOSModal from '@/components/modal/TOSModal';

export default {
  name: 'ModalView',
  components: {
    ModalPrompt,
    ModalCalendar,
    SelectViewModal,
    ViewAll,
    TOSModal,
    ModalRedeem
},
  setup () {
    const modalStore = useModalStore();
    const { getIsShowing, getModalBackground } = storeToRefs(modalStore);
    return {
      modalStore, getIsShowing, getModalBackground
    };
  },
  computed: {
    showBg () {
      return '';
    },
    getModalType () {
      const modalStore = useModalStore();
      return modalStore.getModalType;
    }
  },
  methods: {
    handleNo () {
      this.modalStore.toggleModal();
    },
    handleKey (e) {
      if (e.key === 's') { this.modalStore.toggleModal(); }
    },
    handleBackgroundClick () {
      if (this.getModalType === 'tos' || this.getModalType === 'redeem') { return; }
      this.modalStore.toggleModal();
    },
  }
};
</script>

<style scoped>
.bg-transparent {
  background-color: rgba(0, 0, 0, 0.0);
}
.modal-view-bg {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
}
.modal-view {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 300;
}
</style>

<style>
.modal-title {
  display: flex;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 20px;
  font-size: .85em;
}
.modal-bg {
  width: 80%;
  height: 60%;
  background-color: white;
}
</style>