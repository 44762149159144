import { defineStore } from 'pinia';
import { useModalStore } from '@/stores/ModalStore.js';
import { useSettingsStore } from '@/stores/SettingsStore.js';
import $ from 'jquery';
import { useMCStore } from './MCStore';
import { useAppStore } from './AppStore';

const authConfig = {
  authority: process.env.NODE_ENV === 'production' ? 'https://auth.nelsonirrigation.com' : 'https://services.nelsonirrigation.com/identity',     // identity server authority
  client_id: 'twigreporting_client',                                // client
  client_secret: 'twigreportingsecret',                             // client secret
  redirect_uri: process.env.NODE_ENV === 'production' ? 'https://reporting.twigwirelesscontrols.com/#/callback' : 'http://localhost:8946/#/callback',
  scope: 'twig_reporting'									// the scope that will be requested at login. This is the scope that is required by the api
};
const ENDPOINT_DISCOVERY = '/.well-known/openid-configuration';
const ENDPOINT_GETTOKENUSER = '/api/identity/user';
// const ENDPOINT_GETSUBSCRIPTIONS = "/api/identity/subscriptions";
const ENDPOINT_SETPASSWORD = '/api/identity/password';
// const ENDPOINT_GETUSERPROPERTIES = "/api/identity/userproperties";
const ENDPOINT_SETUSERPROPERTY = '/api/identity/userproperty';
const LOCALSTORAGE_AUTH_DISCOVERY = 'auth_discovery';
const LOCALSTORAGE_TOKENS = 'auth_tokens';
const handleCallbackAutomatically = true;

export const useIdentityStore = defineStore('identity', {
    state () {
        return {
            User: null,
            config: authConfig,
            discovery: null,
            isTos: false
        };
      },
  actions: {
    setLocalStorageObject (key, obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    },
    getLocalStorageObject (key) {
      var str = localStorage.getItem(key);
      if (str === null) { return null; }
      return JSON.parse(str);
    },
    deleteLocalStorageObject (key) {
      localStorage.removeItem(key);
    },
    __getParameterByName (name, url = window.location.href) {
      name = name.replace(/[[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) { return null; }
      if (!results[2]) { return ''; }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    __ObjectToEncodedParams (obj) {
      var queryString = Object.keys(obj).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
      }).join('&');
      return queryString;
    },
    access_token () {
      let tokens = this.tokens();
      if (tokens) {
        return tokens.access_token;
      }
      return null;
    },
    refresh_token () {
      let tokens = this.tokens();
      if (tokens) {
        return tokens.refresh_token;
      }
      return null;
    },
    signout () {
      localStorage.removeItem('auth_tokens');
      localStorage.removeItem('user');
      this.ssoAuthenticate();
    },
    tokens () {
      return this.getLocalStorageObject(LOCALSTORAGE_TOKENS);
    },
    loadDiscoveryDocument () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          url: self.config.authority + ENDPOINT_DISCOVERY,
          success: function (result) {
            self.discovery = result;
            if (success) {
              self.setLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY, result);
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    ssoAuthenticate () {
      // redirect to the authorize endpoint
      var authParams = {
        redirect_uri: this.config.redirect_uri,
        client_id: this.config.client_id,
        client_secret: this.config.client_secret,
        scope: this.config.scope,
        response_type: 'code',
        // UsePkce : true
      };
      var queryString = this.__ObjectToEncodedParams(authParams);
      window.location.href = this.discovery.authorization_endpoint + '?' + queryString;
    },
    verifyDiscoveryDocument () {
      var self = this;
      let p = new Promise(function (success, fail) {
        if (!self.discovery) {
          var dsc = self.getLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY);
          if (dsc) {
            if (!dsc.issuer || dsc.issuer.toLowerCase().indexOf(self.config.authority.toLowerCase()) < 0) {
              // authority changed
              self.deleteLocalStorageObject(LOCALSTORAGE_AUTH_DISCOVERY);
              self.verifyDiscoveryDocument();
            }
            self.discovery = dsc;
            success(self.discovery);
          }
          else {
            self.loadDiscoveryDocument().then(success, fail);
          }
        }
        else {
          if (success) {
            success(self.discovery);
          }
        }
      });
      return p;
    },
    ssoRedeemAuthorizationCode (code) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                client_id: self.config.client_id,
                client_secret: self.config.client_secret,
                grant_type: 'authorization_code',
                code: code,
                scope: self.config.scope + ' ' + 'offline_access',
                redirect_uri: self.config.redirect_uri
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                url: self.discovery.token_endpoint,
                data: queryString,
                success: function (result) {
                  self.setLocalStorageObject(LOCALSTORAGE_TOKENS, result);
                  if (success) {
                    success(result);
                  }
                },
                error: function (xhr, status, errorThrown) {
                  try {
                    fail(errorThrown);
                  } catch {
                    alert('error redeeming authorization code');
                  }
                }
              });
            },
            function () {
              alert('unable to load SSO discovery document');
              fail();
            }
          );
      });
      return p;
    },
    ssoRefreshToken (refresh) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                client_id: self.config.client_id,
                client_secret: self.config.client_secret,
                grant_type: 'refresh_token',
                refresh_token: refresh
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                url: self.discovery.token_endpoint,
                data: queryString,
                success: function (result) {
                  self.setLocalStorageObject(LOCALSTORAGE_TOKENS, result);
                  
                  if (success) {
                    success(result);
                  }
                },
                error: fail
              });
            },
            function () {
              alert('failed to retrieve SSO discovery document');
              fail();
            }
          );
      });
      return p;
    },
    parseAuthorizationCodeResponse () {
      return this.__getParameterByName('code');
    },
    init () {
      var self = this;
      this.verifyDiscoveryDocument().then(() => {
      });
      if (handleCallbackAutomatically) {
        self.handleCallbackResponse();
      }
    },
    handleCallbackResponse () {
      var self = this;
      if (window.location.href.toLowerCase().indexOf(self.config.redirect_uri.toLowerCase()) >= 0) {
        var authCode = self.parseAuthorizationCodeResponse();
        if (authCode) {
          // now request an access token
          self.ssoRedeemAuthorizationCode(authCode).then(
            function () {
              // got a token
              // use access_token for API calls.
              // when this token expires, use refresh_token to request a new access token
              self.$router.push({ name: 'hybrid' });
            },
            function (errResponse) {
              alert('failed to redeem authorization code: ' + errResponse);
              // window.location.href = "/"; // TODO! route to access denied view
            }
          );
        }
        else {
          alert('no token was received');
        }
      }
    },
    // verify a token with introspection endpoint. 
    inspectToken (access_token) {
      var self = this;
      let p = new Promise(function (success, fail) {
        self.verifyDiscoveryDocument()
          .then(
            function () {
              var params = {
                token: access_token
              };
              // Turn the data object into an array of URL-encoded key/value pairs.
              var queryString = self.__ObjectToEncodedParams(params);
              $.ajax({
                type: 'POST',
                headers: { 'Authorization': 'Basic ' + btoa(self.config.scope + ':' + self.config.client_secret) },
                url: self.discovery.introspection_endpoint,
                data: queryString,
                success: success,
                error: fail
              });
            },
            function () {
              fail();
            }
          );
      });
      return p;
    },
        /*  sample output from the introspect endpoint
         {
        "nbf": 1674094379,
        "exp": 1674097979,
        "iss": "https://localhost:7012",
        "aud": "OverlapApi",
        "client_id": "overlap_client",
        "sub": "bd507828-9e6f-4312-a5d0-5a5003517d15",
        "auth_time": 1674094317,
        "idp": "local",
        "role": "admin",
        "name": "Dan Anderson",
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": "bd507828-9e6f-4312-a5d0-5a5003517d15",
        "email": "daniel@acclima.com",
        "orgid": "adab0464-985d-4df2-b7bc-08e75326c6e8",
        "initials": "DA",
        "grant_type": "password",
        "user_data":"{\"pwdhash\":\"pTdsDTv7EO3Xip/u7xV8\+PczPX4=\",\"orgname\":\"Nelson Irrigation Corporation Australia\",\"orgabbrev\":\"nica\"}",
        "user_props": "{\"Overlap_Units\":\"0:0:0:0:0:0:0:0\"}",
        "jti": "94EC4DAC47B8BCBB590D00AC55B10420",
        "iat": 1674094379,
        "active": true,
        "scope": "Overlap"
            }
         */
    isTokenValid (access_token) {
      var self = this;
      return new Promise(function (success, fail) {
        if (!access_token) {
          access_token = self.access_token();
        }
        return self.inspectToken(access_token)
          .then((t) => {
            success(t.active);
          }, () => {
            fail(false);
          });
      });
    },
    getTokenUser () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          headers: { 'Authorization': 'Bearer ' + self.access_token() },
          url: self.config.authority + ENDPOINT_GETTOKENUSER,
          success: function (result) {
            self.User = result;
            self.setLocalStorageObject('user', result);
            // duplicate from router index
           /*  if (self.User.properties.twig_reporting_hasAgreed === undefined) {
              const modalStore = useModalStore();
              modalStore.showModal('tos');
            } */
            if (success) {
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    getUserSubscriptions () {
      // TODO!
      // GET this.config.authority + ENDPOINT_GETSUBSCRIPTIONS,
    },
    setUserPassword (newPassword) {
      var self = this;
      $.ajax({
        type: 'POST',
        headers: { 'Authorization': 'Bearer ' + self.access_token() },
        url: self.config.authority + ENDPOINT_SETPASSWORD,
        data: JSON.stringify({
          sendEmail:true,
          bccEmail: '',
          password: newPassword,

        }),
        contentType: 'application/json',
        success: function (data) {
          console.log(data);
          self.signout();
        },
        error: function (error) {
          console.log(`Error ${error}`);
        }
      });
    },
    getUserProperties () {
      var self = this;
      let p = new Promise(function (success, fail) {
        $.ajax({
          type: 'GET',
          headers: { 'Authorization': 'Bearer ' + self.access_token() },
          url: self.config.authority + ENDPOINT_GETTOKENUSER,
          success: function (result) {
            self.User = result;
            const settingsStore = useSettingsStore();
            settingsStore.restoreUnits();
            self.setLocalStorageObject('user', result);
            if (success) {
              success(result);
            }
          },
          error: fail
        });
      });
      return p;
    },
    setUserProperty (property) {
      var self = this;
      $.ajax({
        type: 'POST',
        headers: { 'Authorization': 'Bearer ' + self.access_token() },
        url: self.config.authority + ENDPOINT_SETUSERPROPERTY,
        data: JSON.stringify(property),
        contentType: 'application/json',
        success: function (data) {
          console.log(data);
          if (self.isTos) {
            const modalStore = useModalStore();
            const appStore = useAppStore();
            const mcStore = useMCStore();
            if (mcStore.twig_mcs.length === 0) {
              modalStore.setModalObj({
                redeemHeader: 'Redeem MC Invitation',
                redeemLabel: 'Voucher:',
              });
              modalStore.showModal('redeem');
              appStore.isLoading = false;
              self.isTos = false;
            } else {
              modalStore.hideModal();
              appStore.isLoading = false;
            }
          }
        self.getUserProperties();
        },
        error: function (error) {
          console.log(`Error ${error}`);
        }
      });
    },
}
});
