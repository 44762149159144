import { defineStore } from 'pinia';
import { useIdentityStore } from '@/stores/IdentityStore';

export const useSettingsStore = defineStore('settings', {
	state () {
		return {
			settings_overlay: false,
			lengthIndex: 0,
			pressureIndex: 0,
			flowIndex: 0,
			rateIndex: 0,
			areaIndex: 0,
		};
	},
	setup () {
		const identityStore = useIdentityStore();
		return {
			identityStore
		};
	},
	getters: {
		lengthUnit () {
			return this.lengthOptions[this.lengthIndex];
		},
		pressureUnit () {
			return this.pressureOptions[this.pressureIndex];
		},
		flowUnit () {
			return this.flowOptions[this.flowIndex];
		},
		rateUnit () {
			return this.rateOptions[this.rateIndex];
		},
		areaUnit () {
			return this.areaOptions[this.areaIndex];
		},
		lengthOptions () {
			return ['ft', 'm'];
		},
		pressureOptions () {
			return ['psi', 'bar'];
		},
		flowOptions () {
			return ['gal/min', 'liters/min', 'liters/sec', 'm3/h'];
		},
		rateOptions () {
			return ['in/hr', 'mm/hr'];
		},
		areaOptions () {
			return ['acres', 'hectares', 'm2'];
		},
		units () {
			return this.lengthIndex + ':' + this.pressureIndex + ':' + this.flowIndex + ':' + this.rateIndex + ':' + this.areaIndex;
		}
	},
	actions: {
		setLengthUnit (value) {
			this.lengthIndex = this.lengthOptions.indexOf(value);
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ Units: this.units });
		},
		setPressureUnit (value) {
			this.pressureIndex = this.pressureOptions.indexOf(value);
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ Units: this.units });
		},
		setFlowUnit (value) {
			this.flowIndex = this.flowOptions.indexOf(value);
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ Units: this.units });
		},
		setRateUnit (value) {
			this.rateIndex = this.rateOptions.indexOf(value);
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ Units: this.units });
		},
		setAreaUnit (value) {
			this.areaIndex = this.areaOptions.indexOf(value);
			const identityStore = useIdentityStore();
			identityStore.setUserProperty({ Units: this.units });
		},
		restoreUnits () {
			const identityStore = useIdentityStore();
			var storedUnits = identityStore.User.properties.twig_reporting_Units;
			if (storedUnits == null) {
				identityStore.setUserProperty({ Units: this.units });
				return;
			}
			var units = storedUnits.split(':');
			this.lengthIndex = parseInt(units[0]);
			this.pressureIndex = parseInt(units[1]);
			this.flowIndex = parseInt(units[2]);
			this.rateIndex = parseInt(units[3]);
			this.areaIndex = parseInt(units[4]);
		},
	},
});
