<template>
  <div class="redeem">
    <div class="redeem-content">
      <h3 class="redeem-title">{{ this.modalStore.modalObj.redeemHeader }}</h3>
      <div>
        <label>{{ this.modalStore.modalObj.redeemLabel }}</label>
      </div>
      <textarea class="redeem-input" @click="handleInput" :value="this.redeemToken" @blur="this.redeemToken = $event.target.value"></textarea>
      <div class="button-holder">
        <v-btn @click="this.redeemMC" variant="outlined" style="width:100px;margin-left:15px;"
          class="settingsBtn"><span>Redeem</span>
          <div class="progress-circle"><v-progress-circular v-if="appStore.isRedeeming" :size="15" indeterminate
              color="white"></v-progress-circular></div>
        </v-btn>
      </div>
      <div style="color: red;" v-if="mcStore.isInvalidToken"><p>Invalid Token! Please type in a valid token.</p></div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/AppStore';
import { useMCStore } from '@/stores/MCStore';
import { useModalStore } from '@/stores/ModalStore';


export default {
  name: 'ModalRedeem',
  setup () {
    const modalStore = useModalStore();
    const appStore = useAppStore();
    const mcStore = useMCStore();
    return {
      modalStore,
      appStore,
      mcStore
    };
  },
  data () {
    return {
      redeemToken: '',
      isLoading: false,
    };
  },
  props: [
    'obj'
  ],
  methods: {
    handleInput () {
      this.mcStore.isInvalidToken = false;
    },
    redeemMC () {
      this.appStore.isRedeeming = true;
      this.mcStore.redeemMC(this.redeemToken);
      
    },
  }
};
</script>

<style scoped>
.redeem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  padding: 25px 15px 15px 15px;
  background-color: white;
}

.button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 5px 0;
}

.settingsBtn {
  padding: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: var(--nelson-green);
  color: white;
}

.redeem-title {
  /* font-weight: bold; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.redeem-input {
  width: 300px;
  border: 1px solid black;
}
</style>