import * as d3 from 'd3';
import dayjs from 'dayjs';

// sets colors for the specific parts of the graph
const gridColor = '#E8E8E8';
const hoursColor = '#5D5AD8';
const precipitationColor = '#849D7F';
const bottomLinesColor = 'grey';
const bottomBoxColor = '#F3F3F3';
const programTextColor = 'black';
const pillTextColor = '#ffffff';

// Sets line positions
const regColorLine = 25;
const regEvenLine = 45;
const regOddLine = 20;

// program lollypop
const regLollypopCy = 25;
const regLollypopRadius = 5;

// // program pill
const regPill = 10;
const tallPill = 45;
const textPillEven = 58;
const textPillOdd = 23;

// program line positions
const regProgramLine = 97.5;
const regBlankBox = 90;
const regProgramText = 102;
const regColorCircle = 97.5;
const regColorCircleRadius = 4;
const begProgramEndTicks = 90;
const endProgramEndTicks = 105;

// grid lines
const reglineToEndY1 = 105;
const reglineToEndY2 = 150;
const reglineToProgramY2 = 90;

// Function draws left graph axis
export const drawLeftAxis = (svg, height, maxTimeValues) => {
	// Creates left y scale
	const increment = height / 9;
	// Appends left axis to the graph svg
	const g = svg.append('g');
	g
		.append('line')
		.attr('y1', 0)
		.attr('y2', height)
		.style('stroke', hoursColor)
		.attr('class', 'axisSteelBlue');

		maxTimeValues.sort((a,b)=>b-a);
		maxTimeValues.forEach((value, index) => {
			g
				.append('line')
				.attr('y1', index * increment)
				.attr('y2', index * increment)
				.attr('x1', 0)
				.attr('x2', 10)
				.style('stroke', hoursColor)
				.attr('class', 'axisGrey');
			g
				.append('text')
				.text(value)
				.attr('x', -4)
				.attr('y', (index * increment) + 3.5)
				.attr('text-anchor', 'end')
				.style('stroke', hoursColor)
				.attr('class', 'axisGrey')
				.style('font-size', '12px');
		});
	// Appends Hours text to the graph
	g
		.append('text')
		.attr('text-anchor', 'end')
		.attr('transform', 'rotate(0)')
		.attr('y', height + 25)
		.attr('x', 15)
		.attr('class', 'axisSteelBlue')
		.text('Hours')
		.style('font-size', 'small')
		.style('fill', hoursColor);
};

// draws the right axis of the graph
export const drawRightAxis = (svg, height, width, maxAppliedValues) => {
	// Create right y scale
	const increment = height / 9;
	// Appends right y axis
	const g = svg.append('g');
	g
		.append('line')
		.attr('y1', 0)
		.attr('y2', height)
		.style('color', precipitationColor)
		.style('stroke', precipitationColor)
		.attr('class', 'axisGrey')
		.attr('transform', 'translate( ' + width + ', 0 )')
		.style('stroke', precipitationColor);
		maxAppliedValues.sort((a,b)=>b-a);
		maxAppliedValues.forEach((value, index) => {
		g
			.append('line')
			.attr('y1', index * increment)
			.attr('y2', index * increment)
			.attr('x1', 0)
			.attr('x2', -10)
			.style('stroke', precipitationColor)
			.attr('class', 'axisGrey')
			.attr('transform', 'translate( ' + width + ', 0 )');
		g
			.append('text')
			.text(value)
			.attr('x', 4)
			.attr('y', (index * increment) + 3.5)
			.attr('text-anchor', 'start')
			.style('stroke', precipitationColor)
			.attr('class', 'axisGrey')
			.style('font-size', '12px')
			.attr('transform', 'translate( ' + width + ', 0 )');
	});
	// Appends Inches label to right y axis
	g
		.append('text')
		.attr('text-anchor', 'end')
		.attr('transform', 'rotate(0)')
		.attr('y', height + 25)
		.attr('x', width + 15)
		.attr('class', 'axisGrey')
		.text('Depth')
		.style('font-size', 'small')
		.style('fill', precipitationColor);
};

// draws the x axis of the graph
export const drawXAxis = (svg, width, height, allGroup) => {
	// Creates x axis scale
	let x = d3
		.scaleBand()
		.range([25, width - 25])
		.domain(allGroup)
		.padding([0.2]);

	// Add the X Axis
	svg
		.append('g')
		.attr('transform', 'translate(0,' + height + ')')
		.call(d3.axisBottom(x).tickSizeInner([0]).tickSizeOuter([0]).ticks())
		.selectAll('text')
		.remove();

	return x;
};

// draws the grid of the graph
export const drawGrid = (
	svg,
	x,
	height,
	width,
	valveInfo
) => {
	const heightIncrement = height / 9;
	const widthIncrement = 	x.step();
	const g = svg.append('g');
	const eventCount = valveInfo.length;
	const calcXLength = (eventCount - 1) * widthIncrement;
	const paddingResults = (width - calcXLength) / 2;

	g
		.append('line')
		.attr('y1', -heightIncrement + 25)
		.attr('y2', -heightIncrement + 25)
		.attr('x1', -50)
		.attr('x2', width + 50)
		.attr('stroke', gridColor);

	for (let i = 0; i < 9; i++) {
		if( i != 0) {
			g
			.append('line')
			.attr('y1', heightIncrement * i)
			.attr('y2', heightIncrement * i)
			.attr('x1', 25)
			.attr('x2', width - 25)
			.attr('stroke', gridColor);
		}
	}
	for (let i = 0; i < (eventCount); i++) {
		g
			.append('line')
			.attr('x1', (widthIncrement * i) + paddingResults)
			.attr('x2', (widthIncrement * i) + paddingResults)
			.attr('y1',	-20)
			.attr('y2', height)
			.attr('stroke', gridColor);
	}
};

export const drawBottom = (
	svg,
	dataCount,
	valveInfo,
	countsExtended,
	totalExtended,
	isByProgram,
	valveCount,
	x
) => {
	// This function finds the Program text width
	const textSize = (text) => {
		if (!d3) {return;}
		var container = d3.select('body').append('svg');
		container.append('text').text(text);
		var size = container.node().getBBox();
		const sizes = [];
		sizes.push(size.width);
		sizes.push(size.height);
		container.remove();
		return sizes;
	};
	// Finds the text pill size based on the width of the text
	const textPillSize = [];
	valveInfo.forEach((element) => {
		const getTextSize = textSize(element.group);
		const textWidth = getTextSize[0];
		if (textWidth > 130) {
			textPillSize.push({ width: 85, height: getTextSize[1] });
		} else if (textWidth > 100) {
			textPillSize.push({ width: 100, height: getTextSize[1] });
		} else {
			textPillSize.push({ width: textWidth, height: getTextSize[1] });
		}
	});
	// Determines the amount of pills until lollypops on the type of graph
	let queryDisplay = valveCount === 1 ? 50 : 25;

	// Grabs the width of the program text
	let valveText = [];
	valveInfo.forEach((e, i) => {
		valveText.push({
			id: i,
			program: e.program,
			valveName:  e.group,
			width: textSize(e.group)[0],
			height: textSize(e.group)[1],
		});
	});
	// Is a function that determines where the pills, lines, texts need to be placed at the bottom of graph
	const getHeight = (index) => {
		const findHeight = valveText[index].width / 130;
		let height = Math.ceil(findHeight) * 20;
		// quick fix to have pills collide to graph
		if (height > 40) {
			height = 40;
		}
		const yPosition = 20 - (height - 10);
		let textHeight = height === 40 ? 24: 12;
		let textTranslation;
		let topLine;
		if (index % 2 == 0) {
			textTranslation = (height - textHeight) / 2 + tallPill;
			topLine = height + tallPill;
		} else {
			textTranslation = (height - textHeight) / 2 + regPill;
			topLine = height + regPill;
		}
		return [height, yPosition, textTranslation, topLine];
	};
	// Selects all x axis ticks on the graph
	let ticks = svg.selectAll('.tick');
	// Gets the width between ticks
	let tickWidth = x.step();

	let startingProgramPoints = [];
	startingProgramPoints.push(0);

	ticks.each(function (name, index) {
		// This section finds the program starting points
		if (index != 0) {
			let er = index - 1;
			if (valveInfo[index].program != valveInfo[er].program) {
				let halfValue = index;
				startingProgramPoints.push(halfValue);
			}
		}
		if (index % 2 == 0) {
			d3.select(this).attr('id', 'evenGroup');
			d3.select(this)
				.append('line')
				.attr('id', 'programLine')
				.style('stroke', valveInfo[index].programColor);
			d3.select(this)
				.append('circle')
				.attr('id', 'programLollypop')
				.attr('fill', valveInfo[index].programColor);
			d3.select(this).append('line').attr('id', 'lineToProgram');
			d3.select(this).append('line').attr('id', 'lineToEnd');
			if (dataCount <= queryDisplay) {
				d3.select(this)
					.append('rect')
					.attr('id', 'programPills')
					.attr('x', -(textPillSize[index].width / 2))
					.attr('width', textPillSize[index].width)
					.attr('height', getHeight(index)[0])
					.attr('fill', getProgramColor(index, valveInfo));
				d3.select(this).append('text').attr('id', 'itemText').text(valveInfo[index].group);
				d3.select(this)
					.append('line')
					.attr('id', 'lineToProgramPills')
					.attr('y1', getHeight(index)[3]);
			}
		} else {
			d3.select(this).attr('id', 'oddGroup');
			d3.select(this)
				.append('line')
				.attr('id', 'programLine')
				.style('stroke', valveInfo[index].programColor);
			d3.select(this)
				.append('circle')
				.attr('id', 'programLollypop')
				.attr('fill', valveInfo[index].programColor);
			d3.select(this).append('line').attr('id', 'lineToProgram');
			d3.select(this).append('line').attr('id', 'lineToEnd');
			if (dataCount <= queryDisplay) {
				d3.select(this)
					.append('rect')
					.attr('id', 'programPills')
					.attr('x', -(textPillSize[index].width / 2))
					.attr('width', textPillSize[index].width)
					.attr('height', getHeight(index)[0])
					.attr('fill', getProgramColor(index, valveInfo));
				d3.select(this).append('text').attr('id', 'itemText').text(valveInfo[index].group);
				d3.select(this)
					.append('line')
					.attr('id', 'lineToProgramPills')
					.attr('y1', getHeight(index)[3]);
			}
		}
	});
	if (dataCount <= queryDisplay) {
		// even group with pills
		d3.selectAll('#evenGroup')
			.selectAll('#programLine')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 0)
			.attr('y2', regEvenLine);
		d3.selectAll('#evenGroup')
			.selectAll('#programPills')
			.attr('y', tallPill)
			.attr('stroke-width', 0.1)
			.attr('ry', 12)
			.attr('rx', 12);
		d3.selectAll('#evenGroup')
			.selectAll('#itemText')
			.style('fill', pillTextColor)
			.attr('transform', `translate(0, ${textPillEven})`)
			.call(wrap, 80);
		d3.selectAll('#evenGroup')
			.selectAll('#lineToProgramPills')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y2', reglineToProgramY2)
			.attr('stroke', bottomLinesColor);
		d3.selectAll('#evenGroup')
			.selectAll('#lineToEnd')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', reglineToEndY1)
			.attr('y2', reglineToEndY2)
			.attr('stroke', bottomLinesColor);
		// odd group with pills
		d3.selectAll('#oddGroup')
			.selectAll('#programPills')
			.attr('stroke-width', 0.1)
			.attr('y', regPill)
			.attr('ry', 12)
			.attr('rx', 12);
		d3.selectAll('#oddGroup')
			.selectAll('#programLine')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 0)
			.attr('y2', regOddLine);
		d3.selectAll('#oddGroup')
			.selectAll('#itemText')
			.style('fill', pillTextColor)
			.attr('transform', `translate(0, ${textPillOdd})`)
			.call(wrap, 80);
		d3.selectAll('#oddGroup')
			.selectAll('#lineToProgramPills')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y2', reglineToProgramY2)
			.attr('stroke', bottomLinesColor);
		d3.selectAll('#oddGroup')
			.selectAll('#lineToEnd')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', reglineToEndY1)
			.attr('y2', reglineToEndY2)
			.attr('stroke', bottomLinesColor);
	} else {
		// even group without pills
		d3.selectAll('#evenGroup')
			.selectAll('#programLine')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 0)
			.attr('y2', regColorLine);
		d3.selectAll('#evenGroup')
			.selectAll('#programLollypop')
			.attr('cy', regLollypopCy)
			.attr('r', regLollypopRadius);
		d3.selectAll('#evenGroup')
			.selectAll('#lineToProgram')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 30)
			.attr('y2', reglineToProgramY2)
			.attr('stroke', bottomLinesColor);
		d3.selectAll('#evenGroup')
			.selectAll('#lineToEnd')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', reglineToEndY1)
			.attr('y2', reglineToEndY2)
			.attr('stroke', bottomLinesColor);
		// odd group without pills
		d3.selectAll('#oddGroup')
			.selectAll('#programLollypop')
			.attr('cy', 25)
			.attr('r', 5);
		d3.selectAll('#oddGroup')
			.selectAll('#programLine')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 0)
			.attr('y2', regColorLine);
		d3.selectAll('#oddGroup')
			.selectAll('#lineToProgram')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 30)
			.attr('y2', reglineToProgramY2)
			.attr('stroke', bottomLinesColor);
		d3.selectAll('#oddGroup')
			.selectAll('#lineToEnd')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', reglineToEndY1)
			.attr('y2', reglineToEndY2)
			.attr('stroke', bottomLinesColor);
	}
	// Finds the middle point of the the program and puts it into an array
	let middleProgramPoints = [];
	startingProgramPoints.forEach((value, index) => {
		const number = countsExtended[index].count;
		const addValues = value + number - 1;
		middleProgramPoints.push(addValues);
	});
	// Grabs the width of the program text
	let textWidth = [];
	startingProgramPoints.forEach((value, index) => {
		textWidth.push({
			id: index,
			program: valveInfo[value].program,
			width: textSize(valveInfo[value].program)[0],
			widthAdded: textSize(valveInfo[value].program)[0] + 20,
		});
	});
	// This array gets where the Endpoints are needed.
	let programEndpoints = [];
	startingProgramPoints.forEach((element) => {
		startingProgramPoints.push(element);
		if (element != 0) {
			const numMinus = element - 1;
			programEndpoints.push({
				programName: valveInfo[numMinus].program,
				value: numMinus,
			});
			programEndpoints.push({
				programName: valveInfo[element].program,
				value: element,
			});
		} else {
			programEndpoints.push({
				programName: valveInfo[element].program,
				value: element,
			});
		}
	});
	programEndpoints.push({
		programName: valveInfo[dataCount - 1].program,
		value: dataCount - 1,
	});
	// Grabs the width of the program
	const programTickWidth = [];
	totalExtended.forEach((element) => {
		const counted = element.count - 1;
		const programTickLength = counted * tickWidth;
		programTickWidth.push(programTickLength);
	});
	// removes duplcates in startingProgramPoints array
	const startingProgramTickCount = programTickWidth.length;
	for (let i = 0; i < startingProgramTickCount; i++) {
		startingProgramPoints.pop();
	}
	if (isByProgram === true || valveCount === 1 || valveInfo[0].program != null) {
		startingProgramPoints.forEach((element, index) => {
			// if (index % 2 == 0) {
			let selectRectangle = d3
				.selectAll('g.tick')
				.filter(function (valve, index) {
					return index == element;
				});
			selectRectangle
				.append('rect')
				.attr('id', 'programBlankBox')
				.attr('width', programTickWidth[index]);
			let selectTick = d3.selectAll('g.tick').filter(function (value, index) {
				return index == element;
			});
			selectTick
				.append('line')
				.attr('id', 'programLineBracket')
				.attr('x2', programTickWidth[index])
				.attr('stroke', getProgramColor(element, valveInfo));
		});
		d3.selectAll('g.tick')
			.select('#programBlankBox')
			.attr('x', 0)
			.attr('y', regBlankBox)
			.attr('height', 15)
			.attr('fill', bottomBoxColor);
		d3.selectAll('g.tick')
			.select('#programLineBracket')
			.attr('x1', 0)
			.attr('y1', regProgramLine)
			.attr('y2', regProgramLine);
		middleProgramPoints.forEach((element, index) => {
			const programNumValves = totalExtended[index].count;
			const selectProgramTextHide = d3
				.selectAll('g.tick')
				.filter(function (d, index) {
					return index == element;
				});			
			selectProgramTextHide.append('text').text(getProgramFormat(valveCount, valveInfo[element].program)).attr('transform', `translate(${tickWidth / 2}, ${regProgramText})`).style('fill', getProgramColor(element, valveInfo)).attr('class', 'ifProgramText').style('font-size', '12px');
			var textElement = selectProgramTextHide.select('.ifProgramText');
			let textComputed = textElement.node().getComputedTextLength();
			let textLength = textComputed + 16;
			selectProgramTextHide.selectAll('.ifProgramText').style('opacity', 0);
			if (textLength < programTickWidth[index]) {
				// if (index % 2 == 0) {
				d3.selectAll('g.tick')
					.filter(function (value, index) {
						return index == element;
					})
					.append('rect')
					.attr('id', 'programTextBoxSpace')
					.attr(
						'x',
						getProgramBoxPlacement(programNumValves, textComputed, tickWidth)
					)
					.attr('width', textComputed + 16);
				// program name
				const selectProgramText = d3
					.selectAll('g.tick')
					.filter(function (value, index) {
						return index == element;
					});
				selectProgramText
					.append('text')
					.attr('id', 'programText')
					.text(getProgramFormat(valveCount, valveInfo[element].program))
					.attr(
						'transform',
						`translate(${getProgramTextPlacement(
							programNumValves,
							tickWidth
						)}, ${regProgramText})`
					);
				// cirlce next to the program word
				let selectCircle = d3
					.selectAll('g.tick')
					.filter(function (value, index) {
						return index == element;
					});
				selectCircle
					.append('circle')
					.attr('id', 'programCircle')
					.attr(
						'cx',
						getProgramCirclePlacement(programNumValves, textComputed, tickWidth)
					)
					.attr('fill', getProgramColor(element, valveInfo));
			}
		});
		d3.selectAll('g.tick')
			.selectAll('#programTextBoxSpace')
			.attr('y', regBlankBox)
			.attr('height', 15)
			.attr('fill', bottomBoxColor);
		d3.selectAll('g.tick')
			.selectAll('#programText')
			.attr('fill', programTextColor)
			.style('font-size', '12px');
		if (valveCount > 1) {
			d3.selectAll('g.tick')
				.selectAll('#programCircle')
				.attr('cy', regColorCircle)
				.attr('r', regColorCircleRadius);
		}
	} 
	const programEndpointLines = [];
	programEndpoints.forEach((element) => {
		programEndpointLines.push(element.value);
	});
	const startingProgramPointIndex = [];
	startingProgramPoints.forEach((value, index) => {
		startingProgramPointIndex.push(index);
	});
	let endpoints = startingProgramPointIndex;
	startingProgramPointIndex.forEach((element) => {
		endpoints.push(element);
	});
	// sorts the dates in order
	endpoints.sort(function (a, b) {
		return a - b;
	});
	if (isByProgram === true || valveCount === 1 || valveInfo[0].program != null) {
		endpoints.forEach((element, index) => {
			const endpointColorIndex = programEndpoints[index].value;
			let selectEndTick = d3
				.selectAll('g.tick')
				.filter(function (value, index) {
					return index == endpointColorIndex;
				});
			selectEndTick
				.append('line')
				.attr('id', 'programEndTicks')
				.attr('stroke', getProgramColor(endpointColorIndex, valveInfo));
		});
		d3.selectAll('g.tick')
			.selectAll('#programEndTicks')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', begProgramEndTicks)
			.attr('y2', endProgramEndTicks)
			.attr('stroke-width', '2');
	} else {
		d3.selectAll('g.tick')
			.append('line')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', 90)
			.attr('y2', 105)
			.attr('stroke', bottomLinesColor);
	}
};

// Draws bars on the graph
export const drawBars = (
	svg,
	valveInfo,
	subgroups,
	height,
	mouseover,
	mousemove,
	mouseleave,
	x,
	maxTimeValues,
	maxAppliedValues
) => {
	// color palette = one color per subgroup
	const color = d3
		.scaleOrdinal()
		.domain(subgroups)
		.range(['#5D5AD8', '#A1B5FF', '#5A6154', '#849D7F']);

	svg
		.append('g')
		.attr('id', 'graph')
		.selectAll('g')
		.data(valveInfo)
		.join('g')
		.attr('transform', (d) => `translate(${x(d.index)}, 0)`)
		.attr('id', (d) => {
			const noSpecialChars = d.group.replace(/([^\w ]|_)/g, '');
			const allSpacesRemoved = noSpecialChars.replaceAll(' ', '');
			return 'group' + allSpacesRemoved;
		})
		.on('mouseover', mouseover)
		.on('mousemove', mousemove)
		.on('mouseleave', mouseleave)
		.attr('width', 'auto')
		.selectAll('rect')
		.data(function (d) {
			const revisedData = [];
			let subgroup;
			if (
				+d.verifiedHour == 0 &&
				+d.verifiedPrecipitation == 0 &&
				+d.hoursRan == 0 &&
				+d.precipitation == 0
			) {
				subgroup = [
					'verifiedHour',
					'hoursRan',
					'verifiedPrecipitation',
					'precipitation',
				];
			} else if (+d.verifiedHour == 0 && +d.verifiedPrecipitation == 0) {
				delete d.verifiedHour;
				delete d.verifiedPrecipitation;
				subgroup = ['verifiedHour', 'hoursRan', 'precipitation', 'verifiedPrecipitation'];
			} else if (+d.hoursRan == 0 && +d.precipitation == 0) {
				delete d.hoursRan;
				delete d.precipitation;
				subgroup = ['hoursRan', 'verifiedHour', 'verifiedPrecipitation', 'precipitation'];
			} else {
				subgroup = [
					'verifiedHour',
					'hoursRan',
					'verifiedPrecipitation',
					'precipitation',
				];
			}
			subgroup.map(function (key) {
				revisedData.push({ key: key, value: d[key], subgroup: subgroup, group: d.group, });
			});
			return revisedData;
		})
		.join('rect')
		.attr('class', function (d) {
			return d.key;
		})
		.attr('x', (d) => {
			const xSub = xSubgroup(d.subgroup, x);
			return xSub(d.key);
		})
		.attr('y', function (d) {
			if(d.value === undefined) {return 0;}
			if (d.key == 'verifiedPrecipitation' || d.key == 'precipitation') {
				if (d.value == 0.00) {return 0;}
				else {
					return height - (d.value / maxAppliedValues[0] * height);
				}
			} else {
				if (d.value == 0.00) {return 0;} 
				else {
					return height - (d.value / maxTimeValues[0] * height);
				}
			}
		})
		.attr('width', function (d) {
			const xSub = xSubgroup(d.subgroup, x);
			return xSub.bandwidth();
		})
	.attr('height', function (d) {
		if(d.value === undefined) {return 0;}
		if (d.key == 'verifiedPrecipitation' || d.key == 'precipitation') {
			if (d.value == 0.00) {return 0;}
			else {
				return (d.value / maxAppliedValues[0] * height);
			}
		} else {
			if (d.value == 0.00) {return 0;}
			else {
				return (d.value / maxTimeValues[0] * height);
			}
		}
	})
	.attr('fill', (d) => {
		return color(d.key);
	})
	.attr('opacity', 1);
	return svg;
};

export const wrap = (text, width) => {
	text.each(function () {
		let text = d3.select(this),
			words = text.text().split(/\s+/).reverse(),
			word,
			line = [],
			lineHeight = 1.1,
			y = text.attr('y'),
			dy = text.attr('dy'),
			tspan = text
				.text(null)
				.append('tspan')
				.attr('x', 0)
				.attr('y', y)
				.attr('dy', dy);
		while ((word = words.pop())) {
			line.push(word);
			tspan.text(line.join(' '));
			if (tspan.node().getComputedTextLength() > width) {
				line.pop();
				tspan.text(line.join(' '));
				line = [word];
				tspan = text
					.append('tspan')
					.attr('x', 0)
					.attr('y', y)
					.attr('dy', lineHeight + 'em')
					.text(word);
			}
		}
	});
};

// Reusable draw line function is for the graph - (selected, x1, x2, y1, y2, color)
export const drawLine = (selected, x1, x2, y1, y2, color, strokeWidth) => {
	selected
		.append('line')
		.attr('x1', x1)
		.attr('x2', x2)
		.attr('y1', y1)
		.attr('y2', y2)
		.style('stroke', color)
		.attr('stroke-width', strokeWidth);
};

// Reusable draw circle function for the graph - (selected, cx, cy, r, color)
export const drawCircle = (selected, cx, cy, r, color) => {
	selected
		.append('circle')
		.attr('cx', cx)
		.attr('cy', cy)
		.attr('r', r)
		.attr('fill', color);
};

// Reusable draw rectangle function for the graph
export const drawRectangle = (
	selected,
	x,
	y,
	width,
	height,
	color,
	strokeWidth,
	rx,
	ry
) => {
	selected
		.append('rect')
		.attr('x', x)
		.attr('y', y)
		.attr('width', width)
		.attr('height', height)
		.attr('fill', color)
		.attr('stroke-width', strokeWidth)
		.attr('ry', ry)
		.attr('rx', rx);
};

// Reusable draw text function for the graph
export const drawText = (selected, text, transform, color, isWrap, classed) => {
	if (isWrap == true) {
		selected
			.append('text')
			.text(text)
			.style('fill', color)
			.attr('transform', transform)
			.attr('class', classed)
			.call(wrap, 80);
	} else {
		selected
			.append('text')
			.text(text)
			.style('fill', color)
			.attr('transform', transform)
			.attr('class', classed);
	}
};

// Finds placement for blank box where text
export const getProgramBoxPlacement = (number, textValue, tickWidth) => {
	return number % 2 == 0 ? -(textValue / 2) + tickWidth / 2 - 12 : -(textValue / 2) + -10;
};

// Finds placement for the program text
export const getProgramTextPlacement = (number, tickWidth) => {
	return number % 2 == 0 ? tickWidth / 2 : 0;
};

// Finds placement for the program circle
export const getProgramCirclePlacement = (number, textValue, tickWidth) => {
	return number % 2 == 0 ? -(textValue / 2) + tickWidth / 2 + -6 : -(textValue / 2) + -5;
};

// Gets the program color needed to draw the correct color
export const getProgramColor = (index, valveData) => {
	return valveData[index].programColor;
};

export const xSubgroup = (subgroup, x) => {
	const xSubgroups = d3.scaleBand().domain(subgroup).range([0, x.bandwidth()]);
	return xSubgroups;
};

export const getProgramFormat = (valveCount, name) => {
	return valveCount === 1 ? dayjs(name).format('MMMM') : name;
};