import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '@/assets/css/styles.css';
// Vuetify
import 'vuetify/styles';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { createVuetify } from 'vuetify';
import { VDataTable } from 'vuetify/labs/VDataTable';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, md } from 'vuetify/iconsets/md';
import { SetupCalendar } from 'v-calendar';
import Vue3TouchEvents from 'vue3-touch-events';
import ApiService from '@/services/ApiService';

const vuetify = createVuetify({
  components,
  directives,
  VDataTable,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: 'Results not found',
    },
  }
});

const app = createApp(App).use(vuetify).use(createPinia().use(({ store }) => {
  store.$router = markRaw(router);
})).use(router).use(SetupCalendar, {}).use(Vue3TouchEvents);

app.config.errorHandler = (error, _, info) => {
  ApiService.logError(error, info);
};

app.mount('#app');
