<template>
  <div class="color-option-holder" @click.stop="handleColor">
    <div ref="color" class="color"></div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { useRMTStore } from '@/stores/RMTStore';

export default {
  name: 'ColorOption',
  setup () {
    const RMTStore = useRMTStore();
    return {
      RMTStore
    };
  },
  props: [
    'index',
    'color'
  ],
  mounted () {
    gsap.set(this.$refs.color, {
      backgroundColor: this.$props.color
    });
  },
  methods: {
    handleColor () {
      this.RMTStore.setSelectedItemColor(this.$props.index);
    }
  }
};
</script>

<style scoped>
.color-option-holder {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  display: flex;
  padding: 1px;
  margin: 3px;
  cursor: pointer;
}
.color {
  width: 15px;
}
</style>